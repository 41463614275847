<template>
  <div class="modal-footer">
    <slot name="content"></slot>
  </div>
</template>

<script>
export default {
  name: 'ModalFooter'
}
</script>

<style lang="scss" scoped>
@import "@/styles/variables";

.modal-footer {
  --modal-footer-padding: 24px;
  padding: 24px var(--modal-footer-padding);
  display: flex;
  justify-content: center;
}
</style>
