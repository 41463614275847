<template>
  <div class="room-header-brand">
    <router-link :to="{ name: 'home' }" class="brand-link">
      <SvgBrand class="brand-svg"></SvgBrand>
    </router-link>
  </div>
</template>

<script>
import SvgBrand from '@/components/svg/SvgBrand'

export default {
  name: 'RoomHeaderBrand',
  components: {
    SvgBrand
  }
}
</script>

<style scoped lang="scss">
@import "@/styles/variables";

.room-header-brand {
  height: 100%;
  display: flex;
  align-items: center;
}

.brand-link {
  height: 100%;
  padding: 0 $content-padding;
  display: flex;
  align-items: center;
  .brand-svg {
    width: 160px;
  }
}
</style>
