const toastClick = {
  mounted (el, binding) {
    if (!binding.arg) {
      el.addEventListener(
        'click',
        function (event) {
          if (!event.target.hasAttribute('toast-click-ignore')) {
            binding.value()
          }
        },
        false
      )
    } else if (binding.arg === 'ignore') {
      el.setAttribute('toast-click-ignore', true)
    }
  }
}

export default toastClick
