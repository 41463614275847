<template>
  <MobileHeaderLayout :sidebar="true">
    <template v-slot:content>
      <div class="mobile-membership">
        <VerificationAlert v-if="imGuest"></VerificationAlert>
        <MobileMembershipProfile></MobileMembershipProfile>
        <MobileSubscriptions v-if="subscriptions.length"></MobileSubscriptions>
        <MembershipPerks></MembershipPerks>
      </div>
    </template>
  </MobileHeaderLayout>
</template>

<script>
import { mapState } from 'vuex'
import MobileHeaderLayout from '@/components/layout/MobileHeaderLayout'
import MobileMembershipProfile from '@/views/membership/MobileMembershipProfile'
import MembershipPerks from '@/views/membership/MembershipPerks'
import MobileSubscriptions from '@/views/membership/MobileSubscriptions'
import VerificationAlert from '@/components/VerificationAlert'

export default {
  name: 'MobileMembership',
  components: {
    MobileHeaderLayout,
    MobileMembershipProfile,
    MembershipPerks,
    MobileSubscriptions,
    VerificationAlert
  },
  computed: {
    ...mapState('me', {
      role: (state) => state.role,
      subscriptions: (state) => state.subscriptions
    }),
    imGuest () {
      return this.role === 'GUEST'
    }
  }
}
</script>

<style scoped lang="scss">
@import "@/styles/variables";
.mobile-membership {
  padding: $mobile-content-padding;
  display: flex;
  flex-direction: column;
  gap: 16px;
}
</style>
