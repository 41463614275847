<template>
  <ContentLayout
    :add-paddings="false"
  >
    <template v-slot:title>MY ROOMS</template>
    <template v-slot:content>
      <div class="my-rooms-empty" v-if="!rooms.length">
        You don't have any rooms yet.
      </div>
      <div class="my-rooms" v-else>
        <RoomCard
          class="room"
          v-for="room in rooms"
          :key="room.id"
          :room="room"
        >
        </RoomCard>
      </div>
    </template>
  </ContentLayout>
</template>

<script>
import { mapState } from 'vuex'
import RoomCard from '@/components/rooms/RoomCard'
import ContentLayout from '@/components/layout/ContentLayout'

export default {
  name: 'MyRooms',
  components: {
    RoomCard,
    ContentLayout
  },
  computed: {
    ...mapState('me', {
      rooms: (state) => state.rooms
    })
  }
}
</script>

<style scoped lang="scss">
@import "@/styles/variables";
@import "@/styles/breakpoints";

.my-rooms {
  display: flex;
  flex-direction: column;
}

.my-rooms-empty {
  padding: 0 32px;
  @include media-breakpoint-down(sm) {
    padding: 0 24px;
  }
}
</style>
