import store from '@/store'

const focus = {
  mounted (el) {
    if (!store.state.isMobile) {
      el.focus()
    }
  }
}

export default focus
