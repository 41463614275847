import dateFormat from 'dateformat'
import TimeAgo from 'javascript-time-ago'
import en from 'javascript-time-ago/locale/en'

TimeAgo.addDefaultLocale(en)

const timeAgo = new TimeAgo('en-US')

export function formatTimeAgo (value) {
  return timeAgo.format(new Date(value), 'twitter-now')
}

export function formatDateOnly (value) {
  return dateFormat(new Date(value), 'mmm dS, yyyy')
}
