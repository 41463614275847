import { WsClient } from '@/helper/ws'
import { roomWsUrl } from '@/settings'

export class RoomWsService {
  constructor (app) {
    this.app = app
    this.ws = null
    this.pingTimer = null
  }

  get jwt () {
    return this.app.config.globalProperties.$service.jwt
  }

  getToken () {
    return this.jwt.accessToken
  }

  init (roomId) {
    this.destroy()
    this.ws = new WsClient({ wsUrl: roomWsUrl, getToken: () => this.getToken() })
    this.ws.connect({ room_id: roomId })
    this.initPing()
  }

  destroy () {
    if (this.ws) {
      this.ws.disconnect()
      this.ws.removeAllListeners()
    }
    this.destroyPing()
  }

  initPing () {
    this.pingTimer = setInterval(() => {
      if (!this.ws || !this.ws.connected || !this.ws.authorized) {
        return
      }
      this.publishPing()
    }, 10000)
  }

  destroyPing () {
    if (this.pingTimer) {
      clearInterval(this.pingTimer)
      this.pingTimer = null
    }
  }

  publishPing () {
    this.ws.send({ t: 'ping', d: {} })
  }

  publishChatItem (text, attachments) {
    this.ws.send({ t: 'chat.item', d: { text, attachments } })
  }

  publishChatTyping () {
    this.ws.send({ t: 'chat.typing', d: {} })
  }

  publishCmd (a, n) {
    this.ws.send({ t: 'cmd', d: { a, n } })
  }
}
