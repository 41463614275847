<template>
  <div class="subscription-card">
    <div class="payment-system">
      <SvgPaypal width="30" v-if="subscription.paymentSystem === 'PAYPAL'"></SvgPaypal>
    </div>
    <div class="description">
      <div class="name">
        <template v-if="subscription.plan === 'MONTHLY'">
          Monthly subscription
        </template>
        <template v-else-if="subscription.plan === 'YEARLY'">
          Yearly subscription
        </template>
        <template v-else-if="subscription.plan === 'QUARTERLY'">
          Quarterly subscription
        </template>
        <template v-else-if="subscription.plan === 'HALF_YEARLY'">
          Half-yearly subscription
        </template>
      </div>
      <div class="info">
        <div class="status">
          {{ subscription.status }}
        </div>
        <div class="source">
          {{ subscription.sourceId }}
        </div>
        <div
            class="payment-failed"
            v-if="subscription.paymentFailed"
        >
          Payment failed
        </div>
      </div>
    </div>
    <button type="button" class="btn-secondary-sm btn-cancel" @click="cancelSubscription">
      CANCEL
    </button>
  </div>
</template>

<script>
import SvgPaypal from '@/components/svg/SvgPaypal'

export default {
  name: 'SubscriptionCard',
  props: {
    subscription: Object
  },
  components: {
    SvgPaypal
  },
  methods: {
    cancelSubscription () {
      this.$modal.open({
        type: 'cancel-subscription',
        subscriptionId: this.subscription.id
      })
    }
  }
}
</script>

<style scoped lang="scss">
@import "@/styles/variables";

.subscription-card {
  display: flex;
  align-items: flex-start;
  gap: 24px;
}

.description {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  gap: 8px;
  .name {
    font-family: "Roboto Medium", sans-serif;
  }
  .info {
    display: flex;
    align-items: center;
    gap: 16px;
    .status {
      font-size: 15px;
    }
    .source {
      font-size: 13px;
      color: var(--muted-color);
    }
    .payment-failed {
      font-size: 15px;
      color: var(--membership-subscription-payment-failed-color);
    }
  }
}
.btn-cancel {
  max-width: 110px;
}
</style>
