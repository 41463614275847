<template>
  <router-link :to="{ name: 'account' }" class="sticky-header-account">
    <div class="info" v-if="name">
      <div class="name">
        {{ name }}
      </div>
      <div class="membership">
        <template v-if="role === 'GUEST'">
          Guest
        </template>
        <template v-else-if="membership">
          Premium
        </template>
        <template v-else>
          Basic
        </template>
      </div>
    </div>
    <Avatar class="avatar-xsm" :avatar="avatar"></Avatar>
  </router-link>
</template>

<script>
import { mapState } from 'vuex'
import Avatar from '@/components/Avatar'

export default {
  name: 'StickyHeaderAccount',
  components: {
    Avatar
  },
  computed: {
    ...mapState('me', {
      avatar: (state) => state.avatar,
      name: (state) => state.name,
      membership: (state) => state.membership,
      role: (state) => state.role
    })
  }
}
</script>

<style scoped lang="scss">
@import "@/styles/variables";

.sticky-header-account {
  display: flex;
  align-items: center;
  gap: 16px;
  height: 100%;
  padding: 0 $sticky-header-item-padding;
  color: var(--primary-color);
  text-decoration: none;
  .info {
    display: flex;
    flex-direction: column;
    gap: 4px;
    align-items: flex-end;
    .name {
      font-size: 16px;
      font-family: "Roboto Medium", sans-serif;
    }
    .membership {
      font-size: 14px;
      color: var(--muted-color);
    }
  }
}
</style>
