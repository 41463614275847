<template>
  <div class="members">
    <div class="header">
      <input
        v-focus
        v-model="query"
        class="input-lt-sm"
        type="text"
        placeholder="Find the member..."
      />
      <ProcessButton
        type="submit"
        class="btn-secondary-sm btn-refresh"
        :processing="refreshProcessing"
        @submit="refresh"
      >
        REFRESH
      </ProcessButton>
    </div>
    <div class="content scroll-y">
      <MemberPopup
          v-for="member in filterMembers(members)"
          :key="member.userId"
          placement="left"
          :user-id="member.userId"
          :avatar="member.avatar"
          :name="member.name"
      >
        <MemberCard
            :member="member"
        ></MemberCard>
      </MemberPopup>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import MemberCard from '@/components/room/members/MemberCard'
import MemberPopup from '@/components/room/members/MemberPopup'
import ProcessButton from '@/components/ProcessButton'

export default {
  name: 'Members',
  components: {
    ProcessButton,
    MemberCard,
    MemberPopup
  },
  data () {
    return {
      query: '',
      queryString: '',
      searchTimer: null,
      refreshProcessing: false
    }
  },
  computed: {
    ...mapState('room', {
      roomId: (state) => state.roomId,
      members: (state) => state.members
    })
  },
  watch: {
    query (value) {
      if (this.searchTimer) {
        clearTimeout(this.searchTimer)
      }
      this.searchTimer = setTimeout(() => {
        this.queryString = value
      }, 500)
    }
  },
  beforeUnmount () {
    if (this.searchTimer) {
      clearTimeout(this.searchTimer)
    }
  },
  methods: {
    filterMembers (members) {
      if (!this.queryString.length) {
        return members
      }
      return members.filter(
        (member) => member.name && member.name.toLowerCase().startsWith(this.queryString)
      )
    },
    async refresh () {
      this.refreshProcessing = true
      try {
        await this.$service.room.refreshMembers(this.roomId)
      } finally {
        this.refreshProcessing = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/styles/variables";

.members {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.header {
  padding: 24px 20px;
  display: flex;
  gap: 16px;
  .btn-refresh {
    height: 40px;
    max-width: 90px;
  }
}

.content {
  flex-grow: 1;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}
</style>
