import { events } from '@/plugins/modal/events'

export class Control {
  constructor (options = {}) {
    this.options = options
  }

  open (data) {
    events.emit('add', Object.assign({}, data))
  }

  closeAll () {
    events.emit('del-all')
  }
}
