module.exports = {
  appUrl: process.env.VUE_APP_URL,
  discordAuthUrl: process.env.VUE_APP_DISCORD_AUTH_URL,
  googleAuthUrl: process.env.VUE_APP_GOOGLE_AUTH_URL,
  membershipUrl: process.env.VUE_APP_MEMBERSHIP_URL,
  iamUrl: process.env.VUE_APP_IAM_URL,
  roomUrl: process.env.VUE_APP_ROOM_URL,
  roomWsUrl: process.env.VUE_APP_ROOM_WS_URL,
  liveStreamWsUrl: process.env.VUE_APP_LIVE_STREAM_WS_URL,
  meUrl: process.env.VUE_APP_ME_URL,
  centrifugoUrl: process.env.VUE_APP_CENTRIFUGO_URL,
  avatarUrl: process.env.VUE_APP_AVATAR_URL,
  giphyApiKey: process.env.VUE_APP_GIPHY_API_KEY,
  paypalClientId: process.env.VUE_APP_PAYPAL_CLIENT_ID,
  paypalMonthlyPlanId: process.env.VUE_APP_PAYPAL_MONTHLY_PLAN_ID,
  paypalYearlyPlanId: process.env.VUE_APP_PAYPAL_YEARLY_PLAN_ID,
  paypalQuarterlyPlanId: process.env.VUE_APP_PAYPAL_QUARTERLY_PLAN_ID,
  paypalHalfYearlyPlanId: process.env.VUE_APP_PAYPAL_HALF_YEARLY_PLAN_ID,
  discordInviteUrl: process.env.VUE_APP_DISCORD_INVITE_URL
}
