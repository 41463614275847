import store from '@/store'
import { MeServiceError } from '@/service/me.service'

export class PreloadService {
  constructor (app) {
    this.app = app
  }

  get toast () {
    return this.app.config.globalProperties.$toast
  }

  get me () {
    return this.app.config.globalProperties.$service.me
  }

  async fetch (preload) {
    if (preload.length) {
      const promises = []
      for (const name of preload) {
        if (name === 'me') {
          if (this.needToFetchMe()) {
            promises.push(this.me.fetchMe())
          }
        } else {
          console.warn('unknown.preload', name)
        }
      }
      if (promises.length) {
        await store.dispatch('showPreloader')
        try {
          await Promise.all(promises)
        } catch (err) {
          if (err instanceof MeServiceError) {
            if (err.code === 'request.canceled') {
              await store.dispatch('hidePreloader')
              throw err
            }
          }
          this.toast.somethingWentWrong()
          throw err
        } finally {
          await store.dispatch('hidePreloader')
        }
      }
    }
  }

  needToFetchMe () {
    return !store.state.me.userId
  }
}
