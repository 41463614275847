<template>
  <div class="login-form-code">
    <div class="code-title">
      <div class="title">Let's verify your email</div>
      <div class="sub-title">You will receive a code within 1 minute to {{ email }}</div>
    </div>
    <form class="code-form">
      <v-otp-input
        v-model:value="code"
        input-classes="otp-input"
        separator=""
        :num-inputs="4"
        :should-auto-focus="true"
        input-type="letter-numeric"
        :conditionalClass="['one', 'two', 'three', 'four']"
        :placeholder="['*', '*', '*', '*']"
        @on-complete="login"
      />
      <div class="actions">
        <button type="button" class="btn-secondary" @click="reset">
          CANCEL
        </button>
        <ProcessButton type="submit" class="btn-primary" @submit="login" :processing="processing">
          VERIFY
        </ProcessButton>
      </div>
    </form>
  </div>
</template>

<script>
import ProcessButton from '@/components/ProcessButton'

export default {
  name: 'LoginFormCode',
  components: {
    ProcessButton
  },
  props: {
    otpId: String,
    email: String
  },
  data () {
    return {
      code: '',
      processing: false
    }
  },
  methods: {
    async login () {
      this.processing = true
      try {
        await this.$service.iam.loginEmail(this.otpId, this.code)
      } finally {
        this.processing = false
      }
      const next = this.$route.query.next
      if (next) {
        await this.$router.push(next)
      } else {
        await this.$router.push({ name: 'home' })
      }
    },
    reset () {
      this.$emit('reset')
    }
  }
}
</script>

<style scoped lang="scss">
@import "@/styles/variables";
@import "@/styles/breakpoints";

.login-form-code {
  display: flex;
  flex-direction: column;
  gap: 50px;
}

.code-title {
  display: flex;
  flex-direction: column;
  gap: 16px;
  .title {
    font-family: "Roboto Medium", sans-serif;
    font-size: 26px;
    @include media-breakpoint-down(sm) {
      font-size: 24px;
    }
  }
  .sub-title {
    color: var(--muted-color);
  }
}

.code-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 50px;
  .actions {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 16px;
  }
}
</style>
