<template>
  <ContentLayout>
    <template v-slot:title>
      MEMBERSHIP
    </template>
    <template v-slot:content>
      <div class="mobile-membership-profile">
        <div class="membership" v-if="membership">
          <div class="info">
            <div class="membership-status">
              You are in Bearcat membership
              <SvgCheck class="icon-check" width="16"></SvgCheck>
            </div>
            <div class="membership-expires-at">
              Expires on {{ formatDate(membershipExpiresAt) }}
            </div>
          </div>
          <button type="button" class="btn-primary-sm btn-upgrade" @click="upgrade"
                  v-if="!subscriptions.length"
          >UPGRADE</button>
        </div>
        <div v-else class="not-membership">
          <div class="info">
            <div class="membership-status">
              <template v-if="subscriptions.length">
                You aren't in Bearcat membership
              </template>
              <template v-else>
                You aren't in Bearcat membership yet
              </template>
            </div>
            <div class="membership-expired-at" v-if="subscriptions.length">
              Expired at {{ formatDate(membershipExpiresAt) }}
            </div>
          </div>
          <button type="button" class="btn-primary-sm btn-upgrade" @click="upgrade">UPGRADE</button>
        </div>
      </div>
    </template>
  </ContentLayout>
</template>

<script>
import { mapState } from 'vuex'
import ContentLayout from '@/components/layout/ContentLayout'
import { formatDateOnly } from '@/utils/date'
import SvgCheck from '@/components/svg/SvgCheck'

export default {
  name: 'MobileMembershipProfile',
  components: {
    SvgCheck,
    ContentLayout
  },
  computed: {
    ...mapState('me', {
      membership: (state) => state.membership,
      membershipExpiresAt: (state) => state.membershipExpiresAt,
      subscriptions: (state) => state.subscriptions,
      role: (state) => state.role
    }),
    imGuest () {
      return this.role === 'GUEST'
    }
  },
  methods: {
    formatDate (value) {
      return formatDateOnly(value)
    },
    upgrade () {
      if (this.subscriptions.length) {
        this.$toast.warning({
          type: 'simple',
          context: {
            text: 'Please cancel your active subscription before purchasing a new one'
          }
        })
        return
      }
      if (this.imGuest) {
        this.$toast.warning({
          type: 'verification'
        })
        return
      }
      this.$modal.open({
        type: 'upgrade-membership'
      })
    }
  }
}
</script>

<style scoped lang="scss">
@import "@/styles/variables";

.mobile-membership-profile {
  display: flex;
  flex-direction: column;
}

.membership {
  display: flex;
  flex-direction: column;
  gap: 24px;
  .info {
    display: flex;
    flex-direction: column;
    gap: 8px;
    .membership-status {
      display: flex;
      align-items: center;
      gap: 8px;
      font-family: "Roboto Medium", sans-serif;
      .icon-check {
        color: var(--status-green);
      }
    }
    .membership-expires-at {
      color: var(--muted-color);
      font-size: 15px;
    }
  }
}

.not-membership {
  display: flex;
  flex-direction: column;
  gap: 24px;
  .info {
    display: flex;
    flex-direction: column;
    gap: 8px;
    .membership-status {
      display: flex;
      align-items: center;
      gap: 8px;
      font-family: "Roboto Medium", sans-serif;
    }
    .membership-expired-at {
      color: var(--muted-color);
      font-size: 15px;
    }
  }
}
</style>
