<template>
  <div class="member-card">
    <div class="avatar-wrap"
         :class="{owner: isOwner}"
    >
      <Avatar class="avatar-md" :avatar="member.avatar"></Avatar>
      <div class="owner-crown" v-if="isOwner">
        <SvgCrown width="24"></SvgCrown>
      </div>
    </div>
    <div class="user-info">
      <div class="name clamp-1">{{ member.name || 'Member' }}</div>
      <div class="online">
        online
        <div class="dot dot-online"></div>
      </div>
    </div>
    <div class="badge badge-host" v-if="isHost">
      HOST
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Avatar from '@/components/Avatar'
import SvgCrown from '@/components/svg/SvgCrown'

export default {
  name: 'MemberCard',
  components: {
    Avatar,
    SvgCrown
  },
  props: {
    member: Object
  },
  computed: {
    ...mapState('room', {
      members: (state) => state.members,
      hostId: state => state.hostId,
      ownerId: state => state.ownerId
    }),
    isOwner () {
      return this.member.userId === this.ownerId
    },
    isHost () {
      return this.member.userId === this.hostId
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/styles/variables";

.member-card {
  display: flex;
  align-items: center;
  padding: 16px 24px;
  gap: 16px;
  cursor: pointer;
  -webkit-transition: background-color 100ms linear;
  -ms-transition: background-color 100ms linear;
  transition: background-color 100ms linear;
  &:hover {
    background-color: var(--hover-bg);
  }
  &:active {
    background-color: var(--click-bg);
  }
}

.user-info {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  gap: 8px;
  .name {
    font-family: "Roboto Medium", sans-serif;
    font-size: 18px;
  }
  .online {
    font-size: 16px;
    display: flex;
    align-items: center;
    gap: 8px;
    .dot {
      height: 8px;
      width: 8px;
      border-radius: 50%;
      display: inline-block;
    }
    .dot-online {
      background-color: var(--status-green);
    }
  }
}

.badge {
  border-radius: 8px;
  padding: 6px 12px;
  font-family: "Roboto Medium", sans-serif;
  font-size: 13px;
  color: var(--bright-color);
  &.badge-host {
    background-color: var(--member-card-badge-host-bg);
  }
}

.avatar-wrap {
  position: relative;
  border: 4px solid transparent;
  border-radius: 12px;
  &.owner {
    border: 4px solid var(--member-card-crown-color);
  }
  .owner-crown {
    position: absolute;
    transform: translate(-50%, 0);
    top: -20px;
    left: 50%;
    color: var(--member-card-crown-color);
  }
}
</style>
