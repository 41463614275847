const storeKey = 'jwt'

export class JwtService {
  constructor () {
    const tokens = this.get()
    const { accessToken, refreshToken } = tokens || {}
    this._accessToken = accessToken
    this._refreshToken = refreshToken
    this._userId = undefined
    if (this._accessToken) {
      const { sub: _userId } = this._parse(this._accessToken)
      this._userId = _userId
    }
  }

  get accessToken () {
    return this._accessToken
  }

  get refreshToken () {
    return this._refreshToken
  }

  get userId () {
    return this._userId
  }

  has () {
    return this._accessToken && this._refreshToken
  }

  get () {
    const raw = localStorage.getItem(storeKey)
    if (!raw) {
      return
    }
    return JSON.parse(raw)
  }

  set ({ accessToken, refreshToken }) {
    this._accessToken = accessToken
    this._refreshToken = refreshToken
    if (this._accessToken) {
      const { sub: _userId } = this._parse(this._accessToken)
      this._userId = _userId
    }
    localStorage.setItem(
      storeKey,
      JSON.stringify({ accessToken, refreshToken })
    )
  }

  reset () {
    this._accessToken = undefined
    this._refreshToken = undefined
    this._userId = undefined
    localStorage.removeItem(storeKey)
  }

  _parse (token) {
    const base64Url = token.split('.')[1]
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
    const jsonPayload = decodeURIComponent(
      window
        .atob(base64)
        .split('')
        .map(function (c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
        })
        .join('')
    )
    return JSON.parse(jsonPayload)
  }
}
