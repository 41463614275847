import { events } from '@/plugins/toast/events'

export default class Control {
  constructor (options = {}) {
    this.options = options
  }

  info (data) {
    events.emit(
      'add',
      Object.assign({ type: 'simple' }, data, { level: 'info' })
    )
  }

  error (data) {
    events.emit(
      'add',
      Object.assign({ type: 'simple' }, data, { level: 'error' })
    )
  }

  warning (data) {
    events.emit(
      'add',
      Object.assign({ type: 'simple' }, data, { level: 'warning' })
    )
  }

  somethingWentWrong () {
    events.emit('add', {
      type: 'simple',
      context: {
        text: 'Ups... Something went wrong, try again later'
      },
      level: 'error'
    })
  }

  closeAll () {
    events.emit('del-all')
  }
}
