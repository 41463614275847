<template>
  <ContentLayout>
    <template v-slot:title>LOGIN OPTIONS</template>
    <template v-slot:content>
      <div class="login-options">
        <div class="block-wrap">
          <div class="block">
            <div class="icon email-icon">
              <SvgEmail width="18"></SvgEmail>
            </div>
            <div class="info">
              <div class="label">Email</div>
              <div class="value">
                <template v-if="hasEmail">
                  {{ censoredEmail }}
                </template>
                <template v-else> Login with Email </template>
              </div>
            </div>
            <template v-if="hasEmail">
              <SvgCheck class="check" width="25"></SvgCheck>
            </template>
            <template v-else-if="!isMobile">
              <button class="btn-secondary-sm btn-setup" @click="setupEmail">SETUP</button>
            </template>
          </div>
          <button class="btn-secondary-sm btn-setup" v-if="isMobile && !hasEmail" @click="setupEmail">SETUP</button>
        </div>
        <div class="block-wrap">
          <div class="block">
            <div class="icon discord-icon">
              <SvgDiscord width="18"></SvgDiscord>
            </div>
            <div class="info">
              <div class="label">Discord</div>
              <div class="value">
                <template v-if="hasDiscord">
                  <span class="value-sm">{{ censoredDiscord }}</span>
                </template>
                <template v-else> Login with Discord </template>
              </div>
            </div>
            <template v-if="hasDiscord">
              <SvgCheck class="check" width="25"></SvgCheck>
            </template>
            <template v-else-if="!isMobile">
              <button class="btn-secondary-sm btn-setup" @click="setupDiscord">SETUP</button>
            </template>
          </div>
          <button class="btn-secondary-sm btn-setup" v-if="isMobile && !hasDiscord" @click="setupDiscord">SETUP</button>
        </div>
        <div class="block-wrap">
          <div class="block">
            <div class="icon google-icon">
              <SvgGoogle width="18"></SvgGoogle>
            </div>
            <div class="info">
              <div class="label">Google</div>
              <div class="value">
                <template v-if="hasGoogle">
                  <span class="value-sm">{{ censoredGoogle }}</span>
                </template>
                <template v-else> Login with Google </template>
              </div>
            </div>
            <template v-if="hasGoogle">
              <SvgCheck class="check" width="25"></SvgCheck>
            </template>
            <template v-else-if="!isMobile">
              <button class="btn-secondary-sm btn-setup" @click="setupGoogle">SETUP</button>
            </template>
          </div>
          <button class="btn-secondary-sm btn-setup" v-if="isMobile && !hasGoogle" @click="setupGoogle">SETUP</button>
        </div>
      </div>
    </template>
  </ContentLayout>
</template>

<script>
import { mapState } from 'vuex'
import ContentLayout from '@/components/layout/ContentLayout'
import SvgCheck from '@/components/svg/SvgCheck'
import { googleAuthUrl, discordAuthUrl } from '@/settings'
import SvgEmail from '@/components/svg/SvgEmail'
import SvgGoogle from '@/components/svg/SvgGoogle'
import SvgDiscord from '@/components/svg/SvgDiscord'

export default {
  name: 'LoginOptions',
  components: {
    ContentLayout,
    SvgCheck,
    SvgEmail,
    SvgGoogle,
    SvgDiscord
  },
  computed: {
    ...mapState({
      isMobile: state => state.isMobile
    }),
    ...mapState('me', {
      censoredEmail: (state) => state.censoredEmail,
      censoredDiscord: (state) => state.censoredDiscord,
      censoredGoogle: (state) => state.censoredGoogle
    }),
    hasEmail () {
      return this.censoredEmail !== null
    },
    hasDiscord () {
      return this.censoredDiscord !== null
    },
    hasGoogle () {
      return this.censoredGoogle !== null
    }
  },
  methods: {
    setTmp (provider) {
      this.$service.tmp.reset()
      this.$service.tmp.setOauth2LinkProvider(provider)
      this.$service.tmp.setOauth2Next(this.$route.fullPath)
    },
    setupEmail () {
      console.log('link.email')
      this.$modal.open({
        type: 'setup-email'
      })
    },
    setupDiscord () {
      console.log('link.discord')
      this.setTmp('discord')
      window.location.replace(discordAuthUrl)
    },
    setupGoogle () {
      console.log('link.google')
      this.setTmp('google')
      window.location.replace(googleAuthUrl)
    }
  }
}
</script>

<style scoped lang="scss">
@import "@/styles/variables";

.login-options {
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.block-wrap {
  display: flex;
  flex-direction: column;
  gap: 16px;
  .block {
    display: flex;
    align-items: center;
    gap: 16px;
    .info {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      gap: 8px;
      .label {
        font-family: "Roboto Medium", sans-serif;
      }
      .value {
        font-size: 15px;
        color: var(--muted-color);
        .value-sm {
          font-size: 14px;
        }
      }
    }
    .btn-setup {
      max-width: 110px;
    }
    .check {
      color: var(--status-green);
    }
    .icon {
      color: white;
      width: 38px;
      height: 38px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      &.email-icon {
        background-color: var(--email-bg);
      }
      &.discord-icon {
        background-color: var(--discord-bg);
      }
      &.google-icon {
        background-color: var(--google-bg);
      }
    }
  }
}
</style>
