<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
  >
    <rect width="22" height="10" x="1" y="7" rx="4" stroke-width="2" />
  </svg>
</template>

<script>
export default {
  name: 'SvgRectAlt'
}
</script>

<style scoped>
svg {
  display: block;
}
</style>
