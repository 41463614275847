<template>
  <div class="content-layout">
    <div class="title" v-if="hasTitleSlot">
      <slot name="title"></slot>
    </div>
    <div class="content"
         :class="{'add-paddings': addPaddings}"
    >
      <slot name="content"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ContentLayout',
  props: {
    addPaddings: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    hasTitleSlot () {
      return !!this.$slots.title
    }
  }
}
</script>

<style scoped lang="scss">
@import "@/styles/variables";
@import "@/styles/breakpoints";

.content-layout {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
  background-color: var(--content-layout-bg);
  border-radius: 8px;
  padding: 24px 0;

  .title {
    color: var(--muted-color);
    font-size: 14px;
    padding: 0 32px;
  }

  .content {
    padding: 0;
    &.add-paddings {
      padding: 0 32px;
    }
  }

  @include media-breakpoint-down(sm) {
    .title {
      padding: 0 24px;
      font-size: 13px;
    }
    .content {
      &.add-paddings {
        padding: 0 24px;
      }
    }
  }
}
</style>
