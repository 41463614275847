<template>
  <div class="chat">
    <div class="messages-wrap">
      <div class="messages scroll-y" ref="messages" @scroll="handleScroll">
        <div v-for="data in chat" :key="data.id" :id="'msg-' + data.id">
          <ChatMessage :data="data"></ChatMessage>
        </div>
      </div>
      <button class="btn-secondary-icon-sm btn-to-bottom" v-if="showScrollToBottom" @click="scrollToBottom">
        <SvgChevronDown width="20"></SvgChevronDown>
      </button>
    </div>
    <div class="input-box">
      <ChatInputBox
        ref="inputBox"
        @submit="publishChatItem"
        @typing="publishChatTyping"
        @resized="onInputBoxResized"
      ></ChatInputBox>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import ChatInputBox from '@/components/room/chat/ChatInputBox'
import ChatMessage from '@/components/room/chat/ChatMessage'
import SvgChevronDown from '@/components/svg/SvgChevronDown'

export default {
  name: 'Chat',
  components: {
    ChatInputBox,
    ChatMessage,
    SvgChevronDown
  },
  computed: {
    ...mapState('me', {
      userId: (state) => state.userId
    }),
    ...mapState('room', {
      chat: (state) => state.chat,
      mini: (state) => state.mini,
      roomId: (state) => state.roomId,
      fullscreen: (state) => state.fullscreen
    })
  },
  data () {
    return {
      scrolled: false,
      scrollToBottomShowDelayTimer: null,
      showScrollToBottom: false
    }
  },
  async beforeMount () {
    this.initSubEvents()
  },
  beforeUnmount () {
    this.destroySubEvents()
    this.stopScrollToBottomShowDelayTimer()
  },
  mounted () {
    this.$nextTick(() => {
      this.scrollToBottom()
    })
  },
  watch: {
    roomId () {
      this.destroySubEvents()
      this.initSubEvents()
    },
    mini (value) {
      if (!value) {
        this.$nextTick(() => {
          this.scrollToBottom()
        })
      }
    },
    fullscreen (value) {
      if (!value) {
        this.$nextTick(() => {
          this.scrollToBottom()
        })
      }
    }
  },
  methods: {
    onResize () {
      this.$nextTick(() => {
        this.scrollToBottom()
      })
    },
    startScrollToBottomShowDelayTimer () {
      this.stopScrollToBottomShowDelayTimer()
      this.scrollToBottomShowDelayTimer = setTimeout(() => {
        if (this.scrolled) {
          this.showScrollToBottom = true
        }
      }, 1000)
    },
    stopScrollToBottomShowDelayTimer () {
      if (this.scrollToBottomShowDelayTimer) {
        clearTimeout(this.scrollToBottomShowDelayTimer)
        this.scrollToBottomShowDelayTimer = null
      }
    },
    initSubEvents () {
      this.$service.roomCentrifugoService.subEvents.on('chat.item', this.onChatItem)
      this.$service.roomCentrifugoService.subEvents.on('chat.typing', this.onChatTyping)
    },
    destroySubEvents () {
      this.$service.roomCentrifugoService.subEvents.off('chat.item', this.onChatItem)
      this.$service.roomCentrifugoService.subEvents.off('chat.typing', this.onChatTyping)
    },
    onInputBoxResized () {
      if (!this.scrolled) {
        this.$nextTick(() => {
          this.scrollToBottom()
        })
      }
    },
    publishChatItem (text, attachments) {
      this.$service.roomWs.publishChatItem(text, attachments)
    },
    publishChatTyping () {
      this.$service.roomWs.publishChatTyping()
    },
    onChatItem (data) {
      this.$refs.inputBox.delTyping({
        userId: data.userId,
        name: data.message.name
      })
      if (this.userId === data.userId || !this.scrolled) {
        this.$nextTick(() => {
          this.scrollToBottom()
        })
      }
    },
    onChatTyping (data) {
      this.$refs.inputBox.addTyping({
        userId: data.userId,
        name: data.name
      })
    },
    scrollToBottom () {
      this.$refs.messages.scrollTo(0, this.$refs.messages.scrollHeight)
    },
    async handleScroll (e) {
      const { scrollHeight, scrollTop, clientHeight } = e.target
      if (scrollTop === 0) {
        if (this.chat[0]) {
          const lastMessageId = this.chat[0].id
          const lastMessageCreatedAt = this.chat[0].createdAt
          const data = await this.$service.room.getChat(
            this.roomId,
            lastMessageCreatedAt
          )
          if (data.length) {
            this.$nextTick(() => {
              if (this.$refs.messages) {
                this.$refs.messages
                  .querySelector(`#msg-${lastMessageId}`)
                  .scrollIntoView()
              }
            })
          }
        }
      }
      const scrolled = Math.abs(scrollHeight - clientHeight - scrollTop) >= 1
      if (scrolled) {
        this.scrolled = true
        this.startScrollToBottomShowDelayTimer()
      } else {
        this.scrolled = false
        this.showScrollToBottom = false
        this.stopScrollToBottomShowDelayTimer()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/styles/variables";

.chat {
  height: 100%;
  display: flex;
  flex-direction: column;
  .messages-wrap {
    flex-grow: 1;
    height: 0;
    position: relative;
    .messages {
      width: 100%;
      height: 100%;
      padding: 16px 0;
      --scrollbar-color: var(--room-scroll-color);
    }
    .btn-to-bottom {
      position: absolute;
      z-index: 1;
      right: 16px;
      bottom: 22px;
      width: 30px;
      height: 30px;
      border-radius: 6px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .input-box {
    min-height: $room-chat-input-height;
  }
}
</style>
