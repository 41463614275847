<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0, 0, 400,377.9527559055118">
    <g>
      <path
        d="M143.729 23.894 C 16.426 54.708,-29.082 220.369,63.695 315.242 C 86.725 338.791,128.120 362.976,132.636 355.520 C 134.082 353.133,142.979 338.968,152.409 324.042 L 169.554 296.903 158.005 293.332 C 84.457 270.589,60.922 178.541,113.947 121.016 C 174.264 55.580,286.426 95.845,292.250 185.025 C 294.365 217.414,290.794 226.841,248.963 299.299 C 206.574 372.726,206.575 372.596,248.486 358.021 C 456.672 285.621,358.329 -28.049,143.729 23.894 M166.072 167.158 C 136.560 191.484,163.461 238.831,199.951 226.788 C 237.384 214.434,228.365 157.480,188.976 157.480 C 181.161 157.480,174.292 160.383,166.072 167.158 "
        stroke="none"
        fill="rgb(89,46,148)"
        fill-rule="evenodd"
      ></path>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'S9Anime'
}
</script>

<style scoped>
svg {
  display: block;
}
</style>
