<template>
  <ContentLayout>
    <template v-slot:title>ACCOUNT REMOVAL</template>
    <template v-slot:content>
      <div class="account-removal">
        <div class="description line-height">
          Deleting account means you can't recover it after taking this action.
        </div>
        <button class="btn-danger-sm btn-delete-account" type="button" @click="deleteAccount">DELETE</button>
      </div>
    </template>
  </ContentLayout>
</template>

<script>
import ContentLayout from '@/components/layout/ContentLayout'

export default {
  name: 'AccountRemoval',
  components: {
    ContentLayout
  },
  methods: {
    deleteAccount () {
      this.$modal.open({
        type: 'delete-account'
      })
    }
  }
}
</script>

<style scoped lang="scss">
@import "@/styles/variables";
@import "@/styles/breakpoints";

.account-removal {
  display: grid;
  grid-template-columns: 1fr 110px;
  column-gap: 16px;
  row-gap: 24px;
  align-items: center;
  @include media-breakpoint-down(sm) {
    grid-template-columns: repeat(1, 1fr);
  }
}
</style>
