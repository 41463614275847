<template>
  <div class="footer-theater">
    <button
      class="btn btn-theater"
      v-if="!theater"
      @click.prevent="toggleTheater"
    >
      <SvgRect width="22"></SvgRect>
    </button>
    <button
      class="btn btn-theater"
      v-else
      @click.prevent="toggleTheater"
    >
      <SvgRectAlt width="22"></SvgRectAlt>
    </button>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import SvgRect from '@/components/svg/SvgRect'
import SvgRectAlt from '@/components/svg/SvgRectAlt'

export default {
  name: 'FooterTheater',
  components: {
    SvgRect,
    SvgRectAlt
  },
  computed: {
    ...mapState('room', {
      theater: (state) => state.theater
    })
  },
  methods: {
    toggleTheater () {
      this.$store.dispatch('room/updateMode', { theater: !this.theater })
    }
  }
}
</script>

<style scoped lang="scss">
@import "@/styles/variables";

.footer-theater {
  display: flex;
  align-items: center;
  height: 100%;
  position: relative;
}
.btn-theater {
  height: 100%;
  padding: 0 16px;
  color: var(--muted-color);
  &:hover:not([disabled]) {
    color: var(--primary-color);
  }
}
</style>
