<template>
  <div class="chat-attachment">
    <div class="block" v-for="(attachment, index) in attachments" :key="index">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        :viewBox="`0 0 ${attachment.preview.width} ${attachment.preview.height}`"
      >
        <image
          width="100%"
          height="100%"
          :href="attachment.preview.url"
        ></image>
      </svg>
      <div class="toolbar">
        <button class="btn btn-detach" @click.prevent="detach(index)">
          <SvgClose width="16"></SvgClose>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import SvgClose from '@/components/svg/SvgClose'

export default {
  name: 'ChatAttachment',
  components: {
    SvgClose
  },
  props: {
    attachments: {
      type: Array,
      required: true
    }
  },
  methods: {
    detach (index) {
      this.$emit('detach', index)
    }
  }
}
</script>

<style scoped lang="scss">
@import "@/styles/variables";

.chat-attachment {
  width: 100%;
  padding: 8px 20px;
  display: inline-grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 12px;
}
.block {
  svg {
    display: block;
  }
  image {
    display: block;
  }
  position: relative;
  .toolbar {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    .btn-detach {
      background-color: var(--room-aside-alt-bg);
      padding: 1px;
    }
  }
}
</style>
