<template>
  <div class="mobile-subscription-card">
    <div class="description">
      <div class="header">
        <div class="name">
          <template v-if="subscription.plan === 'MONTHLY'">
            Monthly subscription
          </template>
          <template v-else-if="subscription.plan === 'YEARLY'">
            Yearly subscription
          </template>
          <template v-else-if="subscription.plan === 'QUARTERLY'">
            Quarterly subscription
          </template>
          <template v-else-if="subscription.plan === 'HALF_YEARLY'">
            Half-yearly subscription
          </template>
        </div>
        <div class="payment-system">
          <SvgPaypal width="13" v-if="subscription.paymentSystem === 'PAYPAL'"></SvgPaypal>
        </div>
      </div>
      <div class="info">
        <div class="status">
          {{ subscription.status }}
        </div>
        <div class="source">
          {{ subscription.sourceId }}
        </div>
      </div>
      <div
        class="payment-failed"
        v-if="subscription.paymentFailed"
      >
        Payment failed
      </div>
    </div>
    <button type="button" class="btn-secondary-sm btn-cancel" @click="cancelSubscription">
      CANCEL
    </button>
  </div>
</template>

<script>
import SvgPaypal from '@/components/svg/SvgPaypal'

export default {
  name: 'MobileSubscriptionCard',
  components: {
    SvgPaypal
  },
  props: {
    subscription: Object
  },
  methods: {
    cancelSubscription () {
      this.$modal.open({
        type: 'cancel-subscription',
        subscriptionId: this.subscription.id
      })
    }
  }
}
</script>

<style scoped lang="scss">
@import "@/styles/variables";

.mobile-subscription-card {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.description {
  display: flex;
  flex-direction: column;
  gap: 8px;
  .header {
    display: flex;
    align-items: center;
    gap: 8px;
    .name {
      font-family: "Roboto Medium", sans-serif;
    }
  }
  .info {
    display: flex;
    align-items: center;
    gap: 16px;
    .status {
      font-size: 13px;
    }
    .source {
      font-size: 13px;
      color: var(--muted-color);
    }
  }
  .payment-failed {
    font-size: 15px;
    color: var(--membership-subscription-payment-failed-color);
  }
}
</style>
