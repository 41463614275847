<template>
  <div class="pending-mini" v-if="mini">
    <Preprocessor class="spinner"></Preprocessor>
  </div>
  <div class="pending">
    <RoomHeader></RoomHeader>
    <div class="content-wrap">
      <div class="content">
        <Preprocessor class="spinner"></Preprocessor>
        <div class="text line-height" v-if="showText">
          We are preparing your browser it may take a few minutes...
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import RoomHeader from '@/components/room/header/RoomHeader'
import Preprocessor from '@/components/Preprocessor'

export default {
  name: 'Pending',
  components: {
    RoomHeader,
    Preprocessor
  },
  computed: {
    ...mapState('room', {
      mini: (state) => state.mini
    })
  },
  data () {
    return {
      textDelayTimer: null,
      showText: false
    }
  },
  async beforeMount () {
    this.startTextDelayTimer(3000)
  },
  beforeUnmount () {
    this.stopTextDelayTimer()
  },
  methods: {
    startTextDelayTimer (delay) {
      this.stopTextDelayTimer()
      this.textDelayTimer = setTimeout(async () => {
        this.showText = true
      }, delay)
    },
    stopTextDelayTimer () {
      if (this.textDelayTimer) {
        clearTimeout(this.textDelayTimer)
        this.textDelayTimer = null
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/styles/variables";

.pending-mini {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  .spinner {
    --preprocessor-size: 48px;
  }
}

.pending {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.content-wrap {
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.content {
  width: 100%;
  max-width: 250px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;
  .spinner {
    --preprocessor-size: 48px;
  }
  .text {
    text-align: center;
    font-family: "Roboto Mono Medium", sans-serif;
    font-size: 13px;
  }
}
</style>
