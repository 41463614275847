<template>
  <MobileHeaderLayout>
    <template v-slot:content>
      <div class="login-wrap">
        <div class="login">
          <LoginForm></LoginForm>
        </div>
      </div>
    </template>
  </MobileHeaderLayout>
</template>

<script>
import MobileHeaderLayout from '@/components/layout/MobileHeaderLayout'
import LoginForm from '@/views/login/LoginForm'

export default {
  name: 'MobileLogin',
  components: {
    MobileHeaderLayout,
    LoginForm
  }
}
</script>

<style scoped lang="scss">
@import "@/styles/variables";

.login-wrap {
  min-width: 100%;
  min-height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 $mobile-content-padding $mobile-header-height;
  @media screen and ( max-height: 500px ) {
    padding: $mobile-header-height $mobile-content-padding;
    align-items: unset;
  }
  .login {
    width: 100%;
    max-width: 350px;
  }
}
</style>
