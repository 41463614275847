<template>
  <div class="create-room-modal">
    <ModalHeader :modal="modal">
      <template v-slot:title>Create room</template>
    </ModalHeader>
    <form class="form">
      <div class="content">
        <input
          v-focus
          v-model="name"
          class="input-lt-sm"
          type="text"
          placeholder="Enter room name..."
        />
      </div>
      <ModalFooter class="footer">
        <template v-slot:content>
          <ProcessButton
            type="submit"
            class="btn-primary-sm btn-create-room"
            :processing="processing"
            @submit="createRoom"
          >
            CREATE ROOM
          </ProcessButton>
        </template>
      </ModalFooter>
    </form>
  </div>
</template>

<script>
import ProcessButton from '@/components/ProcessButton'
import ModalHeader from '@/components/modals/ModalHeader'
import ModalFooter from '@/components/modals/ModalFooter'
import { RoomServiceError } from '@/service/room.service'

export default {
  name: 'CreateRoomModal',
  components: {
    ProcessButton,
    ModalHeader,
    ModalFooter
  },
  props: {
    modal: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      name: null,
      processing: false
    }
  },
  methods: {
    close () {
      this.modal.close()
    },
    async createRoom () {
      console.log('create.room')
      this.processing = true
      try {
        await this.$service.room.createRoom(this.name)
      } catch (err) {
        if (err instanceof RoomServiceError) {
          if (err.code === 'max.rooms.per.user.exceeded') {
            this.close()
            throw err
          }
        }
        throw err
      } finally {
        this.processing = false
      }
      this.close()
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/styles/variables";
@import "@/styles/breakpoints";

.create-room-modal {
  width: 100vw;
  display: flex;
  flex-direction: column;
  .footer {
    .btn-create-room {
      max-width: 140px;
    }
  }
  @include media-breakpoint-up(sm) {
    max-width: 380px;
  }
}

.form {
  display: flex;
  flex-direction: column;
  .content {
    padding: 0 24px;
  }
}
</style>
