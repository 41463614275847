<template>
  <MemberPopup
    placement="left"
    :user-id="data.userId"
    :avatar="data.message.avatar"
    :name="data.message.name"
  >
    <div class="chat-message">
      <Avatar class="avatar avatar-sm" :avatar="data.message.avatar"></Avatar>
      <div class="layout">
        <div class="info">
          <div class="name">
            {{ data.message.name || "Member" }}
          </div>
          <div class="timestamp">
            {{ data.timeAgo }}
          </div>
        </div>
        <div class="content">
          <ChatMessageText
            class="text"
            v-if="data.message.text"
            :text="data.message.text"
          ></ChatMessageText>
          <div
            class="attachments"
            v-if="data.message.attachments && data.message.attachments.length"
          >
            <div
              class="attachment"
              v-for="(attachment, index) in data.message.attachments"
              :key="index"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                preserveAspectRatio="xMinYMin"
                :viewBox="`0 0 ${attachment.width} ${attachment.height}`"
              >
                <image width="100%" height="100%" :href="attachment.url"></image>
              </svg>
            </div>
          </div>
        </div>
      </div>
    </div>
  </MemberPopup>
</template>

<script>
import Avatar from '@/components/Avatar'
import ChatMessageText from '@/components/room/chat/ChatMessageText'
import MemberPopup from '@/components/room/members/MemberPopup'

export default {
  name: 'ChatMessage',
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  components: {
    Avatar,
    ChatMessageText,
    MemberPopup
  }
}
</script>

<style scoped lang="scss">
@import "@/styles/variables";

.chat-message {
  cursor: pointer;
  padding: 12px 20px;
  display: flex;
  gap: 12px;
}
.avatar {
  border-radius: 8px;
  overflow: hidden;
}

.layout {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}
.info {
  display: flex;
  align-items: center;
  gap: 8px;
  .name {
    font-family: "Roboto Medium", sans-serif;
    font-size: 16px;
    color: var(--bright-color);
  }
  .timestamp {
    color: var(--muted-color);
    font-size: 12px;
  }
}
.content {
  display: flex;
  flex-direction: column;
  .text {
    margin-top: 4px;
    font-size: 16px;
    white-space: pre-wrap;
    word-break: break-word;
    overflow-wrap: break-word;
    line-height: 20px;
  }
  .attachments {
    margin-top: 8px;
    .attachment {
      svg {
        display: block;
        max-height: 200px;
      }
      image {
        display: block;
      }
    }
  }
}
</style>
