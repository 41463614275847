<template>
  <div class="live-stream">
    <HostWrap>
      <div class="embed-responsive">
          <video
            id="video"
            class="embed-responsive-item"
            ref="video"
            :srcObject.prop="srcObject"
            disablepictureinpicture
            playsinline
          ></video>
      </div>
    </HostWrap>
    <template v-if="loading">
      <div class="loading">
        <Preloader class="spinner"></Preloader>
      </div>
    </template>
    <template v-if="showPlay">
      <div class="play" @click.prevent="play">
        <SvgPlayCircle width="60"></SvgPlayCircle>
      </div>
    </template>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { liveStreamWsUrl } from '@/settings'
import { WsClient } from '@/helper/ws'
import HostWrap from '@/components/room/online/HostWrap'
import SvgPlayCircle from '@/components/svg/SvgPlayCircle'
import Preloader from '@/components/Preloader'

export default {
  name: 'LiveStream',
  components: {
    HostWrap,
    SvgPlayCircle,
    Preloader
  },
  data () {
    return {
      ws: new WsClient({ wsUrl: liveStreamWsUrl, getToken: () => this.$service.jwt.accessToken }),
      loading: true,
      showPlay: false,
      srcObject: null,
      webRtcPeer: null,
      turnUser: null,
      turnPass: null,
      turnUrl: null,
      pingTimer: null
    }
  },
  computed: {
    ...mapState('room', {
      livePartyId: (state) => state.livePartyId,
      volume: (state) => state.volume,
      muted: (state) => state.muted
    })
  },
  watch: {
    volume (value) {
      if (this.$refs.video) {
        this.$refs.video.volume = value
      }
    },
    muted (value) {
      if (this.$refs.video) {
        this.$refs.video.muted = value
      }
    }
  },
  mounted () {
    this.$refs.video.addEventListener('loadeddata', this.onStreamDataLoaded)
  },
  beforeMount () {
    this.ws.once('authorized', this.onAuthorized)
    this.ws.connect({ party_id: this.livePartyId })
    this.initPing()
  },
  beforeUnmount () {
    this.$refs.video.removeEventListener('loadeddata', this.onStreamDataLoaded)
    if (this.webRtcPeer) {
      this.webRtcPeer.close()
      this.webRtcPeer = null
    }
    this.turnUrl = null
    this.turnUser = null
    this.turnPass = null
    this.ws.removeListener('authorized', this.onAuthorized)
    this.ws.disconnect()
    this.destroyPing()
  },
  methods: {
    initPing () {
      this.pingTimer = setInterval(() => {
        if (!this.ws || !this.ws.connected) {
          return
        }
        this.ws.send({ t: 'ping', d: {} })
      }, 10000)
    },
    destroyPing () {
      if (this.pingTimer) {
        clearInterval(this.pingTimer)
        this.pingTimer = null
      }
    },
    async onAuthorized (d) {
      const { turn_user: turnUser, turn_pass: turnPass, turn_url: turnUrl } = d
      this.turnUser = turnUser
      this.turnPass = turnPass
      this.turnUrl = turnUrl
      await this.initWebRtc()
    },
    async initWebRtc () {
      this.loading = true
      this.webRtcPeer = new RTCPeerConnection({
        iceServers: [
          {
            urls: this.turnUrl,
            username: this.turnUser,
            credential: this.turnPass
          }
        ]
      })
      // on local icecandidate
      this.webRtcPeer.addEventListener(
        'icecandidate',
        this.onLocalIceCandidate
      )
      // on track
      this.webRtcPeer.addEventListener('track', this.onTrack)
      // create sdp offer
      this.webRtcPeer.addTransceiver('video')
      this.webRtcPeer.addTransceiver('audio')
      const sdpOffer = await this.webRtcPeer.createOffer({
        offerToReceiveAudio: true,
        offerToReceiveVideo: true
      })
      await this.webRtcPeer.setLocalDescription(sdpOffer)
      // on remote icecandidate
      this.ws.on('ice.candidate', this.onRemoteIceCandidate)
      // on sdp answer
      this.ws.once('sdp.answer', this.onSdpAnswer)
      // send sdp offer
      this.ws.send({
        t: 'sdp.offer',
        d: { sdp_offer: sdpOffer.sdp }
      })
    },
    onSdpAnswer (d) {
      const { sdp_answer: sdpAnswer } = d
      this.webRtcPeer.setRemoteDescription(
        new RTCSessionDescription({
          type: 'answer',
          sdp: sdpAnswer
        })
      )
    },
    onLocalIceCandidate (event) {
      if (!event.candidate) {
        return
      }
      this.ws.send({
        t: 'ice.candidate',
        d: { ice_candidate: event.candidate }
      })
    },
    onRemoteIceCandidate (d) {
      const { ice_candidate: iceCandidate } = d
      this.webRtcPeer.addIceCandidate(iceCandidate)
    },
    onTrack (event) {
      this.srcObject = event.streams[0]
    },
    async onStreamDataLoaded () {
      this.loading = false
      try {
        await this.$refs.video.play()
      } catch (e) {
        console.error(e)
        this.showPlay = true
        return
      }
      this.showPlay = false
    },
    play () {
      this.$refs.video.play()
      this.showPlay = false
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/styles/variables";
@import "@/styles/breakpoints";

.live-stream {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.play {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  background-color: #0000;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: transform 0.2s;
  color: var(--muted-color);
  &:hover {
    color: var(--primary-color);
    transform: scale(1.25);
  }
}

.loading {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  background-color: #0000;
  display: flex;
  align-items: center;
  justify-content: center;
  .spinner {
    --preloader-size: 60px;
    @include media-breakpoint-down(xl) {
      --preloader-size: 48px;
    }
  }
}

.embed-responsive {
  position: relative;
  display: block;
  height: 100%;
  width: 100%;
  padding: 0;
  overflow: hidden;
  .embed-responsive-item {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
  }
}
</style>
