<template>
  <div class="sticky-header" :class="{ stickied: stickied }">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'StickyHeader',
  props: {
    stickied: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style scoped lang="scss">
@import "@/styles/variables";

.sticky-header {
  width: 100%;
  max-height: $sticky-header-height;
  min-height: $sticky-header-height;
  height: $sticky-header-height;
  background-color: var(--sticky-header-bg);
  display: flex;
  transition: all 0.25s ease-out;
  &.stickied {
    background-color: var(--sticky-header-sticked-bg);
    max-height: $sticky-header-stickied-height;
    min-height: $sticky-header-stickied-height;
    height: $sticky-header-stickied-height;
  }
}
</style>
