<template>
  <div class="logout-modal">
    <ModalHeader :modal="modal">
      <template v-slot:title>Logout</template>
    </ModalHeader>
    <form class="form">
      <div class="content line-height">
        Are you sure you want to logout?
      </div>
      <ModalFooter class="footer">
        <template v-slot:content>
          <button
            type="button"
            class="btn-primary-sm btn-confirm"
            @click="logout"
          >
            CONFIRM
          </button>
        </template>
      </ModalFooter>
    </form>
  </div>
</template>

<script>
import ModalHeader from '@/components/modals/ModalHeader'
import ModalFooter from '@/components/modals/ModalFooter'

export default {
  name: 'LogoutModal',
  components: {
    ModalHeader,
    ModalFooter
  },
  props: {
    modal: {
      type: Object,
      required: true
    }
  },
  methods: {
    close () {
      this.modal.close()
    },
    async logout () {
      await this.$service.iam.logout()
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/styles/variables";
@import "@/styles/breakpoints";

.logout-modal {
  width: 100vw;
  display: flex;
  flex-direction: column;
  .footer {
    .btn-confirm {
      max-width: 110px;
    }
  }
  @include media-breakpoint-up(sm) {
    max-width: 380px;
  }
}

.form {
  display: flex;
  flex-direction: column;
  .content {
    padding: 0 24px;
    font-family: "Roboto Medium", sans-serif;
  }
}
</style>
