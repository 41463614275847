<template>
  <div class="mobile-offline">
    Room is Offline
  </div>
</template>

<script>
export default {
  name: 'MobileOffline'
}
</script>

<style lang="scss" scoped>
@import "@/styles/variables";
@import "@/styles/breakpoints";

.mobile-offline {
  width: 100%;
  aspect-ratio: 16 / 9;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Roboto Mono Medium", sans-serif;
  font-size: 14px;
  color: var(--muted-color);
}
</style>
