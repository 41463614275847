<template>
  <div class="membership-toast">
    <div class="message line-height">
      {{ toast.data.context.text }}
    </div>
    <div class="actions">
      <router-link class="btn-primary-sm"
                   :to="{ name: 'membership' }"
      >
        GET BEARCAT MEMBERSHIP
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MembershipToast',
  props: {
    toast: {
      type: Object,
      required: true
    }
  }
}
</script>

<style scoped lang="scss">
.membership-toast {
  padding: 16px 24px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.actions {
  display: flex;
  justify-content: center;
}
</style>
