<template>
  <div class="offline-mini" v-if="mini">
    Room is Offline
  </div>
  <div class="offline" v-else>
    <RoomHeader></RoomHeader>
    <div class="content">
      Room is Offline
    </div>
  </div>
</template>

<script>
import RoomHeader from '@/components/room/header/RoomHeader'
import { mapState } from 'vuex'

export default {
  name: 'Offline',
  components: {
    RoomHeader
  },
  computed: {
    ...mapState('room', {
      mini: (state) => state.mini,
      roomId: (state) => state.roomId
    })
  }
}
</script>

<style lang="scss" scoped>
@import "@/styles/variables";
@import "@/styles/breakpoints";

.offline-mini {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Roboto Mono Medium", sans-serif;
  font-size: 14px;
  color: var(--muted-color);
}

.offline {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.content {
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Roboto Mono Medium", sans-serif;
  font-size: 15px;
  color: var(--muted-color);
}
</style>
