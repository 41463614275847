<template>
  <div class="sidebar">
    <div class="nav">
      <router-link
        class="nav-item-wrap"
        :class="{ 'nav-item-wrap-active': nav === 'home' }"
        :to="{ name: 'home' }"
      >
        <div
          class="nav-item"
        >
          <SvgHome width="20"></SvgHome>
          Home
        </div>
      </router-link>
      <router-link
        class="nav-item-wrap"
        :class="{ 'nav-item-wrap-active': nav === 'membership' }"
        :to="{ name: 'membership' }"
      >
        <div
          class="nav-item"
        >
          <SvgHeart width="20"></SvgHeart>
          Membership
        </div>
      </router-link>
      <router-link
        class="nav-item-wrap"
        :class="{ 'nav-item-wrap-active': nav === 'account' }"
        :to="{ name: 'account' }"
      >
        <div
          class="nav-item"
        >
          <SvgUser width="20"></SvgUser>
          Account
        </div>
      </router-link>
      <router-link
        class="nav-item-wrap"
        :class="{ 'nav-item-wrap-active': nav === 'help' }"
        :to="{ name: 'help' }"
      >
        <div
          class="nav-item"
        >
          <SvgHelp width="20"></SvgHelp>
          Help
        </div>
      </router-link>
    </div>
  </div>
</template>

<script>
import SvgHeart from '@/components/svg/SvgHeart'
import SvgHome from '@/components/svg/SvgHome'
import SvgUser from '@/components/svg/SvgUser'
import SvgHelp from '@/components/svg/SvgHelp'

export default {
  name: 'Sidebar',
  components: {
    SvgHeart,
    SvgHome,
    SvgUser,
    SvgHelp
  },
  data () {
    return {
      nav: this.$route.name
    }
  }
}
</script>

<style scoped lang="scss">
@import "@/styles/variables";

.sidebar {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.nav {
  display: flex;
  flex-direction: column;
  .nav-item-wrap {
    text-decoration: none;
    padding: 8px 0;
    .nav-item {
      height: 52px;
      display: flex;
      align-items: center;
      gap: 16px;
      padding: 0 24px;
      border-radius: 8px;
      -webkit-transition: background-color 100ms linear;
      -ms-transition: background-color 100ms linear;
      transition: background-color 100ms linear;
      font-family: "Roboto Mono Medium", sans-serif;
      text-decoration: none;
      color: var(--primary-color);
      background-color: transparent;
    }
    &.nav-item-wrap-active {
      .nav-item {
        color: var(--sidebar-active-color);
        background-color: var(--sidebar-active-bg);
      }
    }
    &:hover:not(.nav-item-wrap-active) {
      .nav-item {
        color: var(--hover-color);
        background-color: var(--hover-bg);
      }
    }
  }
}
</style>
