<template>
  <div class="offline-owner-mini" v-if="mini">
    Room is Offline
  </div>
  <div class="offline-owner" v-else>
    <RoomHeader></RoomHeader>
    <div class="content" ref="content"
         :class="{ flexed: flexed, 'scroll-y': !flexed }"
    >
      <div class="start-party" ref="startParty">
        <div class="title">START PARTY</div>
        <div class="items">
          <Site @click="startParty('duckduckgo')"
                :processing="processing === 'duckduckgo'"
          >
            <template v-slot:logo>
              <SDuckDuckGo width="120"></SDuckDuckGo>
            </template>
            <template v-slot:name> DuckDuckGo </template>
          </Site>
          <Site @click="startParty('google')"
                :processing="processing === 'google'"
          >
            <template v-slot:logo>
              <SGoogle width="120"></SGoogle>
            </template>
            <template v-slot:name> Google </template>
          </Site>
          <Site @click="startParty('youtube')"
                :processing="processing === 'youtube'"
          >
            <template v-slot:logo>
              <SYouTube width="120"></SYouTube>
            </template>
            <template v-slot:name> YouTube </template>
          </Site>
          <Site @click="startParty('instagram')"
                :processing="processing === 'instagram'"
          >
            <template v-slot:logo>
              <SInstagram width="120"></SInstagram>
            </template>
            <template v-slot:name> Instagram </template>
          </Site>
          <Site @click="startParty('twitch')"
                :processing="processing === 'twitch'"
          >
            <template v-slot:logo>
              <STwitch width="120"></STwitch>
            </template>
            <template v-slot:name> Twitch </template>
          </Site>
          <Site @click="startParty('fmovies')"
                :processing="processing === 'fmovies'"
          >
            <template v-slot:logo>
              <SFMovies width="120"></SFMovies>
            </template>
            <template v-slot:name> FMovies </template>
          </Site>
          <Site @click="startParty('123movies')"
                :processing="processing === '123movies'"
          >
            <template v-slot:logo>
              <S123Movies width="120"></S123Movies>
            </template>
            <template v-slot:name> 123Movies </template>
          </Site>
          <Site @click="startParty('9anime')"
                :processing="processing === '9anime'"
          >
            <template v-slot:logo>
              <S9Anime width="80"></S9Anime>
            </template>
            <template v-slot:name> 9Anime </template>
          </Site>
          <Site @click="startParty('popcornflix')"
                :processing="processing === 'popcornflix'"
          >
            <template v-slot:logo>
              <SPopcornflix width="120"></SPopcornflix>
            </template>
            <template v-slot:name> Popcornflix </template>
          </Site>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import RoomHeader from '@/components/room/header/RoomHeader'
import { mapState } from 'vuex'
import Site from '@/components/room/offline-owner/Site'
import SGoogle from '@/components/room/offline-owner/SGoogle'
import S9Anime from '@/components/room/offline-owner/S9Anime'
import S123Movies from '@/components/room/offline-owner/S123Movies'
import SFMovies from '@/components/room/offline-owner/SFMovies'
import SInstagram from '@/components/room/offline-owner/SInstagram'
import SPopcornflix from '@/components/room/offline-owner/SPopcornflix'
import STwitch from '@/components/room/offline-owner/STwitch'
import SYouTube from '@/components/room/offline-owner/SYouTube'
import SDuckDuckGo from '@/components/room/offline-owner/SDuckDuckGo'
import { isNil } from '@/utils/common'

export default {
  name: 'OfflineOwner',
  components: {
    RoomHeader,
    Site,
    SGoogle,
    S9Anime,
    S123Movies,
    SFMovies,
    SInstagram,
    SPopcornflix,
    STwitch,
    SYouTube,
    SDuckDuckGo
  },
  computed: {
    ...mapState('room', {
      mini: (state) => state.mini,
      roomId: (state) => state.roomId
    }),
    ...mapState('me', {
      role: (state) => state.role
    }),
    imGuest () {
      return this.role === 'GUEST'
    }
  },
  data () {
    return {
      siteUrls: {
        google: 'https://www.google.com',
        youtube: 'https://www.youtube.com',
        instagram: 'https://www.instagram.com',
        twitch: 'https://www.twitch.tv',
        fmovies: 'https://ww4.fmovies.co',
        '123movies': 'https://123-moviesonline.com',
        '9anime': 'https://9animetv.to',
        popcornflix: 'https://popcornflix.rest',
        duckduckgo: 'https://duckduckgo.com'
      },
      resizeObserver: null,
      flexed: true,
      processing: null
    }
  },
  async mounted () {
    this.initResizeObserver()
  },
  beforeUnmount () {
    this.destroyResizeObserver()
  },
  methods: {
    async startParty (site) {
      if (!isNil(this.processing)) {
        return
      }
      if (this.imGuest) {
        this.$toast.warning({
          type: 'verification'
        })
        return
      }
      this.processing = site
      try {
        await this.$service.room.startParty(this.roomId, this.siteUrls[site])
      } finally {
        this.processing = null
      }
    },
    initResizeObserver () {
      this.destroyResizeObserver()
      const resizeObserver = new ResizeObserver(() => {
        if (this.$refs.content) {
          const contentHeight = this.$refs.content.clientHeight
          const startPartyHeight = this.$refs.startParty.clientHeight
          this.flexed = startPartyHeight < contentHeight
        }
      })
      resizeObserver.observe(this.$refs.content)
    },
    destroyResizeObserver () {
      if (this.resizeObserver) {
        this.resizeObserver.disconnect()
        this.resizeObserver = null
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/styles/variables";
@import "@/styles/breakpoints";

.offline-owner-mini {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Roboto Mono Medium", sans-serif;
  font-size: 14px;
  color: var(--muted-color);
}

.offline-owner {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.content {
  flex-grow: 1;
  width: 100%;
  height: 100%;
  &.flexed {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.start-party {
  display: flex;
  flex-direction: column;
  gap: 32px;
  padding: $content-padding;
  .title {
    font-family: "Roboto Mono Medium", sans-serif;
    position: relative;
    height: 60px;
    font-size: 19px;
    padding: 0 32px;
    display: flex;
    align-items: center;
    color: var(--bright-color);
    &:before {
      position: absolute;
      content: "";
      height: 3px;
      width: 100%;
      left: 0;
      bottom: 0;
      background-color: var(--bright-bg);
    }
  }
  .items {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    column-gap: 48px;
    row-gap: 48px;
  }
}

.offline-owner {
  @include media-breakpoint-down(xxl) {
    .content {
      .start-party {
        .items {
          grid-template-columns: repeat(4, 1fr);
        }
      }
    }
  }
  @include media-breakpoint-down(xl) {
    .content {
      .start-party {
        .items {
          grid-template-columns: repeat(3, 1fr);
        }
      }
    }
  }
  @include media-breakpoint-down(lg) {
    .content {
      .start-party {
        .items {
          grid-template-columns: repeat(2, 1fr);
        }
      }
    }
  }
  @include media-breakpoint-down(md) {
    .content {
      .start-party {
        .items {
          grid-template-columns: repeat(1, 1fr);
        }
      }
    }
  }
}
</style>
