<template>
  <ContentLayout>
    <template v-slot:title>ADD ROOM</template>
    <template v-slot:content>
      <div class="add-room">
        <div class="description line-height">
          Room allows you to surf the web and chat with friends.
        </div>
        <button class="btn-primary-sm btn-add-room" type="button" @click="createRoom">ADD ROOM</button>
      </div>
    </template>
  </ContentLayout>
</template>

<script>
import { mapState } from 'vuex'
import ContentLayout from '@/components/layout/ContentLayout'

export default {
  name: 'AddRoom',
  components: {
    ContentLayout
  },
  computed: {
    ...mapState('me', {
      role: (state) => state.role
    }),
    imGuest () {
      return this.role === 'GUEST'
    }
  },
  methods: {
    createRoom () {
      if (this.imGuest) {
        this.$toast.warning({
          type: 'verification'
        })
        return
      }
      this.$modal.open({
        type: 'create-room'
      })
    }
  }
}
</script>

<style scoped lang="scss">
@import "@/styles/variables";
@import "@/styles/breakpoints";

.add-room {
  display: grid;
  grid-template-columns: 1fr 110px;
  column-gap: 16px;
  row-gap: 24px;
  align-items: center;
  @include media-breakpoint-down(sm) {
    grid-template-columns: repeat(1, 1fr);
  }
}
</style>
