<template>
  <div class="mobile-sidebar">
    <router-link
      class="btn-base nav-item"
      :class="{ 'nav-item-active': nav === 'account' }"
      :to="{ name: 'account' }"
    >
      <SvgUser width="20"></SvgUser>
    </router-link>
    <router-link
      class="btn-base nav-item"
      :class="{ 'nav-item-active': nav === 'membership' }"
      :to="{ name: 'membership' }"
    >
      <SvgHeart width="20"></SvgHeart>
    </router-link>
    <router-link
      class="btn-base nav-item"
      :class="{ 'nav-item-active': nav === 'home' }"
      :to="{ name: 'home' }"
    >
      <SvgHome width="20"></SvgHome>
    </router-link>
  </div>
</template>

<script>
import SvgHeart from '@/components/svg/SvgHeart'
import SvgHome from '@/components/svg/SvgHome'
import SvgUser from '@/components/svg/SvgUser'

export default {
  name: 'MobileSidebar',
  components: {
    SvgHeart,
    SvgHome,
    SvgUser
  },
  data () {
    return {
      nav: 'home'
    }
  }
}
</script>

<style scoped lang="scss">
@import "@/styles/variables";

.mobile-sidebar {
  width: 100%;
  height: $mobile-sidebar-height;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  background-color: var(--mobile-sidebar-bg);
}
.nav-item {
  color: var(--primary-color);
  width: 100%;
  height: $mobile-sidebar-height;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
