<template>
  <div class="mobile-control">
    <div class="content">
      <div class="touch-pad"
           @touchmove="onTouchMove"
           @touchend="resetTouch"
           @touchstart="resetTouch"
           @click="onMouseLeftButtonClick"
      >
        <SvgMove width="20"></SvgMove>
      </div>
      <div class="action-pad">
        <button class="btn-secondary btn-action"
                @click="showKeyboard"
        >
          <SvgControl width="26"></SvgControl>
        </button>
        <div class="btn-primary btn-action"
             @contextmenu="preventContextmenu"
             @pointerdown="onMouseLeftButtonPointerDown"
             @pointerup="onMouseLeftButtonPointerUp"
        >
          <SvgMouseLeftClick width="26"></SvgMouseLeftClick>
        </div>
        <div class="btn-secondary btn-action"
             @contextmenu="preventContextmenu"
             @pointerdown="onMouseRightButtonPointerDown"
             @pointerup="onMouseRightButtonPointerUp"
        >
          <SvgMouseRightClick width="26"></SvgMouseRightClick>
        </div>
      </div>
    </div>
    <div class="scroll-pad">
      <div class="scroll-up">
        <div class="btn-secondary-icon-sm btn-scroll"
             @pointerdown="onScrollButtonPointerDown(false)"
             @pointerup="onScrollButtonPointerUp"
             @contextmenu="preventContextmenu"
        >
          <SvgChevronUp width="26"></SvgChevronUp>
        </div>
      </div>
      <div class="scroll-down">
        <div class="btn-secondary-icon-sm btn-scroll"
             @pointerdown="onScrollButtonPointerDown(true)"
             @pointerup="onScrollButtonPointerUp"
             @contextmenu="preventContextmenu"
        >
          <SvgChevronDown width="26"></SvgChevronDown>
        </div>
      </div>
    </div>
    <div class="input-wrap">
      <input class="input"
             type="text"
             @input="onInput"
             ref="input"
      >
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import SvgChevronUp from '@/components/svg/SvgChevronUp'
import SvgChevronDown from '@/components/svg/SvgChevronDown'
import SvgControl from '@/components/svg/SvgControl'
import SvgMouseLeftClick from '@/components/svg/SvgMouseLeftClick'
import SvgMouseRightClick from '@/components/svg/SvgMouseRightClick'
import SvgMove from '@/components/svg/SvgMove'
import { isNil } from '@/utils/common'

export default {
  name: 'MobileControl',
  components: {
    SvgChevronUp,
    SvgChevronDown,
    SvgControl,
    SvgMouseLeftClick,
    SvgMouseRightClick,
    SvgMove
  },
  computed: {
    ...mapState('me', {
      userId: (state) => state.userId
    }),
    ...mapState('room', {
      hostId: (state) => state.hostId
    }),
    imHost () {
      return (
        !isNil(this.hostId) &&
        !isNil(this.userId) &&
        this.userId === this.hostId
      )
    }
  },
  beforeUnmount () {
    this.stopScrollDelayTimer()
  },
  data () {
    return {
      scrollDelayTimer: null,
      touchX: null,
      touchY: null
    }
  },
  methods: {
    onInput (e) {
      if (isNil(e.data)) {
        this.emitEvent({ key: 'Backspace', ctrlKey: false, shiftKey: false }, 'KEY_DOWN')
        this.emitEvent({ key: 'Backspace', ctrlKey: false, shiftKey: false }, 'KEY_UP')
        return
      }
      this.emitEvent({ key: e.data, ctrlKey: false, shiftKey: false }, 'KEY_DOWN')
      this.emitEvent({ key: e.data, ctrlKey: false, shiftKey: false }, 'KEY_UP')
    },
    preventContextmenu (e) {
      e.preventDefault()
    },
    onScrollButtonPointerDown (scrollUp) {
      this.stopScrollDelayTimer()
      this.scrollDelayTimer = setInterval(() => {
        this.emitEvent({ scrollUp }, 'MOUSE_SCROLL')
      }, 50)
      this.emitEvent({ scrollUp }, 'MOUSE_SCROLL')
    },
    onScrollButtonPointerUp () {
      this.stopScrollDelayTimer()
    },
    stopScrollDelayTimer () {
      if (isNil(this.scrollDelayTimer)) {
        return
      }
      clearInterval(this.scrollDelayTimer)
      this.scrollDelayTimer = null
    },
    onMouseLeftButtonClick () {
      this.emitEvent({ button: 1 }, 'MOUSE_DOWN')
      this.emitEvent({ button: 1 }, 'MOUSE_UP')
    },
    onMouseLeftButtonPointerDown () {
      this.emitEvent({ button: 1 }, 'MOUSE_DOWN')
    },
    onMouseLeftButtonPointerUp () {
      this.emitEvent({ button: 1 }, 'MOUSE_UP')
    },
    onMouseRightButtonPointerDown () {
      this.emitEvent({ button: 3 }, 'MOUSE_DOWN')
    },
    onMouseRightButtonPointerUp () {
      this.emitEvent({ button: 3 }, 'MOUSE_UP')
    },
    resetTouch () {
      this.touchX = null
      this.touchY = null
    },
    onTouchMove (e) {
      e.preventDefault()
      if (isNil(this.touchX) && isNil(this.touchY)) {
        this.touchX = e.changedTouches[0].clientX
        this.touchY = e.changedTouches[0].clientY
        return
      }
      this.emitEvent({
        x: (e.changedTouches[0].clientX - this.touchX) * 2.5,
        y: (e.changedTouches[0].clientY - this.touchY) * 2.5
      }, 'MOUSE_MOVE_DIFF')
      this.touchX = e.changedTouches[0].clientX
      this.touchY = e.changedTouches[0].clientY
    },
    showKeyboard () {
      this.$refs.input.focus()
    },
    emitEvent (a, n) {
      if (this.imHost) {
        this.$service.roomWs.publishCmd(a, n)
      }
    }
  }
}
</script>

<style scoped lang="scss">
@import "@/styles/variables";

.mobile-control {
  width: 100%;
  height: 100%;
  display: flex;
  padding: 24px 20px;
  gap: 16px;
  position: relative;
  overflow: hidden;
  .input-wrap {
    position: absolute;
    top: 0;
    left: 0;
    width: 10px;
    height: 10px;
    opacity: 0;
    pointer-events: none;
    .input {
      width: 100%;
      height: 100%;
    }
  }
  .content {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    gap: 16px;
    .touch-pad {
      flex-grow: 1;
      background-color: var(--room-primary-bg);
      border-radius: 6px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: var(--muted-color);
    }
    .action-pad {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      column-gap: 16px;
    }
  }
  .scroll-pad {
    width: 100%;
    max-width: 40px;
    display: grid;
    grid-template-rows: 1fr 1fr;
    row-gap: 16px;
    .scroll-up {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .scroll-down {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .btn-scroll {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
</style>
