import { Centrifuge } from 'centrifuge'
import store from '@/store'

export class CentrifugoService {
  constructor (app, centrifugoUrl) {
    this.app = app
    this.centrifugo = new Centrifuge(centrifugoUrl, {
      token: '',
      getToken: () => this._refreshToken()
    })
    this.centrifugo.on('connecting', (ctx) => {
      console.log('connecting', ctx)
    })
    this.centrifugo.on('connected', (ctx) => {
      console.log('connected', ctx)
    })
    this.centrifugo.on('disconnected', async (ctx) => {
      console.log('disconnected', ctx)
    })
    this.centrifugo.on('error', (ctx) => {
      console.log('error', ctx)
    })
    this.connected = false
    this.personal = null
  }

  async _refreshToken () {
    await this.iam.refresh()
    return this.jwt.accessToken
  }

  get iam () {
    return this.app.config.globalProperties.$service.iam
  }

  get jwt () {
    return this.app.config.globalProperties.$service.jwt
  }

  connect () {
    if (this.connected) {
      return
    }
    this.centrifugo.setToken(this.jwt.accessToken)
    this.centrifugo.connect()
    this.personal = this.centrifugo.newSubscription(
      `personal:#${this.jwt.userId}`
    )
    this.personal.on('publication', async (ctx) => {
      await this._onPublication(ctx)
    })
    this.connected = true
  }

  disconnect () {
    if (!this.connected) {
      return
    }
    this.centrifugo.setToken('')
    this.centrifugo.disconnect()
    this.centrifugo.removeSubscription(this.personal)
    this.personal.removeAllListeners()
    this.personal = null
    this.connected = false
  }

  newSubscription (channel, options) {
    return this.centrifugo.newSubscription(channel, options)
  }

  removeSubscription (sub) {
    return this.centrifugo.removeSubscription(sub)
  }

  async _onPublication (ctx) {
    console.info('received.publication', ctx)
    if (ctx.data.stream === 'avatar') {
      await this.onAvatar(ctx.data)
      return
    } else if (ctx.data.stream === 'name') {
      await this.onName(ctx.data)
      return
    } else if (ctx.data.stream === 'censored.email') {
      await this.onCensoredEmail(ctx.data)
      return
    } else if (ctx.data.stream === 'censored.google') {
      await this.onCensoredGoogle(ctx.data)
      return
    } else if (ctx.data.stream === 'censored.discord') {
      await this.onCensoredDiscord(ctx.data)
      return
    } else if (ctx.data.stream === 'membership') {
      await this.onMembership(ctx.data)
      return
    } else if (ctx.data.stream === 'subscription') {
      await this.onSubscription(ctx.data)
      return
    } else if (ctx.data.stream === 'role') {
      await this.onRole(ctx.data)
      return
    }
    console.info('unhandled.publication', ctx)
  }

  async onAvatar (data) {
    store.commit('me/update', {
      avatar: data.avatar
    })
  }

  async onName (data) {
    store.commit('me/update', {
      name: data.name
    })
  }

  async onCensoredEmail (data) {
    store.commit('me/update', {
      censoredEmail: data.censored_email
    })
  }

  async onCensoredGoogle (data) {
    store.commit('me/update', {
      censoredGoogle: data.censored_google
    })
  }

  async onCensoredDiscord (data) {
    store.commit('me/update', {
      censoredDiscord: data.censored_discord
    })
  }

  async onMembership (data) {
    store.commit('me/update', {
      membership: data.membership,
      membershipExpiresAt: data.membership_expires_at
    })
  }

  async onSubscription (data) {
    await store.dispatch('me/subscription', {
      id: data.id,
      paymentSystem: data.payment_system,
      sourceId: data.source_id,
      plan: data.plan,
      planId: data.plan_id,
      status: data.status,
      paymentFailed: data.payment_failed
    })
  }

  async onRole (data) {
    store.commit('me/update', {
      role: data.role
    })
  }
}
