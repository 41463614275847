<template>
  <RoomWrap>
    <MobileRoomWin>
      <div class="mobile-room">
        <div class="content">
          <MobileOfflineOwner v-if="showOfflineOwner"></MobileOfflineOwner>
          <MobileOffline v-else-if="showOffline"></MobileOffline>
          <MobilePending v-else-if="showPending"></MobilePending>
          <MobileQueuing v-else-if="showQueuing"></MobileQueuing>
          <MobileOnline v-else-if="showOnline"></MobileOnline>
        </div>
        <MobileNav class="mobile-nav"></MobileNav>
      </div>
    </MobileRoomWin>
  </RoomWrap>
</template>

<script>
import { mapState } from 'vuex'
import RoomWrap from '@/components/room/RoomWrap'
import MobileRoomWin from '@/components/room/MobileRoomWin'
import MobileOfflineOwner from '@/components/room/offline-owner/MobileOfflineOwner'
import MobileQueuing from '@/components/room/queuing/MobileQueuing'
import MobilePending from '@/components/room/pending/MobilePending'
import MobileOnline from '@/components/room/online/MobileOnline'
import MobileNav from '@/components/room/mobile-nav/MobileNav'
import MobileOffline from '@/components/room/offline/MobileOffline'

export default {
  name: 'MobileRoom',
  components: {
    RoomWrap,
    MobileRoomWin,
    MobileOfflineOwner,
    MobileQueuing,
    MobileOnline,
    MobilePending,
    MobileNav,
    MobileOffline
  },
  computed: {
    ...mapState('me', {
      userId: state => state.userId
    }),
    ...mapState('room', {
      status: state => state.status,
      ownerId: state => state.ownerId,
      theater: state => state.theater
    }),
    isOwner () {
      return this.userId === this.ownerId
    },
    showOfflineOwner () {
      return this.status === 'OFFLINE' && this.isOwner
    },
    showOffline () {
      return this.status === 'OFFLINE'
    },
    showPending () {
      return this.status === 'PENDING'
    },
    showQueuing () {
      return this.status === 'QUEUING'
    },
    showOnline () {
      return this.status === 'ONLINE'
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/styles/variables";
@import "@/styles/breakpoints";

.mobile-room {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: var(--room-primary-bg);
  @include media-breakpoint-up(sm) {
    flex-direction: row;
    .content {
      flex-grow: 1;
      display: flex;
      align-items: center;
    }
    .mobile-nav {
      width: 100%;
      max-width: 300px;
    }
  }
}
</style>
