import { createApp } from 'vue'
import VOtpInput from 'vue3-otp-input'
import {
  Dropdown,
  Tooltip
} from 'floating-vue'
import App from '@/App'
import router from '@/router'
import store from '@/store'
import focus from '@/directives/focus'
import clickOutside from '@/directives/clickOutside'
import room from '@/components/room'
import modal from '@/plugins/modal'
import toast from '@/plugins/toast'
import service from '@/plugins/service'

createApp(App)
  .use(store)
  .use(router)
  .use(room)
  .use(modal)
  .use(toast)
  .use(service)
  .directive('focus', focus)
  .directive('click-outside', clickOutside)
  .component('v-otp-input', VOtpInput)
  .component('VDropdown', Dropdown)
  .component('VTooltip', Tooltip)
  .mount('#app')
