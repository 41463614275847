function defaultState () {
  return {
    avatar: null,
    name: null,
    censoredEmail: null,
    censoredDiscord: null,
    censoredGoogle: null,
    role: null,
    userId: null,
    membership: null,
    membershipExpiresAt: null,
    subscriptions: [],
    rooms: []
  }
}

export default {
  namespaced: true,
  state: defaultState(),
  getters: {},
  mutations: {
    update (state, data) {
      Object.assign(state, data)
    }
  },
  actions: {
    addRoom ({ state }, data) {
      state.rooms.unshift(data)
    },
    reset ({ commit }) {
      commit('update', defaultState())
    },
    subscription ({ state }, data) {
      const i = state.subscriptions.findIndex((s) => s.id === data.id)
      if (i > -1) {
        state.subscriptions.splice(i, 1)
      }
      if (['ACTIVE', 'SUSPENDED'].includes(data.status)) {
        state.subscriptions.unshift(data)
      }
    },
    delSubscription ({ state }, subscriptionId) {
      const i = state.subscriptions.findIndex((s) => s.id === subscriptionId)
      if (i > -1) {
        state.subscriptions.splice(i, 1)
      }
    }
  }
}
