<template>
  <div class="mobile-room-win" :class="{ mini: mini }">
    <div class="mobile-room-win-content">
      <div class="mobile-room-win-content-actions" v-if="mini">
        <router-link class="room-open"
                     :to="{ name: 'room', params: { roomSid: roomSid } }"
        >
          <div class="room-name clamp-1">
            {{ name }}
          </div>
          <div class="room-badges">
            <div class="room-stats">
              {{ memberCount }} watching
              <div
                class="dot dot-online"
                v-if="
                    status === 'ONLINE' ||
                    status === 'QUEUING' ||
                    status === 'PENDING'
                  "
              ></div>
              <div class="dot dot-offline" v-else></div>
            </div>
          </div>
        </router-link>
        <div class="room-close">
          <button type="button" class="btn btn-close" @click="close">
            <SvgClose width="25"></SvgClose>
          </button>
        </div>
      </div>
      <div class="mobile-room-content" v-show="!mini">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import SvgClose from '@/components/svg/SvgClose'

export default {
  name: 'MobileRoomWin',
  components: {
    SvgClose
  },
  computed: {
    ...mapState('room', {
      mini: (state) => state.mini,
      roomSid: (state) => state.roomSid,
      name: (state) => state.name,
      memberCount: (state) => state.memberCount,
      status: (state) => state.status
    })
  },
  methods: {
    close () {
      this.$room.close()
    }
  }
}
</script>

<style scoped lang="scss">
@import "@/styles/breakpoints";

.mobile-room-win {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  .mobile-room-win-content {
    position: relative;
    width: 100%;
    height: 100%;
  }
  &.mini {
    top: unset;
    left: 0;
    bottom: $mobile-sidebar-height;
    width: 100%;
    height: $mobile-room-mini-height;
  }
  .mobile-room-content {
    width: 100%;
    height: 100%;
  }
}
.mobile-room-win-content-actions {
  width: 100%;
  height: 100%;
  background: var(--mobile-room-mini-bg);
  display: flex;
  align-items: center;
  gap: 16px;
  padding: 0 6px 0 16px;
  .room-open {
    color: var(--primary-color);
    text-decoration: none;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    gap: 8px;
    .room-name {
      font-size: 15px;
      font-family: "Roboto Mono Medium", sans-serif;
    }
    .room-badges {
      display: flex;
      align-items: center;
      .room-stats {
        font-size: 14px;
        display: flex;
        align-items: center;
        gap: 8px;
        .dot {
          height: 8px;
          width: 8px;
          border-radius: 50%;
          display: inline-block;
        }
        .dot-online {
          background-color: var(--status-green);
        }
        .dot-offline {
          background-color: var(--status-default);
        }
      }
    }
  }
  .room-close {
    padding: 4px;
  }
}
</style>
