<template>
  <div class="site">
    <slot name="logo"></slot>
    <div class="name">
      <slot name="name"></slot>
    </div>
    <div class="preprocessor-wrap" v-if="processing">
      <Preprocessor class="spinner"></Preprocessor>
    </div>
  </div>
</template>

<script>
import Preprocessor from '@/components/Preprocessor'

export default {
  name: 'Site',
  components: {
    Preprocessor
  },
  props: {
    processing: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/styles/variables";

.site {
  width: 100%;
  height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--bright-color);
  position: relative;
  border-radius: 8px;
  overflow: hidden;
  cursor: pointer;
  user-select: none;
  padding: 32px;
  .name {
    transition: all 0.25s ease-out;
    position: absolute;
    left: 0;
    bottom: -32px;
    height: 32px;
    width: 100%;
    background: var(--bright-bg);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &:hover {
    .name {
      bottom: 0;
    }
  }
  &:active {
    transform: translateY(4px);
  }
  .preprocessor-wrap {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: var(--bright-color);
    display: flex;
    align-items: center;
    justify-content: center;
    .spinner {
      --preprocessor-size: 48px;
      --preprocessor-color: var(--bright-bg);
      --preprocessor-stroke: 3px;
    }
  }
}
</style>
