<template>
  <div class="preloader">
    <div class="preloader-spinner"></div>
  </div>
</template>

<script>
export default {
  name: 'Preloader'
}
</script>

<style scoped lang="scss">
.preloader {
  --preloader-size: 60px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  .preloader-spinner {
    width: var(--preloader-size);
    height: var(--preloader-size);
    border-right: 2px solid white;
    border-radius: 100%;
    animation: spinRight 800ms linear infinite;
  }
}

@keyframes spinLeft {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(720deg);
  }
}

@keyframes spinRight {
  from {
    transform: rotate(360deg);
  }
  to {
    transform: rotate(0deg);
  }
}
</style>
