<template>
  <SidebarLayout>
    <template v-slot:content>
      <div class="help">
        <HelpText></HelpText>
      </div>
    </template>
  </SidebarLayout>
</template>

<script>
import SidebarLayout from '@/components/layout/SidebarLayout'
import HelpText from '@/views/help/HelpText'

export default {
  name: 'Help',
  components: {
    SidebarLayout,
    HelpText
  }
}
</script>

<style scoped lang="scss">
@import "@/styles/variables";
@import "@/styles/breakpoints";

.help {
  display: flex;
  flex-direction: column;
  gap: 32px;
  padding-bottom: $content-padding;
}
</style>
