<template>
  <Popup>
    <slot></slot>
    <template v-slot:content="{ hide }">
      <MobileNavMorePopupContent @close="hide"></MobileNavMorePopupContent>
    </template>
  </Popup>
</template>

<script>
import Popup from '@/components/Popup'
import MobileNavMorePopupContent from '@/components/room/mobile-nav/MobileNavMorePopupContent'

export default {
  name: 'MobileNavMorePopup',
  components: {
    Popup,
    MobileNavMorePopupContent
  }
}
</script>

<style scoped lang="scss">
@import "@/styles/variables";
</style>
