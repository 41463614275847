<template>
  <div class="membership-perks">
    <ContentLayout>
      <template v-slot:title>
        MEMBERSHIP PERKS
      </template>
      <template v-slot:content>
        <div class="membership-perks-content line-height">
          This membership helps us maintain the service and release new features!
          Please consider supporting us if you're enjoying Bearcat, and in return
          we'll give you some nice perks.
        </div>
      </template>
    </ContentLayout>
    <div class="perks">
      <ContentLayout>
        <template v-slot:content>
          <div class="perk">
            <div class="perk-icon">
              <SvgZap width="30"></SvgZap>
            </div>
            <div class="perk-title">Skip the Queue</div>
            <div class="perk-description line-height">
              No more queueing for rooms, we'll give you one instantly!
            </div>
          </div>
        </template>
      </ContentLayout>
      <ContentLayout>
        <template v-slot:content>
          <div class="perk">
            <div class="perk-icon">
              <SvgHourglass width="30"></SvgHourglass>
            </div>
            <div class="perk-title">No Time Limit</div>
            <div class="perk-description line-height">
              Watch for hours without worrying about the time.
            </div>
          </div>
        </template>
      </ContentLayout>
    </div>
  </div>
</template>

<script>
import SvgZap from '@/components/svg/SvgZap'
import SvgHourglass from '@/components/svg/SvgHourglass'
import ContentLayout from '@/components/layout/ContentLayout'

export default {
  name: 'MembershipPerks',
  components: {
    SvgZap,
    SvgHourglass,
    ContentLayout
  }
}
</script>

<style lang="scss" scoped>
@import "@/styles/variables";
@import "@/styles/breakpoints";

.membership-perks {
  display: flex;
  flex-direction: column;
  gap: 32px;
  @include media-breakpoint-down(sm) {
    .perks {
      grid-template-columns: repeat(1, 1fr);
    }
    gap: 16px;
  }
}
.perks {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 16px;
  row-gap: 16px;
  .perk {
    background-color: var(--content-layout-bg);
    display: flex;
    flex-direction: column;
    gap: 16px;
    .perk-icon {
      display: flex;
      justify-content: center;
    }
    .perk-title {
      display: flex;
      justify-content: center;
      font-size: 18px;
    }
    .perk-description {
      text-align: center;
    }
  }
}
</style>
