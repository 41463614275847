import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '@/views/home/HomeView'
import LoginView from '@/views/login/LoginView'
import OAuth2View from '@/views/oauth2/OAuth2View'
import MembershipView from '@/views/membership/MembershipView'
import AccountView from '@/views/account/AccountView'
import RoomView from '@/views/room/RoomView'
import HelpView from '@/views/help/HelpView'

function getMeta (meta) {
  return Object.assign(
    {
      loginRequired: false,
      preload: []
    },
    meta
  )
}

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta: getMeta({ loginRequired: true, preload: ['me'] })
  },
  {
    path: '/help',
    name: 'help',
    component: HelpView,
    meta: getMeta({ loginRequired: true, preload: ['me'] })
  },
  {
    path: '/membership',
    name: 'membership',
    component: MembershipView,
    meta: getMeta({ loginRequired: true, preload: ['me'] })
  },
  {
    path: '/account',
    name: 'account',
    component: AccountView,
    meta: getMeta({ loginRequired: true, preload: ['me'] })
  },
  {
    path: '/login',
    name: 'login',
    component: LoginView,
    meta: getMeta({ loginRequired: false, preload: [] })
  },
  {
    path: '/oauth2',
    name: 'oauth2',
    component: OAuth2View,
    meta: getMeta({ loginRequired: false, preload: [] })
  },
  {
    path: '/terms-of-service',
    name: 'terms-of-service',
    component: () => import('@/views/terms-of-service/TermsOfServiceView'),
    meta: getMeta({ loginRequired: false, preload: [] })
  },
  {
    path: '/privacy-policy',
    name: 'privacy-policy',
    component: () => import('@/views/privacy-policy/PrivacyPolicyView'),
    meta: getMeta({ loginRequired: false, preload: [] })
  },
  {
    path: '/:roomSid',
    name: 'room',
    component: RoomView,
    meta: getMeta({ loginRequired: false, preload: [] })
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
