<template>
  <RoomWrap>
    <RoomWin>
      <RoomLayout>
        <div class="room-content"
             :class="{ 'online-theater': status === 'ONLINE' && theater }"
        >
          <OfflineOwner v-if="showOfflineOwner"></OfflineOwner>
          <Offline v-else-if="showOffline"></Offline>
          <Pending v-else-if="showPending"></Pending>
          <Queuing v-else-if="showQueuing"></Queuing>
          <Online v-else-if="showOnline"></Online>
        </div>
      </RoomLayout>
    </RoomWin>
  </RoomWrap>
</template>

<script>
import { mapState } from 'vuex'
import RoomWrap from '@/components/room/RoomWrap'
import RoomWin from '@/components/room/RoomWin'
import RoomLayout from '@/components/room/RoomLayout'
import OfflineOwner from '@/components/room/offline-owner/OfflineOwner'
import Pending from '@/components/room/pending/Pending'
import Queuing from '@/components/room/queuing/Queuing'
import Online from '@/components/room/online/Online'
import Offline from '@/components/room/offline/Offline'

export default {
  name: 'Room',
  components: {
    RoomWrap,
    RoomWin,
    RoomLayout,
    OfflineOwner,
    Pending,
    Queuing,
    Online,
    Offline
  },
  computed: {
    ...mapState('me', {
      userId: state => state.userId
    }),
    ...mapState('room', {
      status: state => state.status,
      ownerId: state => state.ownerId,
      theater: state => state.theater
    }),
    isOwner () {
      return this.userId === this.ownerId
    },
    showOfflineOwner () {
      return this.status === 'OFFLINE' && this.isOwner
    },
    showOffline () {
      return this.status === 'OFFLINE'
    },
    showPending () {
      return this.status === 'PENDING'
    },
    showQueuing () {
      return this.status === 'QUEUING'
    },
    showOnline () {
      return this.status === 'ONLINE'
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/styles/variables";

.room-content {
  width: 100%;
  height: 100%;
  max-height: 100%;
  position: relative;
  background-color: var(--room-primary-bg);

  -webkit-transition: background-color 500ms linear;
  -ms-transition: background-color 500ms linear;
  transition: background-color 500ms linear;
  &.online-theater {
    background-color: var(--room-online-theater-bg);
  }
}
</style>
