import EventEmitter from 'events'

export class Message extends EventEmitter {
  constructor (data) {
    super()
    this.data = data
    this.timer = null
  }

  startTimer () {
    this.stopTimer()
    this.timer = setTimeout(() => {
      this.close()
    }, 4000)
  }

  stopTimer () {
    if (!this.timer) {
      return
    }
    clearTimeout(this.timer)
    this.timer = null
  }

  close () {
    this.stopTimer()
    this.emit('close')
  }
}
