<template>
  <div class="room-footer">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'RoomFooter'
}
</script>

<style lang="scss" scoped>
@import "@/styles/variables";

.room-footer {
  width: 100%;
  height: 100%;
  max-height: $room-footer-height;
  display: flex;
  align-items: center;
}
</style>
