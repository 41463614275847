<template>
  <button
    @click.prevent="$.emit('submit')"
    :disabled="processing || disabled"
    :type="type"
  >
    <Preprocessor class="preprocessor" v-if="processing"></Preprocessor>
    <span v-else :style="{ opacity: processing ? 0 : 1 }">
      <slot></slot>
    </span>
  </button>
</template>

<script>
import Preprocessor from '@/components/Preprocessor'

export default {
  name: 'ProcessButton',
  components: {
    Preprocessor
  },
  props: {
    type: {
      type: String,
      default: 'submit'
    },
    disabled: {
      type: Boolean,
      default: false
    },
    processing: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss" scoped>
.preprocessor {
  --preprocessor-size: calc(100% - 18px);
  --preprocessor-stroke: 2px;
}
</style>
