<template>
  <router-view/>
  <DeviceLayout>
    <template v-slot:desktop>
      <Room></Room>
    </template>
    <template v-slot:mobile>
      <MobileRoom></MobileRoom>
    </template>
  </DeviceLayout>
  <Modals>
    <template v-slot:cancel-subscription="{ modal }">
      <CancelSubscriptionModal :modal="modal"></CancelSubscriptionModal>
    </template>
    <template v-slot:create-room="{ modal }">
      <CreateRoomModal :modal="modal"></CreateRoomModal>
    </template>
    <template v-slot:upgrade-membership="{ modal }">
      <UpgradeMembershipModal :modal="modal"></UpgradeMembershipModal>
    </template>
    <template v-slot:upload-picture="{ modal }">
      <UploadPictureModal :modal="modal"></UploadPictureModal>
    </template>
    <template v-slot:change-name="{ modal }">
      <ChangeNameModal :modal="modal"></ChangeNameModal>
    </template>
    <template v-slot:setup-email="{ modal }">
      <SetupEmailModal :modal="modal"></SetupEmailModal>
    </template>
    <template v-slot:delete-account="{ modal }">
      <DeleteAccountModal :modal="modal"></DeleteAccountModal>
    </template>
    <template v-slot:logout="{ modal }">
      <LogoutModal :modal="modal"></LogoutModal>
    </template>
    <template v-slot:invite-friends="{ modal }">
      <InviteFriendsModal :modal="modal"></InviteFriendsModal>
    </template>
  </Modals>
  <Toasts>
    <template v-slot:simple="{ toast }">
      <SimpleToast :toast="toast"></SimpleToast>
    </template>
    <template v-slot:verification="{ toast }">
      <VerificationToast :toast="toast"></VerificationToast>
    </template>
    <template v-slot:membership="{ toast }">
      <MembershipToast :toast="toast"></MembershipToast>
    </template>
  </Toasts>
  <Preloader class="app-preloader" v-if="preloader"></Preloader>
</template>

<script>
import { mapState } from 'vuex'
import Modals from '@/plugins/modal/Modals'
import CancelSubscriptionModal from '@/components/modals/CancelSubscriptionModal'
import CreateRoomModal from '@/components/modals/CreateRoomModal'
import UpgradeMembershipModal from '@/components/modals/UpgradeMembershipModal'
import UploadPictureModal from '@/components/modals/UploadPictureModal'
import ChangeNameModal from '@/components/modals/ChangeNameModal'
import SetupEmailModal from '@/components/modals/SetupEmailModal'
import DeleteAccountModal from '@/components/modals/DeleteAccountModal'
import LogoutModal from '@/components/modals/LogoutModal'
import InviteFriendsModal from '@/components/modals/InviteFriendsModal'
import Toasts from '@/plugins/toast/Toasts'
import SimpleToast from '@/components/toast/SimpleToast'
import VerificationToast from '@/components/toast/VerificationToast'
import MembershipToast from '@/components/toast/MembershipToast'
import Preloader from '@/components/Preloader'
import DeviceLayout from '@/components/layout/DeviceLayout'
import Room from '@/components/room/Room'
import MobileRoom from '@/components/room/MobileRoom'

export default {
  name: 'App',
  components: {
    Modals,
    CancelSubscriptionModal,
    CreateRoomModal,
    UpgradeMembershipModal,
    UploadPictureModal,
    ChangeNameModal,
    SetupEmailModal,
    DeleteAccountModal,
    LogoutModal,
    InviteFriendsModal,
    Toasts,
    SimpleToast,
    VerificationToast,
    MembershipToast,
    Preloader,
    DeviceLayout,
    Room,
    MobileRoom
  },
  computed: {
    ...mapState({
      theme: (state) => state.theme,
      preloader: (state) => state.preloader
    }),
    ...mapState('room', {
      roomShown: (state) => state.shown
    })
  },
  watch: {
    theme (value) {
      document.documentElement.setAttribute('data-theme', value)
    }
  },
  beforeMount () {
    document.documentElement.setAttribute('data-theme', this.theme)
    this.$router.beforeResolve(this.beforeResolveRoute)
  },
  methods: {
    async beforeResolveRoute (to, from, next) {
      if (to.meta.loginRequired && !this.$service.jwt.has()) {
        await this.$service.iam.logout(to.fullPath)
        throw new Error('Login required')
      }
      if (to.meta.preload.length) {
        await this.$service.preload.fetch(to.meta.preload)
      }
      if (to.meta.loginRequired) {
        this.$service.centrifugo.connect()
      } else if (!['room'].includes(to.name)) {
        this.$service.centrifugo.disconnect()
      }
      if (this.roomShown && !['room'].includes(to.name)) {
        if (to.meta.loginRequired) {
          this.$store.dispatch('room/updateMode', { mini: true })
        } else {
          this.$room.close()
        }
      }
      next()
    }
  }
}
</script>

<style lang="scss">
@import "@/styles/app";

.app-preloader {
  background-color: var(--primary-bg);
}
</style>
