import { events } from '@/components/room/events'

export class Control {
  constructor (options = {}) {
    this.options = options
  }

  open (data) {
    events.emit('open', Object.assign({}, data))
  }

  close () {
    events.emit('close')
  }
}
