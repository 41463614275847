<template>
  <template v-if="isMobile">
    <slot name="mobile"></slot>
  </template>
  <template v-else>
    <slot name="desktop"></slot>
  </template>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'DeviceLayout',
  computed: {
    ...mapState({
      isMobile: (state) => state.isMobile
    })
  }
}
</script>
