import Axios from 'axios'
import store from '@/store'

class MembershipApi {
  constructor (api) {
    this.api = api
  }

  async createPaypalSubscription (sourceId) {
    return await this.api.post(
      '/subscriptions',
      {
        source_id: sourceId,
        payment_system: 'PAYPAL'
      },
      { useAccessToken: true }
    )
  }

  async cancelSubscription (subscriptionId) {
    return await this.api.post(
      `/subscriptions/${subscriptionId}/cancel`,
      {},
      { useAccessToken: true }
    )
  }
}

export class MembershipServiceError extends Error {
  constructor (code) {
    super(code)
    this.code = code
  }
}

export class MembershipService {
  constructor (app, api) {
    this.app = app
    this.api = new MembershipApi(api)
  }

  get toast () {
    return this.app.config.globalProperties.$toast
  }

  async createPaypalSubscription (sourceId) {
    try {
      await this.api.createPaypalSubscription(sourceId)
    } catch (err) {
      if (Axios.isCancel(err)) {
        throw new MembershipServiceError('request.canceled')
      } else if (Axios.isAxiosError(err)) {
        if (err.response && err.response.status === 400) {
          if (err.response.data.error.code === 'verification.required') {
            throw new MembershipServiceError('verification.required')
          } else if (err.response.data.error.code === 'already.has.subscription') {
            this.toast.warning({
              type: 'simple',
              context: {
                text: 'Please cancel your active subscription before purchasing a new one'
              }
            })
            throw new MembershipServiceError('already.has.subscription')
          }
        }
      }
      this.toast.somethingWentWrong()
      throw err
    }
  }

  async cancelSubscription (subscriptionId) {
    try {
      await this.api.cancelSubscription(subscriptionId)
    } catch (err) {
      if (Axios.isCancel(err)) {
        throw new MembershipServiceError('request.canceled')
      }
      this.toast.somethingWentWrong()
      throw err
    }
    await store.dispatch('me/delSubscription', subscriptionId)
  }
}
