<template>
  <div class="chat-paperclip">
    <div class="top">
      <input
        class="input-lt-sm"
        type="text"
        placeholder="Find the perfect gif"
        ref="input"
        v-model="q"
      />
    </div>
    <div class="content scroll-y" ref="content" @scroll="handleScroll">
      <ChatGifWaterfall
        ref="gifWaterfall"
        :q="q"
        @chosen="chooseGif"
      ></ChatGifWaterfall>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import ChatGifWaterfall from '@/components/room/chat/ChatGifWaterfall'

export default {
  name: 'ChatPaperclip',
  components: {
    ChatGifWaterfall
  },
  props: {
    shown: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapState({
      isMobile: (state) => state.isMobile
    })
  },
  data () {
    return {
      q: ''
    }
  },
  watch: {
    shown (value) {
      if (value) {
        if (!this.isMobile) {
          this.$nextTick(() => {
            this.$refs.input.focus()
          })
        }
      }
    }
  },
  methods: {
    async handleScroll (e) {
      const { scrollHeight, scrollTop, clientHeight } = e.target
      if (Math.abs(scrollHeight - clientHeight - scrollTop) < 1) {
        await this.$refs.gifWaterfall.next()
      }
    },
    chooseGif (gif) {
      this.$emit('chosen', gif)
    }
  }
}
</script>

<style scoped lang="scss">
@import "@/styles/variables";
@import "@/styles/breakpoints";

.chat-paperclip {
  height: 450px;
  @include media-breakpoint-down(lg) {
    height: 300px;
  }
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: var(--room-aside-alt-bg);
}
.top {
  font-size: 14px;
  display: flex;
  align-items: center;
  padding: 12px 8px;
}
.content {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  gap: 8px;
  --scrollbar-color: var(--room-scroll-alt-color);
}
</style>
