<template>
  <MobileHeaderLayout :sidebar="true">
    <template v-slot:content>
      <div class="mobile-home">
        <VerificationAlert v-if="imGuest"></VerificationAlert>
        <MobileMyRooms v-if="rooms.length"></MobileMyRooms>
        <AddRoom></AddRoom>
      </div>
    </template>
  </MobileHeaderLayout>
</template>

<script>
import { mapState } from 'vuex'
import MobileHeaderLayout from '@/components/layout/MobileHeaderLayout'
import AddRoom from '@/views/home/AddRoom'
import MobileMyRooms from '@/views/home/MobileMyRooms'
import VerificationAlert from '@/components/VerificationAlert'

export default {
  name: 'MobileHome',
  components: {
    MobileHeaderLayout,
    MobileMyRooms,
    AddRoom,
    VerificationAlert
  },
  computed: {
    ...mapState('me', {
      role: (state) => state.role,
      rooms: (state) => state.rooms
    }),
    imGuest () {
      return this.role === 'GUEST'
    }
  }
}
</script>

<style scoped lang="scss">
@import "@/styles/variables";

.mobile-home {
  display: flex;
  flex-direction: column;
  padding: $mobile-content-padding;
  gap: 16px;
}
</style>
