<template>
  <div class="radio-button" :class="{ checked: checked }" @click="check"></div>
</template>

<script>
export default {
  name: 'RadioButton',
  props: {
    checked: {
      type: Boolean,
      default: true
    }
  },
  methods: {
    check () {
      this.$emit('checked')
    }
  }
}
</script>

<style scoped lang="scss">
@import "@/styles/variables";

.radio-button {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 2px solid var(--radio-button-border-color);
  background-color: var(--radio-button-bg);
  cursor: pointer;
  position: relative;
  &.checked {
    border: 2px solid var(--radio-button-checked-border-color);
    &:after {
      content: " ";
      position: absolute;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background-color: var(--radio-button-checked-bg);
    }
  }
  &:hover:not(.checked) {
    border: 2px solid var(--radio-button-hover-border-color);
  }
}
</style>
