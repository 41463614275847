function defaultState () {
  return {
    roomId: null,
    roomSid: null,
    ownerId: null,
    hostId: null,
    owner: null,
    host: null,
    name: null,
    memberCount: null,
    status: null,
    chat: [],
    members: [],
    membersMap: {},
    shown: false,
    mini: false,
    theater: false,
    fullscreen: false,
    lock: false,
    muted: false,
    volume: 1,
    browserWidth: 1280,
    browserHeight: 720,
    fullscreenChatShown: true,
    queuePos: null,
    membership: null,
    livePartyId: null
  }
}

export default {
  namespaced: true,
  state: defaultState(),
  getters: {},
  mutations: {
    update (state, data) {
      Object.assign(state, data)
    }
  },
  actions: {
    reload ({ commit }, data) {
      commit('update', Object.assign({}, defaultState(), data))
    },
    reset ({ commit }) {
      commit('update', defaultState())
    },
    unshiftChatItems ({ state }, { items }) {
      state.chat.unshift(...items)
    },
    addChatItem ({ state }, item) {
      state.chat.push(item)
    },
    addMember ({ state }, { memberCount, member }) {
      state.memberCount = memberCount
      const i = state.members.findIndex((m) => m.userId === member.userId)
      if (i > -1) {
        state.members.splice(i, 1)
        delete state.membersMap[member.userId]
      }
      state.members.push(member)
      state.membersMap[member.userId] = member
    },
    delMember ({ state }, { memberCount, member }) {
      state.memberCount = memberCount
      const i = state.members.findIndex((m) => m.userId === member.userId)
      if (i > -1) {
        state.members.splice(i, 1)
        delete state.membersMap[member.userId]
      }
    },
    updateMode ({ commit }, { mini, theater, fullscreen }) {
      commit(
        'update',
        Object.assign(
          {
            mini: false,
            theater: false,
            fullscreen: false
          },
          { mini, theater, fullscreen }
        )
      )
    }
  }
}
