<template>
  <div class="cancel-subscription-modal">
    <ModalHeader :modal="modal">
      <template v-slot:title>Cancel subscription</template>
    </ModalHeader>
    <form class="form">
      <div class="content line-height">
        Are you sure you want to cancel subscription?
      </div>
      <ModalFooter class="footer">
        <template v-slot:content>
          <ProcessButton
            class="btn-primary-sm btn-confirm"
            :processing="processing"
            @submit="cancelSubscription"
          >
            CONFIRM
          </ProcessButton>
        </template>
      </ModalFooter>
    </form>
  </div>
</template>

<script>
import ProcessButton from '@/components/ProcessButton'
import ModalHeader from '@/components/modals/ModalHeader'
import ModalFooter from '@/components/modals/ModalFooter'

export default {
  name: 'CancelSubscriptionModal',
  components: {
    ProcessButton,
    ModalHeader,
    ModalFooter
  },
  props: {
    modal: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      processing: false
    }
  },
  computed: {
    subscriptionId () {
      return this.modal.options.subscriptionId
    }
  },
  methods: {
    close () {
      this.modal.close()
    },
    async cancelSubscription () {
      console.log('cancel.subscription', this.subscriptionId)
      this.processing = true
      try {
        await this.$service.membership.cancelSubscription(this.subscriptionId)
      } finally {
        this.processing = false
      }
      this.close()
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/styles/variables";
@import "@/styles/breakpoints";

.cancel-subscription-modal {
  width: 100vw;
  display: flex;
  flex-direction: column;
  .footer {
    .btn-confirm {
      max-width: 110px;
    }
  }
  @include media-breakpoint-up(sm) {
    max-width: 460px;
  }
}

.form {
  display: flex;
  flex-direction: column;
  .content {
    padding: 0 24px;
    font-family: "Roboto Medium", sans-serif;
  }
}
</style>
