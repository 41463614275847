<template>
  <TransitionGroup id="toasts" class="toasts" name="toasts" tag="div">
    <div
      class="toast"
      :class="[`toast-${toast.data.level}`]"
      v-for="toast in toasts"
      :key="toast"
      v-toast-click="() => del(toast)"
      @mouseover="() => toast.stopTimer()"
      @mouseleave="() => toast.startTimer()"
    >
      <div class="toast-level"></div>
      <div class="toast-content">
        <slot :name="toast.data.type" :toast="toast"></slot>
      </div>
    </div>
  </TransitionGroup>
</template>

<script>
import { events } from '@/plugins/toast/events'
import { Toast } from '@/plugins/toast/toast'
import toastClick from '@/plugins/toast/toastClick'

export default {
  name: 'Toasts',
  data () {
    return {
      toasts: []
    }
  },
  directives: {
    toastClick
  },
  mounted () {
    events.on('add', this.add)
    events.on('del-all', this.delAll)
  },
  beforeUnmount () {
    events.off('add', this.add)
    events.off('del-all', this.delAll)
    for (const toast of this.toasts) {
      toast.stopTimer()
      toast.removeAllListeners()
    }
    this.toasts = []
  },
  methods: {
    add (data) {
      const toast = new Toast(data)
      toast.startTimer()
      toast.on('close', () => this.del(toast))
      this.toasts.push(toast)
    },
    del (toast) {
      toast.stopTimer()
      toast.removeAllListeners()
      const index = this.toasts.indexOf(toast)
      if (index > -1) {
        this.toasts.splice(index, 1)
      }
    },
    delAll () {
      for (const toast of this.toasts) {
        toast.removeAllListeners()
      }
      this.toasts = []
    }
  }
}
</script>

<style scoped lang="scss">
@import "@/styles/variables";

.toasts {
  width: 100%;
  max-width: $toast-max-width;
  position: fixed;
  z-index: 1;
  top: 0;
  right: 0;
  padding: 16px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  gap: 16px;
  pointer-events: none;
  user-select: none;
}
.toasts-enter-active,
.toasts-leave-active {
  transition: all 0.3s ease;
}
.toasts-enter-from,
.toasts-leave-to {
  visibility: visible;
  transform: translate3d(calc(100% + 1rem), 0, 0);
}
.toast {
  border-radius: 8px;
  pointer-events: auto;
  overflow: hidden;
  background-color: var(--toast-bg);
  color: var(--toast-color);
  display: flex;
  .toast-level {
    min-width: 6px;
    min-height: 100%;
  }
  &.toast-info {
    .toast-level {
      background-color: var(--toast-info-bg);
    }
  }
  &.toast-error {
    .toast-level {
      background-color: var(--toast-error-bg);
    }
  }
  &.toast-warning {
    .toast-level {
      background-color: var(--toast-warning-bg);
    }
  }
}
</style>
