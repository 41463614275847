<template>
  <div class="upload-picture-modal">
    <ModalHeader :modal="modal">
      <template v-slot:title>Upload picture</template>
    </ModalHeader>
    <form class="form">
      <div class="content">
        <template v-if="imageLoaded">
          <div class="cropper-wrap">
            <Cropper
              class="cropper"
              ref="cropper"
              :src="imageSrc"
              :default-size="defaultSize"
              :stencil-size="stencilSize"
              :stencil-props="{
                lines: {},
                handlers: {},
                movable: false,
                scalable: false,
              }"
              @change="onChange"
              :transitions="false"
              :debounce="false"
              image-restriction="stencil"
              :min-width="10"
              :min-height="10"
              :style="{ width: width + 'px', height: height + 'px' }"
            />
            <div class="slider">
              <Slider
                v-model:value="zoom"
                @update="onZoom"
                :rangeMin="0"
                :rangeMax="1"
                :rangeStep="0.001"
              ></Slider>
            </div>
          </div>
        </template>
        <template v-else>
          <div
            class="placeholder"
            @click="fileDialog"
            :style="{ width: width + 'px', height: height + 24 + 'px' }"
          >
            Click to load image from disc
          </div>
        </template>
        <input
          v-show="false"
          type="file"
          ref="file"
          @change="loadImage($event)"
          accept="image/*"
        />
      </div>
      <ModalFooter class="footer">
        <template v-slot:content>
          <ProcessButton
            class="btn-primary-sm btn-apply"
            :disabled="!imageLoaded"
            :processing="processing"
            @submit="uploadImage"
          >
            APPLY
          </ProcessButton>
        </template>
      </ModalFooter>
    </form>
  </div>
</template>

<script>
import ProcessButton from '@/components/ProcessButton'
import ModalHeader from '@/components/modals/ModalHeader'
import ModalFooter from '@/components/modals/ModalFooter'
import Slider from '@/components/Slider'
import { Cropper } from 'vue-advanced-cropper'
import 'vue-advanced-cropper/dist/style.css'

export default {
  name: 'UploadPictureModal',
  components: {
    ProcessButton,
    ModalHeader,
    ModalFooter,
    Slider,
    Cropper
  },
  props: {
    modal: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      processing: false,
      zoom: 0,
      image: null
    }
  },
  computed: {
    imageLoaded () {
      return Boolean(this.image)
    },
    imageSrc () {
      return URL.createObjectURL(this.image)
    },
    width () {
      return this.modal.options.width
    },
    height () {
      return this.modal.options.height
    }
  },
  mounted () {
    this.fileDialog()
  },
  beforeUnmount () {
    if (this.imageLoaded) {
      URL.revokeObjectURL(this.imageSrc)
    }
  },
  methods: {
    close () {
      this.modal.close()
    },
    fileDialog () {
      this.$refs.file.click()
    },
    defaultSize () {
      return {
        width: this.width,
        height: this.height
      }
    },
    stencilSize () {
      return {
        width: this.width,
        height: this.height
      }
    },
    async uploadImage () {
      const { coordinates } = this.$refs.cropper.getResult()
      this.processing = true
      try {
        await this.modal.options.upload({
          image: this.image,
          width: coordinates.width,
          height: coordinates.height,
          left: coordinates.left,
          top: coordinates.top
        })
      } finally {
        this.processing = false
      }
      this.close()
    },
    loadImage (event) {
      const { files } = event.target
      if (files && files[0]) {
        const reader = new FileReader()
        reader.onload = () => {
          this.image = files[0]
        }
        reader.readAsArrayBuffer(files[0])
      }
    },
    onChange () {
      const cropper = this.$refs.cropper
      if (cropper) {
        const { coordinates, imageSize } = cropper
        if (
          imageSize.width / imageSize.height >
          coordinates.width / coordinates.height
        ) {
          this.zoom =
            (cropper.imageSize.height - cropper.coordinates.height) /
            (cropper.imageSize.height - cropper.sizeRestrictions.minHeight)
        } else {
          this.zoom =
            (cropper.imageSize.width - cropper.coordinates.width) /
            (cropper.imageSize.width - cropper.sizeRestrictions.minWidth)
        }
      }
    },
    onZoom (value) {
      const cropper = this.$refs.cropper
      if (cropper) {
        if (cropper.imageSize.height < cropper.imageSize.width) {
          const minHeight = cropper.sizeRestrictions.minHeight
          const imageHeight = cropper.imageSize.height
          cropper.zoom(
            (imageHeight - this.zoom * (imageHeight - minHeight)) /
            (imageHeight - value * (imageHeight - minHeight))
          )
        } else {
          const minWidth = cropper.sizeRestrictions.minWidth
          const imageWidth = cropper.imageSize.width
          cropper.zoom(
            (imageWidth - this.zoom * (imageWidth - minWidth)) /
            (imageWidth - value * (imageWidth - minWidth))
          )
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/styles/variables";
@import "@/styles/breakpoints";

.upload-picture-modal {
  display: flex;
  flex-direction: column;
  .footer {
    .btn-apply {
      max-width: 110px;
    }
  }
  @include media-breakpoint-down(sm) {
    width: 100vw;
  }
}

.form {
  display: flex;
  flex-direction: column;
  .content {
    display: flex;
    justify-content: center;
    padding: 0 24px;
    .cropper-wrap {
      display: flex;
      flex-direction: column;
      gap: 16px;
      .cropper {
        cursor: move;
      }
      .slider {
        display: flex;
        align-items: center;
      }
    }
    .placeholder {
      background: black;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      color: var(--muted-color);
      &:hover {
        color: var(--primary-color);
      }
    }
  }
}
</style>
