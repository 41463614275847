import EventEmitter from 'events'
import store from '@/store'
import { formatTimeAgo } from '@/utils/date'

export class RoomCentrifugoService {
  constructor (app) {
    this.app = app
    this.sub = null
    this.subEvents = new EventEmitter()
  }

  get centrifugo () {
    return this.app.config.globalProperties.$service.centrifugo
  }

  init (roomId) {
    this.destroy()
    this.sub = this.centrifugo.newSubscription(`room:#${roomId}`)
    this.sub.on('subscribing', (ctx) => {
      console.log('subscribing', ctx)
    })
    this.sub.on('subscribed', async (ctx) => {
      console.log('subscribed', ctx)
    })
    this.sub.on('unsubscribed', (ctx) => {
      console.log('unsubscribed', ctx)
    })
    this.sub.on('error', (ctx) => {
      console.log('subscription.error', ctx)
    })
    this.sub.on('publication', async (ctx) => {
      await this._onPublication(ctx)
    })
    this.sub.subscribe()
  }

  destroy () {
    if (this.sub) {
      this.sub.unsubscribe()
      this.centrifugo.removeSubscription(this.sub)
      this.sub.removeAllListeners()
      this.subEvents.removeAllListeners()
      this.sub = null
    }
  }

  async _onPublication (ctx) {
    console.log('received.publication', ctx)
    if (ctx.data.stream === 'status') {
      const data = {
        status: ctx.data.status,
        livePartyId: ctx.data.live_party_id,
        queuePos: null
      }
      if (ctx.data.host) {
        data.host = {
          avatar: ctx.data.host.avatar,
          name: ctx.data.host.name,
          role: ctx.data.host.role,
          userId: ctx.data.host.user_id,
          membership: ctx.data.host.membership
        }
      }
      if (ctx.data.host_id) {
        data.hostId = ctx.data.host_id
      }
      store.commit('room/update', data)
      this.subEvents.emit(ctx.data.stream, data)
      return
    } else if (ctx.data.stream === 'queue.pos') {
      const data = {
        queuePos: {
          index: ctx.data.index,
          total: ctx.data.total
        }
      }
      store.commit('room/update', data)
      this.subEvents.emit(ctx.data.stream, data)
      return
    } else if (ctx.data.stream === 'joined') {
      const data = {
        memberCount: ctx.data.member_count,
        member: {
          avatar: ctx.data.member.avatar,
          name: ctx.data.member.name,
          role: ctx.data.member.role,
          userId: ctx.data.member.user_id,
          membership: ctx.data.member.membership
        }
      }
      store.dispatch('room/addMember', data)
      this.subEvents.emit(ctx.data.stream, data)
      return
    } else if (ctx.data.stream === 'left') {
      const data = {
        memberCount: ctx.data.member_count,
        member: {
          avatar: ctx.data.member.avatar,
          name: ctx.data.member.name,
          role: ctx.data.member.role,
          userId: ctx.data.member.user_id,
          membership: ctx.data.member.membership
        }
      }
      store.dispatch('room/delMember', data)
      this.subEvents.emit(ctx.data.stream, data)
      return
    } else if (ctx.data.stream === 'host') {
      const data = {
        hostId: ctx.data.host_id,
        host: null
      }
      if (ctx.data.host) {
        data.host = {
          avatar: ctx.data.host.avatar,
          name: ctx.data.host.name,
          role: ctx.data.host.role,
          userId: ctx.data.host.user_id,
          membership: ctx.data.host.membership
        }
      }
      store.commit('room/update', data)
      this.subEvents.emit(ctx.data.stream, data)
      return
    } else if (ctx.data.stream === 'chat.item') {
      const data = {
        id: ctx.data.item.id,
        roomId: ctx.data.item.room_id,
        userId: ctx.data.item.user_id,
        message: {
          text: ctx.data.item.message.text,
          attachments: ctx.data.item.message.attachments,
          name: ctx.data.item.message.name,
          avatar: ctx.data.item.message.avatar
        },
        createdAt: ctx.data.item.created_at,
        timeAgo: formatTimeAgo(ctx.data.item.created_at)
      }
      await store.dispatch('room/addChatItem', data)
      this.subEvents.emit(ctx.data.stream, data)
      return
    } else if (ctx.data.stream === 'chat.typing') {
      const data = {
        userId: ctx.data.user_id,
        name: ctx.data.name
      }
      this.subEvents.emit(ctx.data.stream, data)
      return
    } else if (ctx.data.stream === 'membership') {
      const data = {
        membership: ctx.data.membership
      }
      this.subEvents.emit(ctx.data.stream, data)
      return
    }
    console.info('unhandled.publication', ctx)
  }
}
