<template>
  <div class="verification-toast">
    <div class="message line-height">
      Some app features are restricted. Please setup login option to lift restrictions.
    </div>
    <div class="actions">
      <button class="btn-base btn-action btn-email" @click="setupEmail">
        <SvgEmail width="18"></SvgEmail>
      </button>
      <button class="btn-base btn-action btn-discord" @click="setupDiscord">
        <SvgDiscord width="18"></SvgDiscord>
      </button>
      <button class="btn-base btn-action btn-google" @click="setupGoogle">
        <SvgGoogle width="18"></SvgGoogle>
      </button>
    </div>
  </div>
</template>

<script>
import SvgEmail from '@/components/svg/SvgEmail'
import SvgGoogle from '@/components/svg/SvgGoogle'
import SvgDiscord from '@/components/svg/SvgDiscord'
import { discordAuthUrl, googleAuthUrl } from '@/settings'

export default {
  name: 'VerificationToast',
  components: {
    SvgEmail,
    SvgGoogle,
    SvgDiscord
  },
  props: {
    toast: {
      type: Object,
      required: true
    }
  },
  methods: {
    setTmp (provider) {
      this.$service.tmp.reset()
      this.$service.tmp.setOauth2LinkProvider(provider)
      this.$service.tmp.setOauth2Next(this.$route.fullPath)
    },
    setupEmail () {
      console.log('link.email')
      this.$modal.open({
        type: 'setup-email'
      })
    },
    setupDiscord () {
      console.log('link.discord')
      this.setTmp('discord')
      window.location.replace(discordAuthUrl)
    },
    setupGoogle () {
      console.log('link.google')
      this.setTmp('google')
      window.location.replace(googleAuthUrl)
    }
  }
}
</script>

<style scoped lang="scss">
.verification-toast {
  padding: 16px 24px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.actions {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
  .btn-action {
    color: white;
    width: 38px;
    height: 38px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    &.btn-email {
      background-color: var(--email-bg);
    }
    &.btn-discord {
      background-color: var(--discord-bg);
    }
    &.btn-google {
      background-color: var(--google-bg);
    }
  }
}
</style>
