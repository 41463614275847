<template>
  <div class="login-third-party">
    <button type="button" class="btn-base btn-third-party btn-google"
            @click="loginGoogle"
    >
      <SvgGoogle height="18"></SvgGoogle>
    </button>
    <button type="button" class="btn-base btn-third-party btn-discord"
            @click="loginDiscord"
    >
      <SvgDiscord height="22"></SvgDiscord>
    </button>
  </div>
</template>

<script>
import SvgGoogle from '@/components/svg/SvgGoogle'
import SvgDiscord from '@/components/svg/SvgDiscord'
import { discordAuthUrl, googleAuthUrl } from '@/settings'

export default {
  name: 'LoginThirdParty',
  components: {
    SvgGoogle,
    SvgDiscord
  },
  props: {
    nextBack: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    setOauth2Next () {
      this.$service.tmp.resetOauth2()
      if (this.nextBack) {
        this.$service.tmp.setOauth2Next(window.location.pathname)
      } else {
        const next = this.$route.query.next
        if (next) {
          this.$service.tmp.setOauth2Next(next)
        }
      }
    },
    loginGoogle () {
      this.setOauth2Next()
      window.location.replace(googleAuthUrl)
    },
    loginDiscord () {
      this.setOauth2Next()
      window.location.replace(discordAuthUrl)
    }
  }
}
</script>

<style scoped lang="scss">
.login-third-party{
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
  .btn-third-party {
    color: white;
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
  }
  .btn-discord {
    background-color: var(--discord-bg);
  }
  .btn-google {
    background-color: var(--google-bg);
  }
}
</style>
