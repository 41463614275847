<template>
  <div class="invite-friends-modal">
    <ModalHeader :modal="modal">
      <template v-slot:title>Invite friends</template>
    </ModalHeader>
    <form class="form">
      <div class="content">
        <div class="invite-link">{{ inviteLink }}</div>
      </div>
      <ModalFooter class="footer">
        <template v-slot:content>
          <button
            type="button"
            class="btn-primary-sm btn-copy-link"
            @click="copyLink"
          >
            COPY LINK
          </button>
        </template>
      </ModalFooter>
    </form>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import ModalHeader from '@/components/modals/ModalHeader'
import ModalFooter from '@/components/modals/ModalFooter'
import { appUrl } from '@/settings'

export default {
  name: 'InviteFriendsModal',
  components: {
    ModalHeader,
    ModalFooter
  },
  props: {
    modal: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      appUrl
    }
  },
  computed: {
    ...mapState('room', {
      roomSid: (state) => state.roomSid
    }),
    inviteLink () {
      return `${this.appUrl}/${this.roomSid}`
    }
  },
  methods: {
    close () {
      this.modal.close()
    },
    copyLink () {
      navigator.clipboard.writeText(this.inviteLink).then(
        function () {},
        function () {}
      )
      this.$toast.info({
        type: 'simple',
        context: {
          text: 'Invite link copied to clipboard'
        }
      })
      this.close()
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/styles/variables";
@import "@/styles/breakpoints";

.invite-friends-modal {
  width: 100vw;
  display: flex;
  flex-direction: column;
  .footer {
    .btn-copy-link {
      max-width: 110px;
    }
  }
  @include media-breakpoint-up(sm) {
    max-width: 500px;
  }
}

.form {
  display: flex;
  flex-direction: column;
  .content {
    padding: 0 24px;
    user-select: text;
    .invite-link {
      padding: 12px;
      font-family: "Roboto Mono Medium", sans-serif;
      border-bottom: 2px solid var(--primary-color);
      color: var(--bright-color);
    }
  }
}
</style>
