<template>
  <StickyHeaderLayout>
    <template v-slot:content>
      <div class="login-wrap" ref="content"
           :class="{ flexed: flexed }"
      >
        <div class="login" ref="login">
          <LoginForm></LoginForm>
        </div>
      </div>
    </template>
  </StickyHeaderLayout>
</template>

<script>
import StickyHeaderLayout from '@/components/layout/StickyHeaderLayout'
import LoginForm from '@/views/login/LoginForm'

export default {
  name: 'Login',
  components: {
    StickyHeaderLayout,
    LoginForm
  },
  data () {
    return {
      resizeObserver: null,
      flexed: true
    }
  },
  async mounted () {
    this.initResizeObserver()
  },
  beforeUnmount () {
    this.destroyResizeObserver()
  },
  methods: {
    initResizeObserver () {
      this.destroyResizeObserver()
      const resizeObserver = new ResizeObserver(() => {
        if (this.$refs.content) {
          const contentHeight = this.$refs.content.clientHeight
          const loginHeight = this.$refs.login.clientHeight
          this.flexed = loginHeight < contentHeight
        }
      })
      resizeObserver.observe(this.$refs.content)
    },
    destroyResizeObserver () {
      if (this.resizeObserver) {
        this.resizeObserver.disconnect()
        this.resizeObserver = null
      }
    }
  }
}
</script>

<style scoped lang="scss">
@import "@/styles/variables";

.login-wrap {
  width: 100%;
  height: 100%;
  &.flexed {
    display: flex;
    align-items: center;
  }
  .login {
    padding: $content-padding 0;
    width: 100%;
    max-width: 300px;
    margin: 0 auto;
  }
}
</style>
