<template>
  <RoomFooter>
    <div class="online-footer"
         :class="{ theater: theater }"
    >
      <FooterHost class="host" v-if="hostId && host"></FooterHost>
      <FooterReleaseControl class="release-control" v-if="imHost"></FooterReleaseControl>
      <FooterTakeControl class="take-control" v-if="(!imHost && imOwner) || (noHost && !imOwner)"></FooterTakeControl>
      <FooterLock class="lock" v-if="imHost"></FooterLock>
      <FooterVolume class="volume"></FooterVolume>
      <FooterTheater class="theater"></FooterTheater>
      <FooterFullscreen class="fullscreen"></FooterFullscreen>
    </div>
  </RoomFooter>
</template>

<script>
import { mapState } from 'vuex'
import RoomFooter from '@/components/room/footer/RoomFooter'
import FooterFullscreen from '@/components/room/online/footer/FooterFullscreen'
import FooterHost from '@/components/room/online/footer/FooterHost'
import FooterLock from '@/components/room/online/footer/FooterLock'
import FooterReleaseControl from '@/components/room/online/footer/FooterReleaseControl'
import FooterTakeControl from '@/components/room/online/footer/FooterTakeControl'
import FooterTheater from '@/components/room/online/footer/FooterTheater'
import FooterVolume from '@/components/room/online/footer/FooterVolume'

export default {
  name: 'OnlineFooter',
  components: {
    RoomFooter,
    FooterFullscreen,
    FooterHost,
    FooterLock,
    FooterReleaseControl,
    FooterTakeControl,
    FooterTheater,
    FooterVolume
  },
  computed: {
    ...mapState('me', {
      userId: (state) => state.userId
    }),
    ...mapState('room', {
      theater: (state) => state.theater,
      hostId: (state) => state.hostId,
      host: (state) => state.host,
      ownerId: (state) => state.ownerId
    }),
    noHost () {
      return !this.hostId
    },
    imHost () {
      return this.userId === this.hostId
    },
    imOwner () {
      return this.userId === this.ownerId
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/styles/variables";

.online-footer {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0 16px;
  background-color: var(--room-primary-bg);
  &.theater {
    opacity: 0;
    -webkit-transition: 0.25s;
    transition: opacity 0.25s;
    &:hover {
      opacity: 1;
    }
  }
  .host {
    margin-left: 16px;
    margin-right: 16px;
  }
  .release-control {
    margin-left: 16px;
  }
  .take-control {
    margin-left: 16px;
  }
  .lock {
    margin-left: 16px;
  }
  .volume {
    margin-left: auto;
  }
  .theater {
    margin-left: 16px;
  }
}
</style>
