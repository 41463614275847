<template>
  <div class="footer-fullscreen">
    <button
      class="btn btn-fullscreen"
      v-if="!fullscreen"
      @click.prevent="toggleFullscreen"
    >
      <SvgMaximize width="22"></SvgMaximize>
    </button>
    <button
      class="btn btn-fullscreen"
      v-else
      @click.prevent="toggleFullscreen"
    >
      <SvgMinimize width="22"></SvgMinimize>
    </button>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import SvgMaximize from '@/components/svg/SvgMaximize'
import SvgMinimize from '@/components/svg/SvgMinimize'

export default {
  name: 'FooterFullscreen',
  components: {
    SvgMaximize,
    SvgMinimize
  },
  computed: {
    ...mapState('room', {
      fullscreen: (state) => state.fullscreen
    })
  },
  methods: {
    toggleFullscreen () {
      this.$store.dispatch('room/updateMode', { fullscreen: !this.fullscreen })
    }
  }
}
</script>

<style scoped lang="scss">
@import "@/styles/variables";

.footer-fullscreen {
  display: flex;
  align-items: center;
  height: 100%;
  position: relative;
}
.btn-fullscreen {
  height: 100%;
  padding: 0 16px;
  color: var(--muted-color);
  &:hover:not([disabled]) {
    color: var(--primary-color);
  }
}
</style>
