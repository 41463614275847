<template>
  <div class="fullscreen-aside">
    <div class="top">
      <button
        class="btn-secondary-dk-icon-sm btn-top"
        :class="{ active: !lock }"
        v-if="imHost"
        @click.prevent="toggleLock"
      >
        <SvgLock v-if="lock" width="20"></SvgLock>
        <SvgUnlock v-else width="20"></SvgUnlock>
      </button>
      <button
        class="btn-secondary-dk-icon-sm btn-top"
        :class="{ active: fullscreenChatShown }"
        @click.prevent="toggleFullscreenChat"
      >
        <SvgMessage width="20"></SvgMessage>
      </button>
    </div>
    <div class="content">
      <template v-if="fullscreenChatShown">
        <TransitionGroup
          id="messages"
          class="messages"
          name="messages"
          tag="div"
        >
          <ChatMessage
            class="message"
            v-for="message in messages"
            :key="message"
            :data="message.data"
          ></ChatMessage>
        </TransitionGroup>
      </template>
    </div>
    <div class="bottom" v-show="fullscreenChatShown">
      <ChatInputBox
        ref="inputBox"
        @submit="publishChatItem"
        @typing="publishChatTyping"
        :forceSubmitAttachments="true"
      ></ChatInputBox>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import SvgMessage from '@/components/svg/SvgMessage'
import SvgLock from '@/components/svg/SvgLock'
import SvgUnlock from '@/components/svg/SvgUnlock'
import ChatInputBox from '@/components/room/chat/ChatInputBox'
import ChatMessage from '@/components/room/chat/ChatMessage'
import { Message } from '@/components/room/fullscreen/message'

export default {
  name: 'FullscreenAside',
  components: {
    SvgMessage,
    SvgLock,
    SvgUnlock,
    ChatInputBox,
    ChatMessage
  },
  computed: {
    ...mapState('me', {
      userId: (state) => state.userId
    }),
    ...mapState('room', {
      hostId: (state) => state.hostId,
      lock: (state) => state.lock,
      roomId: (state) => state.roomId,
      fullscreenChatShown: (state) => state.fullscreenChatShown
    }),
    imHost () {
      return this.userId === this.hostId
    }
  },
  data () {
    return {
      messages: []
    }
  },
  watch: {
    roomId () {
      this.destroySubEvents()
      this.initSubEvents()
    }
  },
  async beforeMount () {
    this.initSubEvents()
  },
  beforeUnmount () {
    this.destroySubEvents()
    for (const message of this.messages) {
      message.stopTimer()
      message.removeAllListeners()
    }
    this.messages = []
  },
  methods: {
    initSubEvents () {
      this.$service.roomCentrifugoService.subEvents.on('chat.item', this.onChatItem)
      this.$service.roomCentrifugoService.subEvents.on('chat.typing', this.onChatTyping)
    },
    destroySubEvents () {
      this.$service.roomCentrifugoService.subEvents.off('chat.item', this.onChatItem)
      this.$service.roomCentrifugoService.subEvents.off('chat.typing', this.onChatTyping)
    },
    delMessage (message) {
      message.stopTimer()
      message.removeAllListeners()
      const index = this.messages.indexOf(message)
      if (index > -1) {
        this.messages.splice(index, 1)
      }
    },
    onChatItem (data) {
      const message = new Message(data)
      message.startTimer()
      message.on('close', () => this.delMessage(message))
      this.messages.push(message)
      this.$refs.inputBox.delTyping({
        userId: data.userId
      })
    },
    onChatTyping (data) {
      this.$refs.inputBox.addTyping({
        userId: data.userId,
        name: data.name
      })
    },
    toggleFullscreenChat () {
      this.$store.commit('room/update', {
        fullscreenChatShown: !this.fullscreenChatShown
      })
    },
    toggleLock () {
      this.$store.commit('room/update', { lock: !this.lock })
    },
    publishChatItem (text, attachments) {
      this.$service.roomWs.publishChatItem(text, attachments)
    },
    publishChatTyping () {
      this.$service.roomWs.publishChatTyping()
    }
  }
}
</script>

<style scoped lang="scss">
@import "@/styles/variables";

.fullscreen-aside {
  width: $room-aside-width;
  height: 100%;
  pointer-events: none;
  display: flex;
  flex-direction: column;
}
.top {
  display: flex;
  gap: 8px;
  justify-content: flex-end;
  padding: 6px 12px;
  .btn-top {
    pointer-events: auto;
  }
}
.content {
  flex-grow: 1;
  padding: 8px 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 100%;
  max-height: 100%;
  overflow: hidden;
  .message {
    margin: 8px 0;
    background-color: #2a2f42;
    border-radius: 8px;
  }
}
.bottom {
  pointer-events: auto;
}
</style>
