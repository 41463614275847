<template>
  <div class="footer-host">
    <template v-if="imHost">
      <div class="avatar-wrap">
        <Avatar class="avatar-xsm" :avatar="avatar"></Avatar>
      </div>
      <div class="content">
        <div class="name">{{ name || 'Member' }}</div>
        <div class="stats">{{ memberCount }} watching</div>
      </div>
    </template>
    <template v-else>
      <div class="avatar-wrap">
        <Avatar class="avatar-xsm" :avatar="host.avatar"></Avatar>
      </div>
      <div class="content">
        <div class="name">{{ host.name || 'Member' }}</div>
        <div class="stats">{{ memberCount }} watching</div>
      </div>
    </template>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Avatar from '@/components/Avatar'

export default {
  name: 'FooterHost',
  components: {
    Avatar
  },
  computed: {
    ...mapState('me', {
      userId: (state) => state.userId,
      name: (state) => state.name,
      avatar: (state) => state.avatar
    }),
    ...mapState('room', {
      hostId: (state) => state.hostId,
      host: (state) => state.host,
      memberCount: (state) => state.memberCount,
      status: (state) => state.status
    }),
    imHost () {
      return this.userId === this.hostId
    }
  }
}
</script>

<style scoped lang="scss">
@import "@/styles/variables";

.footer-host {
  display: flex;
  align-items: center;
  height: 100%;
  position: relative;
  gap: 12px;
}
.avatar-wrap {
  border-radius: 8px;
  overflow: hidden;
}
.content {
  display: flex;
  flex-direction: column;
  gap: 4px;
  .name {
    font-size: 15px;
    font-family: "Roboto Medium", sans-serif;
  }
  .stats {
    color: var(--muted-color);
    font-size: 14px;
  }
}
</style>
