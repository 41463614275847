<template>
  <div class="sticky-header-layout">
    <StickyHeader :stickied="stickiedHeader">
      <div class="header-wrap">
        <div class="header">
          <StickyHeaderBrand></StickyHeaderBrand>
          <slot name="header"></slot>
        </div>
      </div>
    </StickyHeader>
    <div class="content-wrap scroll-y"
         @scroll="handleScroll"
    >
      <div class="content">
        <slot name="content"></slot>
      </div>
    </div>
  </div>
</template>

<script>
import StickyHeader from '@/components/sticky-header/StickyHeader'
import StickyHeaderBrand from '@/components/sticky-header/StickyHeaderBrand'

export default {
  name: 'StickyHeaderLayout',
  components: {
    StickyHeader,
    StickyHeaderBrand
  },
  data () {
    return {
      stickiedHeader: false
    }
  },
  methods: {
    handleScroll (event) {
      this.stickiedHeader = Boolean(event.target.scrollTop)
    }
  }
}
</script>

<style scoped lang="scss">
@import "@/styles/variables";

.sticky-header-layout {
  width: 100vw;
  height: 100svh;
  display: flex;
  flex-direction: column;
}
.header-wrap {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  padding: 0 calc(#{$content-padding} - #{$sticky-header-item-padding});
  .header {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
  }
}
.content-wrap {
  flex-grow: 1;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  padding: 0 $content-padding;
  .content {
    height: 100%;
    width: 100%;
    max-width: $sticky-header-layout-max-width;
  }
}
</style>
