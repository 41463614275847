<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 128">
    <path
      fill="currentColor"
      d="M115.1,35.8c-5.9,0.1-10.8,5.3-10.5,11.2c0.1,3.3,1.7,6.1,4.1,8c-12.4,16.6-25,17.9-39.6-9.6c5.2-2.6,8.1-9.2,4.3-15.8c-1.8-3.2-5.5-4.8-9.2-4.6c-0.1,0-0.1,0-0.2,0c0,0-0.1,0-0.2,0c-3.7-0.1-7.3,1.4-9.2,4.6c-3.8,6.6-0.9,13.2,4.3,15.8C44.3,72.9,31.7,71.6,19.3,55c2.4-1.9,4-4.8,4.1-8c0.2-5.9-4.6-11.1-10.5-11.2c-6.1-0.1-11,4.7-11,10.8c0,5.9,4.8,10.8,10.8,10.8c0.4,0,0.9,0,1.3-0.1l4.1,40.2c0.3,3.2,3,5.6,6.2,5.6H64h39.7c3.2,0,5.9-2.4,6.2-5.6l4.1-40.2c0.4,0.1,0.8,0.1,1.3,0.1c5.9,0,10.8-4.8,10.8-10.8C126.1,40.5,121.1,35.7,115.1,35.8z"
    />
  </svg>
</template>

<script>
export default {
  name: 'SvgCrown'
}
</script>

<style scoped>
svg {
  display: block;
}
</style>
