<template>
  <div class="mobile-offline-owner">
    <div class="content">
      <ProcessButton
        class="btn-primary-sm btn-start-party"
        :processing="processing"
        @submit="startParty"
      >
        START PARTY
      </ProcessButton>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import ProcessButton from '@/components/ProcessButton'

export default {
  name: 'MobileOfflineOwner',
  components: {
    ProcessButton
  },
  computed: {
    ...mapState('room', {
      roomId: (state) => state.roomId
    }),
    ...mapState('me', {
      role: (state) => state.role
    }),
    imGuest () {
      return this.role === 'GUEST'
    }
  },
  data () {
    return {
      processing: false
    }
  },
  methods: {
    async startParty () {
      if (this.imGuest) {
        this.$toast.warning({
          type: 'verification'
        })
        return
      }
      this.processing = true
      try {
        await this.$service.room.startParty(this.roomId, 'https://duckduckgo.com')
      } finally {
        this.processing = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/styles/variables";
@import "@/styles/breakpoints";

.mobile-offline-owner {
  aspect-ratio: 16 / 9;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: $mobile-content-padding;
}

.content {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  .btn-start-party {
    max-width: 130px;
  }
}
</style>
