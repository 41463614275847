<template>
  <div class="mobile-nav">
    <div class="nav">
      <div
        class="tab"
        :class="{ active: activeTab === 'chat' }"
        @click="selectTab('chat')"
      >
        <SvgMessage width="20"></SvgMessage>
      </div>
      <div
        class="tab tab-members"
        :class="{ active: activeTab === 'members' }"
        @click="selectTab('members')"
      >
        <SvgUsers width="20"></SvgUsers>
        <div class="badge">{{ memberCount }}</div>
      </div>
      <div v-if="showControl"
        class="tab tab-control"
        :class="{ active: activeTab === 'control' }"
        @click="selectTab('control')"
      >
        <SvgControl width="24"></SvgControl>
      </div>
      <MobileNavMorePopup class="more">
        <button class="btn btn-action">
          <SvgMoreV width="20"></SvgMoreV>
        </button>
      </MobileNavMorePopup>
    </div>
    <div class="nav-content">
      <Chat ref="chat" v-show="activeTab === 'chat'"></Chat>
      <Members v-show="activeTab === 'members'"></Members>
      <MobileControl v-show="activeTab === 'control'"></MobileControl>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import SvgMessage from '@/components/svg/SvgMessage'
import SvgUsers from '@/components/svg/SvgUsers'
import SvgMoreV from '@/components/svg/SvgMoreV'
import Chat from '@/components/room/chat/Chat'
import Members from '@/components/room/members/Members'
import MobileNavMorePopup from '@/components/room/mobile-nav/MobileNavMorePopup'
import MobileControl from '@/components/room/mobile-nav/MobileControl'
import SvgControl from '@/components/svg/SvgControl'

export default {
  name: 'MobileNav',
  components: {
    Members,
    Chat,
    SvgMessage,
    SvgUsers,
    SvgMoreV,
    MobileNavMorePopup,
    SvgControl,
    MobileControl
  },
  data () {
    return {
      activeTab: 'chat'
    }
  },
  computed: {
    ...mapState('me', {
      userId: (state) => state.userId
    }),
    ...mapState('room', {
      hostId: (state) => state.hostId,
      status: (state) => state.status,
      memberCount: (state) => state.memberCount
    }),
    imHost () {
      return this.userId === this.hostId
    },
    showControl () {
      return this.imHost && this.status === 'ONLINE'
    }
  },
  methods: {
    selectTab (name) {
      this.activeTab = name
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/styles/variables";
@import "@/styles/breakpoints";

.mobile-nav {
  width: 100%;
  height: 100%;
  background: var(--room-aside-bg);
  display: flex;
  flex-direction: column;
}
.nav {
  height: $mobile-nav-header-height;
  background: var(--room-aside-header-bg);
  display: flex;
  position: relative;
  .tab {
    height: $mobile-nav-header-height;
    padding: 0 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    cursor: pointer;
    position: relative;
    &.active {
      color: var(--room-aside-active-tab-color);
      &:before {
        position: absolute;
        content: "";
        height: 4px;
        width: 100%;
        left: 0;
        bottom: 0;
        background-color: var(--room-aside-active-tab-bg);
      }
    }
    .badge {
      color: var(--bright-color);
      background: var(--bright-bg);
      padding: 4px 8px;
      border-radius: 8px;
      font-size: 13px;
      font-family: "Roboto Mono Medium", sans-serif;
    }
  }
  .tab-members {
    padding: 0;
    min-width: 96px;
  }
  .tab-control {
    color: var(--mobile-room-nav-control-tab-color);
  }
  .more {
    margin-left: auto;
  }
  .btn-action {
    padding: 0 16px;
    height: $mobile-nav-header-height;
    &:active {
      color: var(--bright-color);
      background: var(--bright-bg);
    }
  }
}
.nav-content {
  flex-grow: 1;
  width: 100%;
  height: 100%;
}
</style>
