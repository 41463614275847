<template>
  <div class="overlay" v-if="modals.length">
    <div
      class="modal"
      v-for="(modal, index) in modals"
      :key="modal"
      v-show="index === lastModalIndex"
    >
      <slot :name="modal.options.type" :modal="modal"></slot>
    </div>
  </div>
</template>

<script>
import { events } from '@/plugins/modal/events'
import { Modal } from '@/plugins/modal/modal'

export default {
  name: 'Modals',
  data () {
    return {
      modals: []
    }
  },
  computed: {
    lastModalIndex () {
      return this.modals.length - 1
    }
  },
  mounted () {
    events.on('add', this.add)
    events.on('del-all', this.delAll)
  },
  beforeUnmount () {
    events.off('add', this.add)
    events.off('del-all', this.delAll)
    this.delAll()
  },
  methods: {
    add (data) {
      const modal = new Modal(data)
      modal.on('close', () => this.del(modal))
      this.modals.push(modal)
    },
    del (modal) {
      const index = this.modals.indexOf(modal)
      if (index > -1) {
        modal.removeAllListeners()
        this.modals.splice(index, 1)
      }
    },
    delAll () {
      for (const modal of this.modals) {
        modal.removeAllListeners()
      }
      this.modals = []
    }
  }
}
</script>

<style scoped lang="scss">
@import "@/styles/variables";
@import "@/styles/breakpoints";

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100vw;
  height: 100svh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--modal-snapshot-bg);
  .modal {
    background: var(--modal-bg);
    box-shadow: 0 0 0 1px var(--modal-shadow-color);
    border-radius: 12px;
    overflow: hidden;
  }
  @include media-breakpoint-down(sm) {
    background-color: var(--primary-bg);
    .modal {
      display: flex;
      align-items: center;
      justify-content: center;
      box-shadow: unset;
    }
  }
}

</style>
