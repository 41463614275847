<template>
  <div class="chat-emoji-picker">
    <div class="top">
      <input
        class="input-lt-sm"
        type="text"
        :placeholder="
          placeholder && placeholder.length
            ? placeholder
            : 'Find the perfect emoji'
        "
        ref="input"
        v-model="q"
      />
    </div>
    <div class="content scroll-y" ref="content" @scroll="handleScroll">
      <template v-if="q.length">
        <div class="section">
          <div class="emojis-grid">
            <div
              class="emoji"
              v-for="(emoji, index) in findEmojisByName(q)"
              :key="index"
              @click="chooseEmoji(emoji)"
              @mouseover="onEmojiMouseover(emoji)"
            >
              <img :src="getEmojiImage(emoji)" :alt="`:${emoji}:`" />
            </div>
          </div>
        </div>
      </template>
      <template v-else>
        <div
          class="section"
          v-for="(category, index) in categories"
          :key="index"
          :id="`section-${index}`"
          :index="index"
        >
          <div class="category">{{ category }}</div>
          <div class="emojis-grid">
            <div
              class="emoji"
              v-for="(emoji, index) in findEmojisByCategory(category)"
              :key="index"
              @click="chooseEmoji(emoji)"
              @mouseover="onEmojiMouseover(emoji)"
            >
              <img :src="getEmojiImage(emoji)" :alt="`:${emoji}:`" />
            </div>
          </div>
        </div>
      </template>
    </div>
    <div class="footer">
      <template v-if="q.length">
        <div class="placeholder">{{ placeholder }}</div>
      </template>
      <template v-else>
        <div class="categories">
          <button
            class="btn category-btn"
            v-for="(category, index) in categories"
            :key="index"
            :class="{ active: activeCategory === category }"
            :disabled="activeCategory === category"
            @click="scrollToSection(index)"
          >
            <template v-if="category === 'Smileys & Emotion'">
              <SvgSmileB width="15"></SvgSmileB>
            </template>
            <template v-else-if="category === 'Symbols'">
              <SvgGenderMaleB width="15"></SvgGenderMaleB>
            </template>
            <template v-else-if="category === 'Activities'">
              <SvgDribbleB width="15"></SvgDribbleB>
            </template>
            <template v-else-if="category === 'Flags'">
              <SvgFlagB width="15"></SvgFlagB>
            </template>
            <template v-else-if="category === 'Travel & Places'">
              <SvgTruckB width="15"></SvgTruckB>
            </template>
            <template v-else-if="category === 'Food & Drink'">
              <SvgCupStrawB width="15"></SvgCupStrawB>
            </template>
            <template v-else-if="category === 'Animals & Nature'">
              <SvgTreeB width="15"></SvgTreeB>
            </template>
            <template v-else-if="category === 'People & Body'">
              <SvgPeopleB width="15"></SvgPeopleB>
            </template>
            <template v-else-if="category === 'Objects'">
              <SvgLampB width="15"></SvgLampB>
            </template>
          </button>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import SvgSmileB from '@/components/svg/SvgSmileB'
import SvgGenderMaleB from '@/components/svg/SvgGenderMaleB'
import SvgDribbleB from '@/components/svg/SvgDribbleB'
import SvgFlagB from '@/components/svg/SvgFlagB'
import SvgTreeB from '@/components/svg/SvgTreeB'
import SvgCupStrawB from '@/components/svg/SvgCupStrawB'
import SvgPeopleB from '@/components/svg/SvgPeopleB'
import SvgLampB from '@/components/svg/SvgLampB'
import SvgTruckB from '@/components/svg/SvgTruckB'
import {
  findEmojisByCategory,
  findEmojisByName,
  getEmojiImage
} from '@/utils/emoji'

export default {
  name: 'ChatEmojiPicker',
  components: {
    SvgSmileB,
    SvgGenderMaleB,
    SvgDribbleB,
    SvgFlagB,
    SvgTreeB,
    SvgCupStrawB,
    SvgPeopleB,
    SvgLampB,
    SvgTruckB
  },
  props: {
    shown: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapState({
      isMobile: (state) => state.isMobile
    })
  },
  watch: {
    shown (value) {
      this.placeholder = null
      if (value) {
        if (!this.isMobile) {
          this.$nextTick(() => {
            this.$refs.input.focus()
          })
        }
      }
    },
    q (value) {
      if (!value.length) {
        this.scrollToTop()
        this.activeCategory = 'Smileys & Emotion'
      }
    }
  },
  data () {
    return {
      q: '',
      placeholder: null,
      activeCategory: 'Smileys & Emotion',
      categories: [
        'Smileys & Emotion',
        'Symbols',
        'Activities',
        'Flags',
        'Travel & Places',
        'Food & Drink',
        'Animals & Nature',
        'People & Body',
        'Objects'
      ]
    }
  },
  methods: {
    chooseEmoji (emoji) {
      this.$emit('chosen', emoji)
    },
    handleScroll (e) {
      const nodes = this.getCurrentViewDom(e.target)
      if (nodes.length) {
        this.activeCategory = this.categories[nodes[0].getAttribute('index')]
      }
    },
    scrollToSection (index) {
      this.$refs.content.querySelector(`#section-${index}`).scrollIntoView()
    },
    scrollToTop () {
      this.$refs.content.scrollTo(0, 0)
    },
    getCurrentViewDom (el) {
      const children = []
      const rootRect = el.getBoundingClientRect()
      const rootHeight = rootRect.height * 0.5
      const rootTop = rootRect.top
      const rootOffset = rootTop + rootHeight
      for (const node of el.children) {
        const rect = node.getBoundingClientRect()
        if (rect.top + rect.height < rootOffset) {
          continue
        }
        if (rect.top <= rootOffset) {
          children.push(node)
        }
      }
      return children
    },
    onEmojiMouseover (emoji) {
      this.placeholder = `:${emoji}:`
    },
    findEmojisByCategory,
    findEmojisByName,
    getEmojiImage
  }
}
</script>

<style scoped lang="scss">
@import "@/styles/variables";
@import "@/styles/breakpoints";

.chat-emoji-picker {
  height: 450px;
  @include media-breakpoint-down(lg) {
    height: 300px;
  }
  width: 100%;
  background-color: var(--room-aside-alt-bg);
  display: flex;
  flex-direction: column;
}
.top {
  font-size: 14px;
  display: flex;
  align-items: center;
  padding: 12px 8px;
}
.content {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  gap: 8px;
  --scrollbar-color: var(--room-scroll-alt-color);
  .section {
    display: flex;
    flex-direction: column;
    gap: 8px;
    .category {
      color: var(--muted-color);
      font-size: 14px;
      padding: 0 8px;
    }
    .emojis-grid {
      display: grid;
      grid-template-columns: repeat(10, 1fr);
      @include media-breakpoint-down(lg) {
        grid-template-columns: repeat(8, 1fr);
      }
      cursor: pointer;
      user-select: none;
      .emoji {
        padding: 4px;
        display: flex;
        justify-content: center;
        align-items: center;
        &:hover {
          background-color: var(--room-aside-alt-lt-bg);
        }
      }
    }
  }
}
.footer {
  background-color: var(--room-aside-alt-lt-bg);
  .categories {
    height: 40px;
    display: grid;
    grid-template-columns: repeat(9, 1fr);
    .category-btn {
      color: var(--bright-color);
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      &.active {
        background-color: var(--bright-bg);
      }
    }
  }
  .placeholder {
    height: 40px;
    display: flex;
    align-items: center;
    padding: 0 8px;
    color: var(--muted-color);
    font-size: 15px;
  }
}
</style>
