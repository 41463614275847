import Axios from 'axios'
import store from '@/store'
import router from '@/router'
import { isEmail } from '@/utils/common'

class IamApi {
  constructor (api) {
    this.api = api
  }

  async refresh () {
    const response = await this.api.post(
      '/auth/refresh',
      {},
      {
        useRefreshToken: true
      }
    )
    return {
      accessToken: response.data.access_token,
      refreshToken: response.data.refresh_token
    }
  }

  async sendEmailOtp (email) {
    const response = await this.api.post('/otp/email', { email })
    return {
      otpId: response.data.id
    }
  }

  async loginEmail (otpId, otpCode) {
    const response = await this.api.post('/auth/email', {
      otp_id: otpId,
      otp_code: otpCode
    })
    return {
      accessToken: response.data.access_token,
      refreshToken: response.data.refresh_token
    }
  }

  async linkEmail (otpId, otpCode) {
    const response = await this.api.post(
      '/auth/email',
      {
        otp_id: otpId,
        otp_code: otpCode
      },
      { useAccessToken: true }
    )
    return {
      accessToken: response.data.access_token,
      refreshToken: response.data.refresh_token
    }
  }

  async loginDiscord (oauth2Code) {
    const response = await this.api.post('/auth/discord', {
      oauth2_code: oauth2Code
    })
    return {
      accessToken: response.data.access_token,
      refreshToken: response.data.refresh_token
    }
  }

  async loginGoogle (oauth2Code) {
    const response = await this.api.post('/auth/google', {
      oauth2_code: oauth2Code
    })
    return {
      accessToken: response.data.access_token,
      refreshToken: response.data.refresh_token
    }
  }

  async linkDiscord (oauth2Code) {
    const response = await this.api.post(
      '/auth/discord',
      {
        oauth2_code: oauth2Code
      },
      { useAccessToken: true }
    )
    return {
      accessToken: response.data.access_token,
      refreshToken: response.data.refresh_token
    }
  }

  async linkGoogle (oauth2Code) {
    const response = await this.api.post(
      '/auth/google',
      {
        oauth2_code: oauth2Code
      },
      { useAccessToken: true }
    )
    return {
      accessToken: response.data.access_token,
      refreshToken: response.data.refresh_token
    }
  }

  async loginGuest (name) {
    const response = await this.api.post('/auth/guest', {
      name
    })
    return {
      accessToken: response.data.access_token,
      refreshToken: response.data.refresh_token
    }
  }

  async uploadAvatar ({ image, width, height, left, top }) {
    const form = new FormData()
    form.append('image', image)
    form.append('width', Math.round(width))
    form.append('height', Math.round(height))
    form.append('left', Math.round(left))
    form.append('top', Math.round(top))
    return await this.api.post('/avatar', form, { useAccessToken: true })
  }

  async changeName (name) {
    return await this.api.post(
      '/name',
      {
        name
      },
      { useAccessToken: true }
    )
  }

  async deleteAccount () {
    return await this.api.post('/delete', {}, { useAccessToken: true })
  }
}

export class IamServiceError extends Error {
  constructor (code) {
    super(code)
    this.code = code
  }
}

export class IamService {
  constructor (app, api) {
    this.app = app
    this.api = new IamApi(api)
    this.refreshPromise = null
    this.logoutPromise = null
  }

  get tmp () {
    return this.app.config.globalProperties.$service.tmp
  }

  get jwt () {
    return this.app.config.globalProperties.$service.jwt
  }

  get centrifugo () {
    return this.app.config.globalProperties.$service.centrifugo
  }

  get modal () {
    return this.app.config.globalProperties.$modal
  }

  get toast () {
    return this.app.config.globalProperties.$toast
  }

  get room () {
    return this.app.config.globalProperties.$room
  }

  get me () {
    return this.app.config.globalProperties.$service.me
  }

  refresh () {
    if (this.refreshPromise) {
      return this.refreshPromise
    }
    this.refreshPromise = new Promise((resolve, reject) => {
      this.api.refresh().then(
        ({ accessToken, refreshToken }) => {
          this.jwt.set({ accessToken, refreshToken })
          resolve()
          this.refreshPromise = null
        },
        (err) => {
          reject(err)
          this.refreshPromise = null
        }
      )
    })
    return this.refreshPromise
  }

  async _logout (nextPath) {
    this.room.close()
    this.toast.closeAll()
    this.modal.closeAll()
    this.centrifugo.disconnect()
    await Promise.all([
      store.dispatch('reset'),
      store.dispatch('me/reset'),
      store.dispatch('room/reset')
    ])
    this.jwt.reset()
    this.tmp.reset()
    if (nextPath) {
      await router.push({ name: 'login', query: { next: nextPath } })
    } else {
      await router.push({ name: 'login' })
    }
  }

  logout (nextPath) {
    if (this.logoutPromise) {
      return this.logoutPromise
    }
    this.logoutPromise = new Promise((resolve, reject) => {
      this._logout(nextPath).then(
        () => {
          resolve()
          this.logoutPromise = null
        },
        (err) => {
          reject(err)
          this.logoutPromise = null
        }
      )
    })
    return this.logoutPromise
  }

  showInvalidEmailToast () {
    this.toast.warning({
      type: 'simple',
      context: {
        text: "You've entered an invalid Email"
      }
    })
  }

  async sendEmailOtp (email) {
    if (!isEmail(email)) {
      this.showInvalidEmailToast()
      throw new IamServiceError('invalid.email')
    }
    let response
    try {
      response = await this.api.sendEmailOtp(email)
    } catch (err) {
      if (Axios.isCancel(err)) {
        throw new IamServiceError('request.canceled')
      } else if (Axios.isAxiosError(err)) {
        if (err.response && err.response.status === 422) {
          this.showInvalidEmailToast()
          throw new IamServiceError('invalid.email')
        }
      }
      this.toast.somethingWentWrong()
      throw err
    }
    return response
  }

  async loginEmail (otpId, otpCode) {
    let response
    try {
      response = await this.api.loginEmail(otpId, otpCode)
    } catch (err) {
      if (Axios.isCancel(err)) {
        throw new IamServiceError('request.canceled')
      } else if (Axios.isAxiosError(err)) {
        if (err.response && err.response.status === 400) {
          if (err.response.data.error.code === 'otp.code.error') {
            this.toast.warning({
              type: 'simple',
              context: {
                text: "You've entered an invalid Code."
              }
            })
            throw new IamServiceError('otp.code.error')
          }
        }
      }
      this.toast.somethingWentWrong()
      throw err
    }
    const { accessToken, refreshToken } = response
    this.jwt.set({ accessToken, refreshToken })
  }

  async linkEmail (otpId, otpCode) {
    let response
    try {
      response = await this.api.linkEmail(otpId, otpCode)
    } catch (err) {
      if (Axios.isCancel(err)) {
        throw new IamServiceError('request.canceled')
      } else if (Axios.isAxiosError(err)) {
        if (err.response && err.response.status === 400) {
          if (err.response.data.error.code === 'otp.code.error') {
            this.toast.warning({
              type: 'simple',
              context: {
                text: "You've entered an invalid Code."
              }
            })
            throw new IamServiceError('otp.code.error')
          } else if (err.response.data.error.code === 'link.error') {
            this.showLinkErrorToast('email')
            throw new IamServiceError('link.error')
          }
        }
      }
      this.toast.somethingWentWrong()
      throw err
    }
    const { accessToken, refreshToken } = response
    this.jwt.set({ accessToken, refreshToken })

    this.showLinkDoneToast('email')
  }

  async loginDiscord (oauth2Code) {
    let response
    try {
      response = await this.api.loginDiscord(oauth2Code)
    } catch (err) {
      if (Axios.isCancel(err)) {
        throw new IamServiceError('request.canceled')
      } else if (Axios.isAxiosError(err)) {
        if (err.response && err.response.status === 400) {
          if (err.response.data.error.code === 'oauth2.code.error') {
            this.toast.somethingWentWrong()
            throw new IamServiceError('oauth2.code.error')
          }
        }
      }
      this.toast.somethingWentWrong()
      throw err
    }
    const { accessToken, refreshToken } = response
    this.jwt.set({ accessToken, refreshToken })
  }

  async loginGoogle (oauth2Code) {
    let response
    try {
      response = await this.api.loginGoogle(oauth2Code)
    } catch (err) {
      if (Axios.isCancel(err)) {
        throw new IamServiceError('request.canceled')
      } else if (Axios.isAxiosError(err)) {
        if (err.response && err.response.status === 400) {
          if (err.response.data.error.code === 'oauth2.code.error') {
            this.toast.somethingWentWrong()
            throw new IamServiceError('oauth2.code.error')
          }
        }
      }
      this.toast.somethingWentWrong()
      throw err
    }
    const { accessToken, refreshToken } = response
    this.jwt.set({ accessToken, refreshToken })
  }

  showLinkErrorToast (provider) {
    if (provider === 'google') {
      this.toast.warning({
        type: 'simple',
        context: {
          text: 'Unable to add Google-ID. Account already exists.'
        }
      })
    } else if (provider === 'discord') {
      this.toast.warning({
        type: 'simple',
        context: {
          text: 'Unable to add Discord-ID. Account already exists.'
        }
      })
    } else if (provider === 'email') {
      this.toast.warning({
        type: 'simple',
        context: {
          text: 'Unable to add Email. Account already exists.'
        }
      })
    }
  }

  showLinkDoneToast (provider) {
    if (provider === 'google') {
      this.toast.info({
        type: 'simple',
        context: {
          text: 'Google-ID has been added to your login options.'
        }
      })
    } else if (provider === 'discord') {
      this.toast.info({
        type: 'simple',
        context: {
          text: 'Discord-ID has been added to your login options.'
        }
      })
    } else if (provider === 'email') {
      this.toast.info({
        type: 'simple',
        context: {
          text: 'Email has been added to your login options.'
        }
      })
    }
  }

  async linkDiscord (oauth2Code) {
    let response
    try {
      response = await this.api.linkDiscord(oauth2Code)
    } catch (err) {
      if (Axios.isCancel(err)) {
        throw new IamServiceError('request.canceled')
      } else if (Axios.isAxiosError(err)) {
        if (err.response && err.response.status === 400) {
          if (err.response.data.error.code === 'oauth2.code.error') {
            this.toast.somethingWentWrong()
            throw new IamServiceError('oauth2.code.error')
          } else if (err.response.data.error.code === 'link.error') {
            this.showLinkErrorToast('discord')
            throw new IamServiceError('link.error')
          }
        }
      }
      this.toast.somethingWentWrong()
      throw err
    }
    const { accessToken, refreshToken } = response
    this.jwt.set({ accessToken, refreshToken })
    this.showLinkDoneToast('discord')
  }

  async linkGoogle (oauth2Code) {
    let response
    try {
      response = await this.api.linkGoogle(oauth2Code)
    } catch (err) {
      if (Axios.isCancel(err)) {
        throw new IamServiceError('request.canceled')
      } else if (Axios.isAxiosError(err)) {
        if (err.response && err.response.status === 400) {
          if (err.response.data.error.code === 'oauth2.code.error') {
            this.toast.somethingWentWrong()
            throw new IamServiceError('oauth2.code.error')
          } else if (err.response.data.error.code === 'link.error') {
            this.showLinkErrorToast('google')
            throw new IamServiceError('link.error')
          }
        }
      }
      this.toast.somethingWentWrong()
      throw err
    }
    const { accessToken, refreshToken } = response
    this.jwt.set({ accessToken, refreshToken })
    this.showLinkDoneToast('google')
  }

  async loginGuest (name) {
    let response
    try {
      response = await this.api.loginGuest(name)
    } catch (err) {
      if (Axios.isCancel(err)) {
        throw new IamServiceError('request.canceled')
      } else if (Axios.isAxiosError(err)) {
        if (err.response && err.response.status === 422) {
          this.toast.warning({
            type: 'simple',
            context: {
              text: "You've entered an invalid Name"
            }
          })
          throw new IamServiceError('invalid.name')
        }
      }
      this.toast.somethingWentWrong()
      throw err
    }
    const { accessToken, refreshToken } = response
    this.jwt.set({ accessToken, refreshToken })
  }

  async uploadAvatar ({ image, width, height, left, top }) {
    try {
      await this.api.uploadAvatar({ image, width, height, left, top })
    } catch (err) {
      if (Axios.isCancel(err)) {
        throw new IamServiceError('request.canceled')
      }
      this.toast.somethingWentWrong()
      throw err
    }
  }

  async changeName (name) {
    try {
      await this.api.changeName(name)
    } catch (err) {
      if (Axios.isCancel(err)) {
        throw new IamServiceError('request.canceled')
      } else if (Axios.isAxiosError(err)) {
        if (err.response && err.response.status === 422) {
          this.toast.warning({
            type: 'simple',
            context: {
              text: 'You\'ve entered an invalid Name'
            }
          })
          throw new IamServiceError('invalid.name')
        }
      }
      this.toast.somethingWentWrong()
      throw err
    }
    store.commit('me/update', { name })
  }

  async deleteAccount () {
    try {
      await this.api.deleteAccount()
    } catch (err) {
      if (Axios.isCancel(err)) {
        throw new IamServiceError('request.canceled')
      }
      this.toast.somethingWentWrong()
      throw err
    }
    await this.logout()
  }
}
