<template>
  <div class="room-aside" ref="aside">
    <div class="aside-nav">
      <div
        class="tab"
        :class="{ active: activeTab === 'chat' }"
        @click="selectTab('chat')"
      >
        <SvgMessage width="20"></SvgMessage>
      </div>
      <div
        class="tab"
        :class="{ active: activeTab === 'members' }"
        @click="selectTab('members')"
      >
        <SvgUsers width="20"></SvgUsers>
        <div class="badge">{{ memberCount }}</div>
      </div>
      <div class="invite">
        <button class="btn-base btn-invite" @click="inviteFriends">
          <SvgUserPlus width="20"></SvgUserPlus>
        </button>
      </div>
      <div class="more" v-click-outside="closeMore">
        <button class="btn-base btn-more" @click="toggleMore">
          <SvgMoreV width="20"></SvgMoreV>
        </button>
        <div class="aside-more-view" v-if="showMore">
          <AsideMore @close="closeMore"></AsideMore>
        </div>
      </div>
    </div>
    <div class="aside-content">
      <Chat ref="chat" v-show="activeTab === 'chat'"></Chat>
      <Members v-show="activeTab === 'members'"></Members>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import SvgMessage from '@/components/svg/SvgMessage'
import SvgUserPlus from '@/components/svg/SvgUserPlus'
import SvgUsers from '@/components/svg/SvgUsers'
import SvgMoreV from '@/components/svg/SvgMoreV'
import Chat from '@/components/room/chat/Chat'
import Members from '@/components/room/members/Members'
import AsideMore from '@/components/room/aside/AsideMore'

export default {
  name: 'RoomAside',
  components: {
    SvgMessage,
    SvgUserPlus,
    SvgUsers,
    SvgMoreV,
    Chat,
    Members,
    AsideMore
  },
  data () {
    return {
      activeTab: 'chat',
      showMore: false,
      resizeObserver: null
    }
  },
  computed: {
    ...mapState('room', {
      memberCount: (state) => state.memberCount
    })
  },
  watch: {
    activeTab (value) {
      if (value === 'chat') {
        this.$nextTick(() => {
          this.$refs.chat.scrollToBottom()
        })
      }
    }
  },
  mounted () {
    this.initResizeObserver()
  },
  beforeUnmount () {
    this.destroyResizeObserver()
  },
  methods: {
    initResizeObserver () {
      this.destroyResizeObserver()
      const resizeObserver = new ResizeObserver(() => {
        if (this.$refs.chat) {
          this.$refs.chat.onResize()
        }
      })
      resizeObserver.observe(this.$refs.aside)
    },
    destroyResizeObserver () {
      if (this.resizeObserver) {
        this.resizeObserver.disconnect()
        this.resizeObserver = null
      }
    },
    selectTab (name) {
      this.activeTab = name
    },
    toggleMore () {
      this.showMore = !this.showMore
    },
    closeMore () {
      this.showMore = false
    },
    inviteFriends () {
      this.$modal.open({
        type: 'invite-friends'
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/styles/variables";

.room-aside {
  width: 100%;
  height: 100%;
  background: var(--room-aside-bg);
  display: flex;
  flex-direction: column;
}
.aside-nav {
  height: $room-aside-header-height;
  background: var(--room-aside-header-bg);
  display: flex;
  position: relative;
  .tab {
    position: relative;
    height: $room-aside-header-height;
    min-width: 98px;
    display: flex;
    gap: 8px;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    &.active {
      color: var(--room-aside-active-tab-color);
      &:before {
        position: absolute;
        content: "";
        height: 4px;
        width: 100%;
        left: 0;
        top: 0;
        background-color: var(--room-aside-active-tab-bg);
      }
    }
    .badge {
      color: var(--bright-color);
      background: var(--bright-bg);
      padding: 4px 8px;
      border-radius: 8px;
      font-size: 13px;
      font-family: "Roboto Mono Medium", sans-serif;
    }
  }
  .invite {
    margin-left: auto;
    display: flex;
    align-items: center;
    .btn-invite {
      padding: 0 16px;
      height: $room-aside-header-height;
      color: var(--btn-primary-color);
      display: flex;
      justify-content: center;
      align-items: center;
      -webkit-transition: background-color 100ms linear;
      -ms-transition: background-color 100ms linear;
      transition: background-color 100ms linear;
      &:hover:not([disabled]) {
        background-color: var(--btn-primary-bg);
      }
    }
  }
  .more {
    display: flex;
    align-items: center;
    .btn-more {
      padding: 0 16px;
      height: $room-aside-header-height;
      color: var(--btn-primary-color);
      display: flex;
      justify-content: center;
      align-items: center;
      -webkit-transition: background-color 100ms linear;
      -ms-transition: background-color 100ms linear;
      transition: background-color 100ms linear;
      &:hover:not([disabled]) {
        background-color: var(--btn-primary-bg);
      }
    }
    .aside-more-view {
      position: absolute;
      z-index: 2;
      top: 100%;
      right: 0;
      width: 100%;
    }
  }
}
.aside-content {
  flex-grow: 1;
  width: 100%;
  height: 100%;
}
</style>
