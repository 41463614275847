<template>
  <MobileHeaderLayout :sidebar="true">
    <template v-slot:content>
      <div class="mobile-account">
        <Profile></Profile>
        <LoginOptions></LoginOptions>
        <Logout></Logout>
        <AccountRemoval></AccountRemoval>
      </div>
    </template>
  </MobileHeaderLayout>
</template>

<script>
import MobileHeaderLayout from '@/components/layout/MobileHeaderLayout'
import Profile from '@/views/account/Profile'
import LoginOptions from '@/views/account/LoginOptions'
import AccountRemoval from '@/views/account/AccountRemoval'
import Logout from '@/views/account/Logout'

export default {
  name: 'MobileAccount',
  components: {
    MobileHeaderLayout,
    Profile,
    LoginOptions,
    AccountRemoval,
    Logout
  }
}
</script>

<style scoped lang="scss">
@import "@/styles/variables";

.mobile-account {
  display: flex;
  flex-direction: column;
  padding: $mobile-content-padding;
  gap: 16px;
}
</style>
