const oauth2NextKey = 'oauth2.next'
const oauth2LinkProviderKey = 'oauth2.link.provider'

export class TmpService {
  getOauth2LinkProvider () {
    return localStorage.getItem(oauth2LinkProviderKey)
  }

  setOauth2LinkProvider (provider) {
    localStorage.setItem(oauth2LinkProviderKey, provider)
  }

  delOauth2LinkProvider () {
    localStorage.removeItem(oauth2LinkProviderKey)
  }

  getOauth2Next () {
    return localStorage.getItem(oauth2NextKey)
  }

  setOauth2Next (next) {
    localStorage.setItem(oauth2NextKey, next)
  }

  delOauth2Next () {
    localStorage.removeItem(oauth2NextKey)
  }

  reset () {
    this.delOauth2Next()
    this.delOauth2LinkProvider()
  }

  resetOauth2 () {
    this.delOauth2Next()
    this.delOauth2LinkProvider()
  }
}
