<template>
  <DeviceLayout>
    <template v-slot:mobile>
      <MobileMembership></MobileMembership>
    </template>
    <template v-slot:desktop>
      <Membership></Membership>
    </template>
  </DeviceLayout>
</template>

<script>
import DeviceLayout from '@/components/layout/DeviceLayout'
import Membership from '@/views/membership/Membership'
import MobileMembership from '@/views/membership/MobileMembership'

export default {
  name: 'MembershipView',
  components: {
    DeviceLayout,
    Membership,
    MobileMembership
  }
}
</script>
