<template>
  <router-link :to="{ name: 'home' }" class="sticky-header-brand">
    <SvgBrand class="brand-svg"></SvgBrand>
  </router-link>
</template>

<script>
import SvgBrand from '@/components/svg/SvgBrand'

export default {
  name: 'StickyHeaderBrand',
  components: {
    SvgBrand
  }
}
</script>

<style scoped lang="scss">
@import "@/styles/variables";

.sticky-header-brand {
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0 $sticky-header-item-padding;
}

.brand-svg {
  width: 160px;
}
</style>
