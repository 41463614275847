<template>
  <div class="guest-join">
    <div class="join-title">
      <div class="title">Join Party</div>
      <div class="sub-title">You have been invited to party!</div>
    </div>
    <form class="join-form">
      <input
        v-focus
        v-model="name"
        class="input-lt"
        type="text"
        placeholder="Enter your name..."
      />
      <ProcessButton type="submit" class="btn-primary" @submit="loginAsGuest" :processing="processing">
        JOIN
      </ProcessButton>
    </form>
    <div class="footer">
      <div class="item">
        Already have an account?
        <span class="link" @click.prevent="routeToLogin">Log In</span>
        .
      </div>
      <LoginLegal>
        <template v-slot:action>joining</template>
      </LoginLegal>
    </div>
    <LoginThirdParty :next-back="true"></LoginThirdParty>
  </div>
</template>

<script>
import LoginThirdParty from '@/components/login/LoginThirdParty'
import ProcessButton from '@/components/ProcessButton'
import LoginLegal from '@/components/login/LoginLegal'
import router from '@/router'

export default {
  name: 'GuestJoin',
  components: {
    LoginLegal,
    ProcessButton,
    LoginThirdParty
  },
  data () {
    return {
      name: '',
      processing: false
    }
  },
  methods: {
    async loginAsGuest () {
      console.log('login.as.guest')
      this.processing = true
      try {
        await this.$service.iam.loginGuest(this.name.length ? this.name : null)
      } finally {
        this.processing = false
      }
      this.$emit('logged')
    },
    async routeToLogin () {
      await router.push({ name: 'login', query: { next: window.location.pathname } })
    }
  }
}
</script>

<style scoped lang="scss">
@import "@/styles/variables";
@import "@/styles/breakpoints";

.guest-join {
  display: flex;
  flex-direction: column;
  gap: 40px;
}
.join-title {
  display: flex;
  flex-direction: column;
  gap: 16px;
  .title {
    font-family: "Roboto Medium", sans-serif;
    font-size: 26px;
    @include media-breakpoint-down(sm) {
      font-size: 24px;
    }
  }
  .sub-title {
    color: var(--muted-color);
  }
}
.join-form {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.footer {
  display: flex;
  flex-direction: column;
  gap: 8px;
  .item {
    cursor: pointer;
    color: var(--muted-color);
    font-size: 15px;
  }
}
</style>
