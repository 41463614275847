<template>
  <div class="footer-take-control">
    <ProcessButton
      class="btn-secondary-dk-sm btn-take"
      :disabled="processing"
      :processing="processing"
      @submit="takeControl"
    >
      TAKE
    </ProcessButton>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import ProcessButton from '@/components/ProcessButton'

export default {
  name: 'FooterTakeControl',
  components: {
    ProcessButton
  },
  computed: {
    ...mapState('room', {
      roomId: (state) => state.roomId
    })
  },
  data () {
    return {
      processing: false
    }
  },
  methods: {
    async takeControl () {
      this.processing = true
      try {
        await this.$service.room.takeControl(this.roomId)
      } finally {
        this.processing = false
      }
    }
  }
}
</script>

<style scoped lang="scss">
@import "@/styles/variables";

.footer-take-control {
  .btn-take {
    width: 110px;
  }
}
</style>
