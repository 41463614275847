<template>
  <VDropdown
    :placement="placement"
    :positioning-disabled="isMobile"
  >
    <slot></slot>
    <template #popper="{ hide }">
      <slot name="content" :hide="hide"></slot>
    </template>
  </VDropdown>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'Popup',
  props: {
    placement: {
      type: String,
      default: 'auto'
    }
  },
  computed: {
    ...mapState({
      isMobile: state => state.isMobile
    })
  }
}
</script>

<style scoped lang="scss">
@import "@/styles/variables";
</style>
