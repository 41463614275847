import { createStore } from 'vuex'
import { isMobile } from '@/utils/common'
import me from '@/store/me'
import room from '@/store/room'

function defaultState () {
  return {
    isMobile: isMobile(),
    theme: 'default',
    preloader: false
  }
}

export default createStore({
  state: defaultState(),
  getters: {
  },
  mutations: {
    update (state, data) {
      Object.assign(state, data)
    }
  },
  actions: {
    reset ({ state, commit }) {
      if (state.verificationAlertShakeTimer) {
        clearTimeout(state.verificationAlertShakeTimer)
      }
      commit('update', defaultState())
    },
    setTheme ({ commit }, theme) {
      if (['theme'].includes(theme)) {
        throw Error(`Unknown theme: ${theme}`)
      }
      commit('update', { theme })
    },
    showPreloader (context) {
      context.commit('update', { preloader: true })
    },
    hidePreloader (context) {
      context.commit('update', { preloader: false })
    }
  },
  modules: {
    me,
    room
  }
})
