<template>
  <div class="mobile-header-layout">
    <MobileHeader>
      <div class="header-wrap">
        <div class="header">
          <template v-if="nav === 'home'">
            <div class="title clamp-1">Home</div>
          </template>
          <template v-else-if="nav === 'membership'">
            <div class="title clamp-1">Membership</div>
          </template>
          <template v-else-if="nav === 'account'">
            <div class="title clamp-1">Account</div>
          </template>
          <template v-else>
            <MobileHeaderBrand></MobileHeaderBrand>
          </template>
        </div>
      </div>
    </MobileHeader>
    <div class="content-wrap scroll-y"
         @scroll="handleScroll"
    >
      <div class="content">
        <slot name="content"></slot>
      </div>
    </div>
    <MobileSidebar v-if="sidebar"></MobileSidebar>
  </div>
</template>

<script>
import MobileHeader from '@/components/mobile-header/MobileHeader'
import MobileHeaderBrand from '@/components/mobile-header/MobileHeaderBrand'
import MobileSidebar from '@/components/sidebar/MobileSidebar'

export default {
  name: 'MobileHeaderLayout',
  props: {
    sidebar: {
      type: Boolean,
      default: false
    }
  },
  components: {
    MobileHeader,
    MobileHeaderBrand,
    MobileSidebar
  },
  data () {
    return {
      nav: this.$route.name
    }
  },
  methods: {
    handleScroll (event) {
      this.stickiedHeader = Boolean(event.target.scrollTop)
    }
  }
}
</script>

<style scoped lang="scss">
@import "@/styles/variables";

.mobile-header-layout {
  width: 100vw;
  height: 100svh;
  display: flex;
  flex-direction: column;
}
.header-wrap {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  padding: 0 $mobile-content-padding;
  .header {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    .title {
      flex-grow: 1;
      font-family: "Roboto Medium", sans-serif;
      font-size: 18px;
    }
  }
}
.content-wrap {
  flex-grow: 1;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  .content {
    height: 100%;
    width: 100%;
  }
}
</style>
