<template>
  <router-link class="mobile-room-card"
               :to="{ name: 'room', params: { roomSid: room.sid } }"
  >
    <ContentLayout
      :add-paddings="false"
    >
      <template v-slot:content>
        <div class="info">
          <div class="name clamp-1">{{ room.name }}</div>
          <div class="badges">
            <div class="stats">
              {{ room.memberCount }} watching
              <div
                class="dot dot-online"
                v-if="
                    room.status === 'ONLINE' ||
                    room.status === 'QUEUING' ||
                    room.status === 'PENDING'
                  "
              ></div>
              <div class="dot dot-offline" v-else></div>
            </div>
          </div>
        </div>
      </template>
    </ContentLayout>
  </router-link>
</template>

<script>
import ContentLayout from '@/components/layout/ContentLayout'

export default {
  name: 'MobileRoomCard',
  props: {
    room: Object
  },
  components: {
    ContentLayout
  }
}
</script>

<style scoped lang="scss">
@import "@/styles/variables";

.mobile-room-card {
  color: var(--primary-color);
  text-decoration: none;
  background: var(--content-layout-bg);
  user-select: none;
  border-radius: 8px;
  padding: 0 $mobile-content-padding;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  gap: 24px;
  &:active {
    background: var(--click-bg);
  }
  .info {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    gap: 8px;
    .name {
      font-size: 15px;
      font-family: "Roboto Mono Medium", sans-serif;
    }
    .badges {
      display: flex;
      align-items: center;
      .stats {
        font-size: 14px;
        display: flex;
        align-items: center;
        gap: 8px;
        .dot {
          height: 8px;
          width: 8px;
          border-radius: 50%;
          display: inline-block;
        }
        .dot-online {
          background-color: var(--status-green);
        }
        .dot-offline {
          background-color: var(--status-default);
        }
      }
    }
  }
}
</style>
