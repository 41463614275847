<template>
  <SidebarLayout>
    <template v-slot:content>
      <div class="membership">
        <VerificationAlert v-if="imGuest"></VerificationAlert>
        <MembershipProfile></MembershipProfile>
        <MembershipPerks></MembershipPerks>
      </div>
    </template>
  </SidebarLayout>
</template>

<script>
import { mapState } from 'vuex'
import SidebarLayout from '@/components/layout/SidebarLayout'
import MembershipProfile from '@/views/membership/MembershipProfile'
import MembershipPerks from '@/views/membership/MembershipPerks'
import VerificationAlert from '@/components/VerificationAlert'

export default {
  name: 'Membership',
  components: {
    SidebarLayout,
    MembershipProfile,
    MembershipPerks,
    VerificationAlert
  },
  computed: {
    ...mapState('me', {
      role: (state) => state.role
    }),
    imGuest () {
      return this.role === 'GUEST'
    }
  }
}
</script>

<style scoped lang="scss">
@import "@/styles/variables";

.membership {
  padding-bottom: $content-padding;
  display: flex;
  flex-direction: column;
  gap: 32px;
}
</style>
