<template>
  <slot v-if="roomShown"></slot>
</template>

<script>
import { mapState } from 'vuex'
import { events } from '@/components/room/events'

export default {
  name: 'RoomWrap',
  computed: {
    ...mapState('room', {
      roomId: (state) => state.roomId,
      roomShown: (state) => state.shown
    })
  },
  beforeMount () {
    events.on('open', this.open)
    events.on('close', this.close)
  },
  beforeUnmount () {
    events.off('open', this.open)
    events.off('close', this.close)
  },
  methods: {
    open (data) {
      this.destroy()
      this.$store.dispatch(
        'room/reload',
        Object.assign(data, {
          shown: true,
          mini: false,
          theater: false,
          fullscreen: false
        })
      )
      this.init()
    },
    close () {
      this.$store.commit('room/update', { shown: false })
      this.destroy()
      this.$store.dispatch('room/reset')
    },
    init () {
      this.initSub()
      this.initWs()
      this.initSubEvents()
    },
    destroy () {
      this.destroySub()
      this.destroyWs()
      this.destroySubEvents()
    },
    initSub () {
      this.$service.roomCentrifugoService.init(this.roomId)
    },
    destroySub () {
      this.$service.roomCentrifugoService.destroy()
    },
    initWs () {
      this.$service.roomWs.init(this.roomId)
    },
    destroyWs () {
      this.$service.roomWs.destroy()
    },
    initSubEvents () {
      this.$service.roomCentrifugoService.subEvents.on('status', this.onStatus)
    },
    destroySubEvents () {
      this.$service.roomCentrifugoService.subEvents.off('status', this.onStatus)
    },
    onStatus (data) {
      if (data.status === 'ONLINE' && !data.membership) {
        this.$toast.info({
          type: 'membership',
          context: {
            text: 'You are in a demo party that will be automatically closed after a 15m. Please upgrade your membership to lift the time limit.'
          }
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/styles/variables";
</style>
