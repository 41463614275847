import Axios from 'axios'
import store from '@/store'
import { formatTimeAgo } from '@/utils/date'

class RoomApi {
  constructor (api) {
    this.api = api
  }

  async createRoom (name) {
    const response = await this.api.post(
      '/',
      {
        name
      },
      { useAccessToken: true }
    )
    return {
      id: response.data.id,
      sid: response.data.sid,
      memberCount: response.data.member_count,
      name: response.data.name,
      status: response.data.status
    }
  }

  parseChat (responseChat) {
    const chat = []
    for (const chatItem of responseChat) {
      chat.push({
        id: chatItem.id,
        roomId: chatItem.room_id,
        userId: chatItem.user_id,
        message: {
          text: chatItem.message.text,
          attachments: chatItem.message.attachments,
          name: chatItem.message.name,
          avatar: chatItem.message.avatar
        },
        createdAt: chatItem.created_at,
        timeAgo: formatTimeAgo(chatItem.created_at)
      })
    }
    return chat
  }

  parseMembers (responseMembers) {
    const membersMap = {}
    const members = []
    for (const member of responseMembers) {
      const parsed = {
        userId: member.user_id,
        avatar: member.avatar,
        name: member.name,
        role: member.role,
        membership: member.membership
      }
      members.push(parsed)
      membersMap[parsed.userId] = parsed
    }
    return { membersMap, members }
  }

  async join (roomSid) {
    const response = await this.api.post(
      `/${roomSid}/join`,
      {},
      { useAccessToken: true }
    )
    const data = {
      roomId: response.data.id,
      roomSid: response.data.sid,
      ownerId: response.data.owner_id,
      hostId: response.data.host_id,
      owner: {
        userId: response.data.owner.user_id,
        avatar: response.data.owner.avatar,
        name: response.data.owner.name,
        role: response.data.owner.role,
        membership: response.data.owner.membership
      },
      host: null,
      name: response.data.name,
      memberCount: response.data.member_count,
      status: response.data.status,
      membership: response.data.membership,
      livePartyId: response.data.live_party_id
    }
    // host
    if (response.data.host) {
      data.host = {
        userId: response.data.host.user_id,
        avatar: response.data.host.avatar,
        name: response.data.host.name,
        role: response.data.host.role,
        membership: response.data.host.membership
      }
    }
    // chat
    data.chat = this.parseChat(response.data.chat)
    // members
    const { membersMap, members } = this.parseMembers(response.data.members)
    data.members = members
    data.membersMap = membersMap
    return data
  }

  async getChat (roomId, createdAtLt) {
    let response
    if (createdAtLt) {
      response = await this.api.get(
        `/${roomId}/chat?created_at_lt=${createdAtLt}`,
        {
          useAccessToken: true
        }
      )
    } else {
      response = await this.api.get(`/${roomId}/chat`, {
        useAccessToken: true
      })
    }
    return this.parseChat(response.data)
  }

  async getMembers (roomId) {
    const response = await this.api.get(`/${roomId}/members`, {
      useAccessToken: true
    })
    return this.parseMembers(response.data)
  }

  async startParty (roomId, startupUrl) {
    const response = await this.api.post(
      `/${roomId}/start-party`,
      {
        startup_url: startupUrl
      },
      { useAccessToken: true }
    )
    return {
      status: response.data.status,
      livePartyId: response.data.live_party_id
    }
  }

  async stopParty (roomId) {
    const response = await this.api.post(
      `/${roomId}/stop-party`,
      {},
      { useAccessToken: true }
    )
    return {
      status: response.data.status
    }
  }

  async updateHost (roomId, hostId) {
    const response = await this.api.put(
      `/${roomId}/host`,
      {
        host_id: hostId
      },
      { useAccessToken: true }
    )
    return {
      hostId: response.data.user_id,
      host: {
        avatar: response.data.avatar,
        name: response.data.name,
        role: response.data.role,
        userId: response.data.user_id,
        membership: response.data.membership
      }
    }
  }

  async releaseControl (roomId) {
    return await this.api.post(
      `/${roomId}/release-control`,
      {},
      { useAccessToken: true }
    )
  }

  async takeControl (roomId) {
    const response = await this.api.post(
      `/${roomId}/take-control`,
      {},
      { useAccessToken: true }
    )
    return {
      hostId: response.data.user_id,
      host: {
        avatar: response.data.avatar,
        name: response.data.name,
        role: response.data.role,
        userId: response.data.user_id,
        membership: response.data.membership
      }
    }
  }
}

export class RoomServiceError extends Error {
  constructor (code, details) {
    super(code)
    this.code = code
    this.details = details
  }
}

export class RoomService {
  constructor (app, api) {
    this.app = app
    this.api = new RoomApi(api)
  }

  get toast () {
    return this.app.config.globalProperties.$toast
  }

  get centrifugo () {
    return this.app.config.globalProperties.$service.centrifugo
  }

  get jwt () {
    return this.app.config.globalProperties.$service.jwt
  }

  async createRoom (name) {
    let data
    try {
      data = await this.api.createRoom(name)
    } catch (err) {
      if (Axios.isCancel(err)) {
        throw new RoomServiceError('request.canceled')
      } else if (Axios.isAxiosError(err)) {
        if (err.response && err.response.status === 400) {
          if (err.response.data.error.code === 'max.rooms.per.user.exceeded') {
            const maxRoomsPerUser = err.response.data.error.details.max_rooms_per_user
            this.toast.warning({
              type: 'simple',
              context: {
                text: `You can't create more than ${maxRoomsPerUser} rooms`
              }
            })
            throw new RoomServiceError('max.rooms.per.user.exceeded', { maxRoomsPerUser })
          } else if (err.response.data.error.code === 'verification.required') {
            throw new RoomServiceError('verification.required')
          }
        } else if (err.response && err.response.status === 422) {
          this.toast.warning({
            type: 'simple',
            context: {
              text: 'You\'ve entered an invalid Name'
            }
          })
          throw new RoomServiceError('invalid.name')
        }
      }
      this.toast.somethingWentWrong()
      throw err
    }
    await store.dispatch('me/addRoom', data)
  }

  async join (roomSid) {
    let data
    try {
      data = await this.api.join(roomSid)
    } catch (err) {
      if (Axios.isCancel(err)) {
        throw new RoomServiceError('request.canceled')
      } else if (Axios.isAxiosError(err)) {
        if (err.response && err.response.status === 400) {
          if (err.response.data.error.code === 'room.not.found') {
            throw new RoomServiceError('room.not.found')
          }
        }
      }
      throw err
    }
    return data
  }

  async getChat (roomId, createdAtLt) {
    let data
    try {
      data = await this.api.getChat(roomId, createdAtLt)
    } catch (err) {
      if (Axios.isCancel(err)) {
        throw new RoomServiceError('request.canceled')
      }
      this.toast.somethingWentWrong()
      throw err
    }
    if (data.length) {
      await store.dispatch('room/unshiftChatItems', {
        items: data
      })
    }
    return data
  }

  async stopParty (roomId) {
    let data
    try {
      data = await this.api.stopParty(roomId)
    } catch (err) {
      if (Axios.isCancel(err)) {
        throw new RoomServiceError('request.canceled')
      }
      this.toast.somethingWentWrong()
      throw err
    }
    store.commit('room/update', {
      status: data.status
    })
    return data
  }

  async startParty (roomId, startupUrl) {
    let data
    try {
      data = await this.api.startParty(roomId, startupUrl)
    } catch (err) {
      if (Axios.isCancel(err)) {
        throw new RoomServiceError('request.canceled')
      }
      this.toast.somethingWentWrong()
      throw err
    }
    store.commit('room/update', {
      status: data.status
    })
    return data
  }

  async updateHost (roomId, hostId) {
    let data
    try {
      data = await this.api.updateHost(roomId, hostId)
    } catch (err) {
      if (Axios.isCancel(err)) {
        throw new RoomServiceError('request.canceled')
      }
      this.toast.somethingWentWrong()
      throw err
    }
    store.commit('room/update', data)
    return data
  }

  async releaseControl (roomId) {
    try {
      await this.api.releaseControl(roomId)
    } catch (err) {
      if (Axios.isCancel(err)) {
        throw new RoomServiceError('request.canceled')
      }
      this.toast.somethingWentWrong()
      throw err
    }
    store.commit('room/update', {
      hostId: null,
      host: null
    })
  }

  async takeControl (roomId) {
    let data
    try {
      data = await this.api.takeControl(roomId)
    } catch (err) {
      if (Axios.isCancel(err)) {
        throw new RoomServiceError('request.canceled')
      }
      this.toast.somethingWentWrong()
      throw err
    }
    store.commit('room/update', data)
    return data
  }

  async refreshMembers (roomId) {
    let data
    try {
      data = await this.api.getMembers(roomId)
    } catch (err) {
      if (Axios.isCancel(err)) {
        throw new RoomServiceError('request.canceled')
      }
      this.toast.somethingWentWrong()
      throw err
    }
    store.commit('room/update', data)
  }
}
