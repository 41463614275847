<template>
  <div class="chat-emoji-list" tabindex="0" @keydown="onKeydown">
    <div class="header">
      <span class="title">Emoji matching</span>
      <span class="query">:{{ query }}</span>
    </div>
    <div class="content">
      <div
        class="emoji"
        v-for="(emoji, index) in emojis"
        :key="index"
        :id="`emoji-${index}`"
        :class="{ active: activeEmojiInd === index }"
        @mouseover="activeEmojiInd = index"
        @click="chooseEmoji"
      >
        <img :src="getEmojiImage(emoji)" :alt="`:${emoji}:`" />
        :{{ emoji }}:
      </div>
    </div>
  </div>
</template>

<script>
import { findEmojisByName, getEmojiImage } from '@/utils/emoji'

export default {
  name: 'ChatEmojiList',
  props: {
    query: {
      type: String
    }
  },
  watch: {
    query () {
      this.render()
    }
  },
  data () {
    return {
      emojis: [],
      activeEmojiInd: 0
    }
  },
  mounted () {
    this.render()
  },
  methods: {
    render () {
      this.activeEmojiInd = 0
      this.emojis = findEmojisByName(this.query, { limit: 10 })
      if (!this.emojis.length) {
        this.close()
      }
    },
    chooseEmoji () {
      this.$emit('chosen', {
        query: this.query,
        name: this.emojis[this.activeEmojiInd]
      })
      this.close()
    },
    close () {
      this.$emit('close')
    },
    onEscape () {
      this.close()
    },
    onArrowDown () {
      if (this.activeEmojiInd < this.emojis.length - 1) {
        this.activeEmojiInd += 1
      } else {
        this.activeEmojiInd = 0
      }
    },
    onArrowUp () {
      if (this.activeEmojiInd > 0) {
        this.activeEmojiInd -= 1
      } else {
        this.activeEmojiInd = this.emojis.length - 1
      }
    },
    onEnter () {
      if (this.emojis.length && this.emojis[this.activeEmojiInd]) {
        this.chooseEmoji()
      }
    },
    execute (data) {
      const { key } = data
      if (key === 'Escape') {
        this.onEscape()
      } else if (key === 'ArrowDown') {
        this.onArrowDown()
      } else if (key === 'ArrowUp') {
        this.onArrowUp()
      } else if (key === 'Enter') {
        this.onEnter()
      }
    },
    onKeydown (e) {
      const { key } = e
      if (key === 'Escape') {
        e.preventDefault()
        this.onEscape()
      } else if (key === 'ArrowDown') {
        e.preventDefault()
        this.onArrowDown()
      } else if (key === 'ArrowUp') {
        e.preventDefault()
        this.onArrowUp()
      } else if (key === 'Enter') {
        e.preventDefault()
        this.onEnter()
      }
    },
    getEmojiImage
  }
}
</script>

<style scoped lang="scss">
@import "@/styles/variables";

.chat-emoji-list {
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: var(--room-aside-alt-bg);
}
.header {
  padding: 12px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  color: var(--muted-color);
  font-size: 14px;
  .query {
    font-size: 15px;
    color: var(--primary-color);
    flex-grow: 1;
    min-width: 0;
    overflow: hidden;
  }
}
.content {
  min-height: 40px;
  max-height: 400px;
  padding-bottom: 8px;
  .emoji {
    cursor: pointer;
    padding: 6px 12px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    font-size: 15px;
    &.active {
      color: var(--bright-color);
      background-color: var(--room-aside-alt-lt-bg);
    }
  }
}
</style>
