<template>
  <DeviceLayout>
    <template v-slot:mobile>
      <MobileHome></MobileHome>
    </template>
    <template v-slot:desktop>
      <Home></Home>
    </template>
  </DeviceLayout>
</template>

<script>
import DeviceLayout from '@/components/layout/DeviceLayout'
import Home from '@/views/home/Home'
import MobileHome from '@/views/home/MobileHome'

export default {
  name: 'HomeView',
  components: {
    DeviceLayout,
    Home,
    MobileHome
  }
}
</script>
