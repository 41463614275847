<template>
  <MobileHeaderLayout :sidebar="true">
    <template v-slot:content>
      <div class="mobile-help">
        <HelpText></HelpText>
      </div>
    </template>
  </MobileHeaderLayout>
</template>

<script>
import MobileHeaderLayout from '@/components/layout/MobileHeaderLayout'
import HelpText from '@/views/help/HelpText'

export default {
  name: 'MobileHelp',
  components: {
    MobileHeaderLayout,
    HelpText
  }
}
</script>

<style scoped lang="scss">
@import "@/styles/variables";

.mobile-help {
  display: flex;
  flex-direction: column;
  padding: $mobile-content-padding;
  gap: 16px;
}
</style>
