<template>
  <div class="mobile-nav-popup-content">
    <div class="account">
      <div class="avatar-wrap">
        <Avatar class="avatar-lg" :avatar="avatar"></Avatar>
      </div>
      <div class="name">Hi, {{ name }}</div>
      <button class="btn-primary btn-manage"
              @click="goToAccount"
      >Manage your Bearcat Account</button
      >
    </div>
    <div class="hr" />
    <div class="room">
      <template v-if="userId === roomOwnerId">
        <div class="avatar-wrap">
          <Avatar class="avatar-sm" :avatar="avatar"></Avatar>
        </div>
        <div class="content">
          <div class="room-owner">{{ name }}</div>
          <div class="room-name">{{ roomName }}</div>
        </div>
      </template>
      <template v-else>
        <Avatar class="avatar-sm" :avatar="roomOwner.avatar"></Avatar>
        <div class="content">
          <div class="room-owner">{{ roomOwner.name }}</div>
          <div class="room-name">{{ roomName }}</div>
        </div>
      </template>
    </div>
    <div class="actions">
      <template v-if="userId === roomOwnerId">
        <template v-if="roomStatus === 'OFFLINE'">
          <button class="btn-secondary-sm btn-action btn-leave" @click.prevent="leaveRoom">
            LEAVE ROOM
          </button>
        </template>
        <template v-else>
          <ProcessButton
            class="btn-danger-sm btn-action btn-stop"
            :processing="stopPartyProcessing"
            @submit="stopParty"
          >
            STOP PARTY
          </ProcessButton>
        </template>
      </template>
      <template v-else>
        <button class="btn-secondary-sm btn-action btn-leave" @click.prevent="leaveRoom">
          LEAVE ROOM
        </button>
      </template>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Avatar from '@/components/Avatar'
import ProcessButton from '@/components/ProcessButton'

export default {
  name: 'MobileNavMorePopupContent',
  components: {
    ProcessButton,
    Avatar
  },
  computed: {
    ...mapState('me', {
      userId: (state) => state.userId,
      avatar: (state) => state.avatar,
      name: (state) => state.name,
      membership: (state) => state.membership
    }),
    ...mapState('room', {
      roomId: (state) => state.roomId,
      roomStatus: (state) => state.status,
      roomOwnerId: (state) => state.ownerId,
      roomOwner: (state) => state.owner,
      roomName: (state) => state.name
    })
  },
  data () {
    return {
      stopPartyProcessing: false
    }
  },
  methods: {
    goToAccount () {
      this.$emit('close')
      this.$router.push({ name: 'account' })
    },
    async stopParty () {
      this.stopPartyProcessing = true
      try {
        await this.$service.room.stopParty(this.roomId)
      } finally {
        this.stopPartyProcessing = false
      }
      this.$emit('close')
    },
    async leaveRoom () {
      this.$room.close()
      await this.$router.push({ name: 'home' })
    }
  }
}
</script>

<style scoped lang="scss">
@import "@/styles/variables";

.mobile-nav-popup-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  padding: 24px 20px;
  width: 100%;
  .account {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    width: 100%;
    .name {
      color: var(--bright-color);
      font-size: 16px;
      font-family: "Roboto Medium", sans-serif;
    }
    .btn-manage {
      width: 100%;
      max-width: 300px;
    }
  }
  .room {
    display: flex;
    align-items: center;
    gap: 12px;
    .content {
      display: flex;
      flex-direction: column;
      gap: 4px;
      .room-owner {
        color: var(--bright-color);
        font-size: 15px;
        font-family: "Roboto Medium", sans-serif;
      }
      .room-name {
        font-size: 15px;
      }
    }
  }
  .actions {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 16px;
    gap: 12px;
    .btn-action {
      width: 100%;
      max-width: 300px;
    }
  }
}
</style>
