<template>
  <div class="setup-email-modal">
    <SetupEmailWelcome v-if="step === 'welcome'" :modal="modal" @done="goToCode"></SetupEmailWelcome>
    <SetupEmailCode v-else-if="step === 'code'" :modal="modal" :otp-id="otpId" :email="email"></SetupEmailCode>
  </div>
</template>

<script>
import SetupEmailWelcome from '@/components/modals/SetupEmailWelcome'
import SetupEmailCode from '@/components/modals/SetupEmailCode'

export default {
  name: 'SetupEmailModal',
  components: {
    SetupEmailWelcome,
    SetupEmailCode
  },
  props: {
    modal: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      step: 'welcome',
      otpId: null,
      email: null
    }
  },
  methods: {
    close () {
      this.modal.close()
    },
    goToCode ({ otpId, email }) {
      console.log('otp.sent', otpId, email)
      this.otpId = otpId
      this.email = email
      this.step = 'code'
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/styles/variables";
@import "@/styles/breakpoints";

.setup-email-modal {
  position: relative;
  width: 100vw;
  @include media-breakpoint-up(sm) {
    max-width: 380px;
  }
}
</style>
