<template>
  <div class="setup-email-code">
    <ModalHeader :modal="modal">
      <template v-slot:title>Let's verify your email</template>
    </ModalHeader>
    <div class="description line-height">
      You will receive a code within 1 minute to {{ email }}
    </div>
    <form class="form">
      <div class="content">
        <v-otp-input
          v-model:value="code"
          input-classes="otp-input"
          separator=""
          :num-inputs="4"
          :should-auto-focus="!isMobile"
          input-type="letter-numeric"
          :conditionalClass="['one', 'two', 'three', 'four']"
          :placeholder="['*', '*', '*', '*']"
          @on-complete="linkEmail"
        />
      </div>
      <ModalFooter class="footer">
        <template v-slot:content>
          <ProcessButton
            type="submit"
            class="btn-primary-sm btn-verify"
            :processing="processing"
            @submit="linkEmail"
          >
            VERIFY
          </ProcessButton>
        </template>
      </ModalFooter>
    </form>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import ProcessButton from '@/components/ProcessButton'
import ModalHeader from '@/components/modals/ModalHeader'
import ModalFooter from '@/components/modals/ModalFooter'
import { IamServiceError } from '@/service/iam.service'
// import { IamServiceError } from '@/service/iam.service'

export default {
  name: 'SetupEmailCode',
  components: {
    ProcessButton,
    ModalHeader,
    ModalFooter
  },
  props: {
    modal: {
      type: Object,
      required: true
    },
    otpId: String,
    email: String
  },
  data () {
    return {
      code: '',
      processing: false
    }
  },
  computed: {
    ...mapState({
      isMobile: state => state.isMobile
    })
  },
  methods: {
    close () {
      this.modal.close()
    },
    async linkEmail () {
      console.log('link.email')
      this.processing = true
      try {
        await this.$service.iam.linkEmail(this.otpId, this.code)
      } catch (err) {
        if (err instanceof IamServiceError) {
          if (err.code === 'link.error') {
            this.close()
            throw err
          }
        }
        throw err
      } finally {
        this.processing = false
      }
      this.close()
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/styles/variables";
@import "@/styles/breakpoints";

.setup-email-code {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.description {
  padding: 0 24px;
}

.form {
  display: flex;
  flex-direction: column;
  .content {
    padding: 24px 24px 8px;
    display: flex;
    justify-content: center;
  }
  .footer {
    .btn-verify {
      max-width: 110px;
    }
  }
}
</style>
