<template>
  <div class="setup-email-welcome">
    <ModalHeader :modal="modal">
      <template v-slot:title>Setup email</template>
    </ModalHeader>
    <form class="form">
      <div class="content">
        <input
          v-focus
          v-model="email"
          class="input-lt-sm"
          type="email"
          placeholder="Enter your email..."
        />
      </div>
      <ModalFooter class="footer">
        <template v-slot:content>
          <ProcessButton
            type="submit"
            class="btn-primary-sm btn-continue"
            :processing="processing"
            @submit="sendOtp"
          >
            CONTINUE
          </ProcessButton>
        </template>
      </ModalFooter>
    </form>
  </div>
</template>

<script>
import ProcessButton from '@/components/ProcessButton'
import ModalHeader from '@/components/modals/ModalHeader'
import ModalFooter from '@/components/modals/ModalFooter'

export default {
  name: 'SetupEmailWelcome',
  components: {
    ProcessButton,
    ModalHeader,
    ModalFooter
  },
  props: {
    modal: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      email: null,
      processing: false
    }
  },
  methods: {
    close () {
      this.modal.close()
    },
    async sendOtp () {
      console.log('send.otp')
      this.processing = true
      let data
      try {
        data = await this.$service.iam.sendEmailOtp(this.email)
      } finally {
        this.processing = false
      }
      const { otpId } = data
      this.$emit('done', { otpId, email: this.email })
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/styles/variables";
@import "@/styles/breakpoints";

.setup-email-welcome{
  width: 100%;
  display: flex;
  flex-direction: column;
}

.form {
  display: flex;
  flex-direction: column;
  .content {
    padding: 0 24px;
  }
  .footer {
    .btn-continue {
      max-width: 110px;
    }
  }
}
</style>
