<template>
  <ContentLayout>
    <template v-slot:title>LOGOUT</template>
    <template v-slot:content>
      <div class="logout">
        <div class="description line-height">
          You can come back at any time using one of your login options.
        </div>
        <button class="btn-secondary-sm btn-logout" type="button" @click="logout">LOGOUT</button>
      </div>
    </template>
  </ContentLayout>
</template>

<script>
import ContentLayout from '@/components/layout/ContentLayout'

export default {
  name: 'Logout',
  components: {
    ContentLayout
  },
  methods: {
    logout () {
      this.$modal.open({
        type: 'logout'
      })
    }
  }
}
</script>

<style scoped lang="scss">
@import "@/styles/variables";
@import "@/styles/breakpoints";

.logout {
  display: grid;
  grid-template-columns: 1fr 110px;
  column-gap: 16px;
  row-gap: 24px;
  align-items: center;
  @include media-breakpoint-down(sm) {
    grid-template-columns: repeat(1, 1fr);
  }
}
</style>
