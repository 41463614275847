<template>
  <div class="mobile-online">
    <LiveStream></LiveStream>
  </div>
</template>

<script>
import LiveStream from '@/components/room/online/LiveStream'

export default {
  name: 'MobileOnline',
  components: {
    LiveStream
  }
}
</script>

<style lang="scss" scoped>
@import "@/styles/variables";

.mobile-online {
  aspect-ratio: 16 / 9;
  width: 100%;
}
</style>
