<template>
  <div class="member-popup-content">
    <div class="user">
      <Avatar class="avatar-md" :avatar="computedAvatar"></Avatar>
      <div class="user-info">
        <div class="name">{{ computedName }}</div>
        <div class="online">
          <template v-if="online">
            online
            <div class="dot dot-online"></div>
          </template>
          <template v-else>
            offline
            <div class="dot dot-offline"></div>
          </template>
        </div>
      </div>
    </div>
    <div class="badges" v-if="showBadges">
      <div class="badge badge-owner" v-if="isOwner">
        OWNER
      </div>
      <div class="badge badge-host" v-if="isHost">
        HOST
      </div>
      <div class="badge badge-verified" v-if="verified">
        VERIFIED
      </div>
      <div class="badge badge-membership" v-if="membership">
        PREMIUM
      </div>
    </div>
    <div class="actions" v-if="(imOwner || imHost) && online && roomOnline && !isHost">
      <ProcessButton
        class="btn-secondary-sm"
        :processing="giveControlProcessing"
        @submit="giveControl"
      >
        GIVE CONTROL
      </ProcessButton>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Avatar from '@/components/Avatar'
import ProcessButton from '@/components/ProcessButton'

export default {
  name: 'MemberPopupContent',
  components: {
    Avatar,
    ProcessButton
  },
  props: {
    userId: {
      type: String,
      default: null
    },
    name: {
      type: String,
      default: null
    },
    avatar: {
      type: String,
      default: null
    }
  },
  data () {
    return {
      giveControlProcessing: false
    }
  },
  computed: {
    ...mapState('me', {
      myUserId: (state) => state.userId
    }),
    ...mapState('room', {
      roomId: (state) => state.roomId,
      membersMap: (state) => state.membersMap,
      ownerId: (state) => state.ownerId,
      hostId: (state) => state.hostId,
      roomStatus: (state) => state.status
    }),
    roomOnline () {
      return this.roomStatus === 'ONLINE'
    },
    showBadges () {
      return this.isOwner || this.isHost || this.verified || this.membership
    },
    imOwner () {
      return this.myUserId === this.ownerId
    },
    imHost () {
      return this.myUserId === this.hostId
    },
    isOwner () {
      return this.userId === this.ownerId
    },
    isHost () {
      return this.userId === this.hostId
    },
    verified () {
      return this.online && this.member.role !== 'GUEST'
    },
    membership () {
      return this.online && this.member.membership
    },
    computedName () {
      if (this.online) {
        return this.member.name || this.name || 'Member'
      }
      return this.name || 'Member'
    },
    computedAvatar () {
      if (this.online) {
        return this.member.avatar || this.avatar
      }
      return this.avatar
    },
    online () {
      return !!this.member
    },
    member () {
      return this.membersMap[this.userId]
    }
  },
  methods: {
    async giveControl () {
      this.giveControlProcessing = true
      try {
        await this.$service.room.updateHost(this.roomId, this.userId)
      } finally {
        this.giveControlProcessing = false
      }
      this.$emit('close')
    }
  }
}
</script>

<style scoped lang="scss">
@import "@/styles/variables";
@import "@/styles/breakpoints";

.member-popup-content {
  width: 350px;
  @include media-breakpoint-down(xxl) {
    width: 100%;
    max-width: 500px;
  }
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 16px 24px;
}

.user {
  display: flex;
  align-items: center;
  gap: 16px;
  .user-info {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    gap: 6px;
    .name {
      font-family: "Roboto Medium", sans-serif;
      font-size: 18px;
    }
    .online {
      font-size: 16px;
      display: flex;
      align-items: center;
      gap: 8px;
      .dot {
        height: 8px;
        width: 8px;
        border-radius: 50%;
        display: inline-block;
      }
      .dot-online {
        background-color: var(--status-green);
      }
      .dot-offline {
        background-color: var(--status-default);
      }
    }
  }
}

.badges {
  display: flex;
  align-items: center;
  gap: 8px;
  .badge {
    border-radius: 8px;
    padding: 6px 12px;
    font-family: "Roboto Medium", sans-serif;
    font-size: 13px;
    color: var(--bright-color);
  }
  .badge-owner {
    background-color: var(--member-card-badge-owner-bg);
  }
  .badge-host {
    background-color: var(--member-card-badge-host-bg);
  }
  .badge-verified {
    background-color: var(--member-card-badge-verified-bg);
  }
  .badge-membership {
    background-color: var(--member-card-badge-membership-bg);
  }
}
</style>
