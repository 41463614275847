<template>
  <div class="footer-lock">
    <button class="btn btn-lock" v-if="lock" @click.prevent="toggleLock">
      <SvgLock width="22"></SvgLock>
    </button>
    <button class="btn btn-lock" v-else @click.prevent="toggleLock">
      <SvgUnlock width="22"></SvgUnlock>
    </button>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import SvgLock from '@/components/svg/SvgLock'
import SvgUnlock from '@/components/svg/SvgUnlock'

export default {
  name: 'FooterLock',
  components: {
    SvgLock,
    SvgUnlock
  },
  computed: {
    ...mapState('room', {
      lock: (state) => state.lock
    })
  },
  methods: {
    toggleLock () {
      this.$store.commit('room/update', { lock: !this.lock })
    }
  }
}
</script>

<style scoped lang="scss">
@import "@/styles/variables";

.footer-lock {
  display: flex;
  align-items: center;
  height: 100%;
  position: relative;
}
.btn-lock {
  height: 100%;
  padding: 0 16px;
  color: var(--muted-color);
  &:hover:not([disabled]) {
    color: var(--primary-color);
  }
}
</style>
