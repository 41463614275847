<template>
  <ContentLayout>
    <template v-slot:content>
      <div class="verification-alert-content">
        <div class="icon">
          <SvgAlertTriangle width="38"></SvgAlertTriangle>
        </div>
        <div class="content">
          <div class="message line-height">
            Some app features are restricted. Please setup login option to lift restrictions.
          </div>
          <div class="actions">
            <button class="btn-secondary-sm btn-action"
                    @click="setupEmail"
            >
              EMAIL
            </button>
            <button class="btn-secondary-sm btn-action"
                    @click="setupDiscord"
            >
              DISCORD
            </button>
            <button class="btn-secondary-sm btn-action"
                    @click="setupGoogle"
            >
              GOOGLE
            </button>
          </div>
        </div>
      </div>
    </template>
  </ContentLayout>
</template>

<script>
import ContentLayout from '@/components/layout/ContentLayout'
import SvgAlertTriangle from '@/components/svg/SvgAlertTriangle'
import { discordAuthUrl, googleAuthUrl } from '@/settings'

export default {
  name: 'VerificationAlert',
  components: {
    ContentLayout,
    SvgAlertTriangle
  },
  methods: {
    setTmp (provider) {
      this.$service.tmp.reset()
      this.$service.tmp.setOauth2LinkProvider(provider)
      this.$service.tmp.setOauth2Next(this.$route.fullPath)
    },
    setupEmail () {
      console.log('link.email')
      this.$modal.open({
        type: 'setup-email'
      })
    },
    setupDiscord () {
      console.log('link.discord')
      this.setTmp('discord')
      window.location.replace(discordAuthUrl)
    },
    setupGoogle () {
      console.log('link.google')
      this.setTmp('google')
      window.location.replace(googleAuthUrl)
    }
  }
}
</script>

<style scoped lang="scss">
@import "@/styles/variables";
@import "@/styles/breakpoints";

.verification-alert-content {
  display: flex;
  gap: 24px;
  .icon {
    color: var(--verification-alert-icon-color);
    @include media-breakpoint-down(sm) {
      display: none;
    }
  }
  .content {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    gap: 24px;
    .actions {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      column-gap: 32px;
      row-gap: 32px;
      @include media-breakpoint-down(sm) {
        grid-template-columns: repeat(1, 1fr);
        column-gap: 16px;
        row-gap: 16px;
      }
      .btn-action {
        display: flex;
        align-items: center;
        gap: 12px;
      }
    }
    @include media-breakpoint-down(sm) {
      gap: 16px;
    }
  }
}
</style>
