<template>
  <DeviceLayout>
    <template v-slot:mobile>
      <MobileLogin></MobileLogin>
    </template>
    <template v-slot:desktop>
      <Login></Login>
    </template>
  </DeviceLayout>
</template>

<script>
import DeviceLayout from '@/components/layout/DeviceLayout'
import Login from '@/views/login/Login'
import MobileLogin from '@/views/login/MobileLogin'

export default {
  name: 'LoginView',
  components: {
    DeviceLayout,
    Login,
    MobileLogin
  }
}
</script>
