import emojis from '@/assets/emoji/emojis.json'

export function getEmojiImage (name) {
  const { image } = emojis[name]
  return require(`@/assets/emoji/pack/${image}`)
}

export function isEmoji (name) {
  return name in emojis
}

export function findEmojisByName (name, options) {
  const { limit } = options || {}
  return Object.keys(emojis).filter(
    function (n) {
      if (!limit) {
        return n.includes(name)
      } else {
        if (this.count < limit && n.includes(name)) {
          this.count += 1
          return true
        }
        return false
      }
    },
    { count: 0 }
  )
}

export function findEmojisByCategory (category) {
  return Object.keys(emojis).filter(function (n) {
    return emojis[n].category === category
  })
}
