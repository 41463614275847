import Axios from 'axios'
import {
  iamUrl,
  meUrl,
  centrifugoUrl,
  membershipUrl,
  roomUrl
} from '@/settings'
import { JwtService } from '@/service/jwt.service'
import { IamService } from '@/service/iam.service'
import { TmpService } from '@/service/tmp.service'
import { CentrifugoService } from '@/service/centrifugo.service'
import { MeService } from '@/service/me.service'
import { PreloadService } from '@/service/preload.service'
import { MembershipService } from '@/service/membership.service'
import { RoomService } from '@/service/room.service'
import { RoomCentrifugoService } from '@/service/room.centrifugo.service'
import { RoomWsService } from '@/service/room.ws.service'

export class Control {
  constructor (app) {
    this.app = app
    this._tmpService = new TmpService()
    this._jwtService = new JwtService()
    this._iamService = new IamService(app, this.getApi(iamUrl))
    this._meService = new MeService(app, this.getApi(meUrl))
    this._centrifugoService = new CentrifugoService(app, centrifugoUrl)
    this._preloadService = new PreloadService(app)
    this._membershipService = new MembershipService(app, this.getApi(membershipUrl))
    this._roomService = new RoomService(app, this.getApi(roomUrl))
    this._roomCentrifugoService = new RoomCentrifugoService(app)
    this._roomWsService = new RoomWsService(app)
  }

  get roomWs () {
    return this._roomWsService
  }

  get roomCentrifugoService () {
    return this._roomCentrifugoService
  }

  get room () {
    return this._roomService
  }

  get membership () {
    return this._membershipService
  }

  get preload () {
    return this._preloadService
  }

  get centrifugo () {
    return this._centrifugoService
  }

  get tmp () {
    return this._tmpService
  }

  get jwt () {
    return this._jwtService
  }

  get iam () {
    return this._iamService
  }

  get me () {
    return this._meService
  }

  getApi (baseUrl) {
    const api = Axios.create({
      baseURL: baseUrl
    })
    api.interceptors.request.use(async (config) => {
      const { useAccessToken, useRefreshToken } = Object.assign(
        { useAccessToken: false, useRefreshToken: false },
        config
      )
      if (useAccessToken) {
        config.headers['X-Access-Token'] = btoa(`Bearer ${this.jwt.accessToken}`)
      } else if (useRefreshToken) {
        config.headers['X-Refresh-Token'] = btoa(
          `Bearer ${this.jwt.refreshToken}`
        )
      }
      return config
    }, undefined)
    api.interceptors.response.use(undefined, async (err) => {
      if (Axios.isAxiosError(err)) {
        if (err.response && err.response.status === 400) {
          if (err.response.data.error.code === 'access.token.error') {
            console.log('access.token.expired.refresh')
            await this.iam.refresh()
            err.config.__isRetryRequest = true
            return api(err.config)
          } else if (err.response.data.error.code === 'refresh.token.error') {
            console.log('refresh.token.expired.logout')
            await this.iam.logout(window.location.pathname)
            throw new Axios.Cancel()
          }
        }
      }
      throw err
    })
    return api
  }
}
