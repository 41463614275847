<template>
  <div class="gif-waterfall">
    <div class="col" v-for="(col, colIndex) in gifs" :key="colIndex">
      <button
        class="btn gif"
        v-for="gif in col"
        :key="gif.id"
        @click="chooseGif(gif)"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          :viewBox="`0 0 ${gif.preview.width} ${gif.preview.height}`"
        >
          <image width="100%" height="100%" :href="gif.preview.url"></image>
        </svg>
      </button>
    </div>
  </div>
</template>

<script>
import Axios from 'axios'
import { giphyApiKey } from '@/settings'

export default {
  name: 'ChatGifWaterfall',
  props: {
    q: {
      type: String,
      default: ''
    },
    cols: {
      type: Number,
      default: 2
    }
  },
  data () {
    return {
      limit: 50,
      offset: 0,
      gifs: null,
      searchTimer: null
    }
  },
  watch: {
    q () {
      if (this.searchTimer) {
        clearTimeout(this.searchTimer)
        this.searchTimer = null
      }
      this.searchTimer = setTimeout(() => {
        this.render()
      }, 500)
    }
  },
  mounted () {
    this.render()
  },
  methods: {
    async fetchGifs () {
      let response
      if (this.q.length) {
        response = await Axios.get('https://api.giphy.com/v1/gifs/search', {
          params: {
            api_key: giphyApiKey,
            q: this.q,
            limit: this.limit,
            offset: this.offset
          }
        })
      } else {
        return []
      }
      return response.data.data
    },
    async fetch () {
      let gifs = await this.fetchGifs()
      gifs = gifs.map((gif) => {
        return {
          title: gif.title,
          url: gif.images.downsized.url,
          height: parseFloat(gif.images.downsized.height),
          width: parseFloat(gif.images.downsized.width),
          preview: {
            height: parseFloat(gif.images.fixed_width_downsampled.height),
            width: parseFloat(gif.images.fixed_width_downsampled.width),
            url: gif.images.fixed_width_downsampled.url
          }
        }
      })
      return gifs
    },
    resetState () {
      this.gifs = [...Array(this.cols)].map(() => [])
      this.limit = 50
      this.offset = 0
    },
    async renderNext () {
      const gifs = await this.fetch()
      const heights = [...Array(this.cols)].map(() => 0)
      for (const gif of gifs) {
        const lowestCol = heights.indexOf(Math.min(...heights))
        heights[lowestCol] += gif.preview.height
        this.gifs[lowestCol].push(gif)
      }
    },
    async render () {
      this.resetState()
      this.$nextTick(async () => {
        await this.renderNext()
      })
    },
    async next () {
      this.offset += this.limit
      await this.renderNext()
    },
    chooseGif (gif) {
      this.$emit('chosen', gif)
    }
  }
}
</script>

<style scoped lang="scss">
.gif-waterfall {
  display: flex;
  flex-direction: row;
}
.col {
  flex-grow: 1;
  min-width: 0;
  display: flex;
  flex-direction: column;
}
.gif {
  padding: 0;
  svg {
    display: block;
  }
  image {
    display: block;
  }
  position: relative;
  &:after {
    position: absolute;
    content: "";
    height: 100%;
    width: 100%;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    opacity: 0;
    transition: all 0.25s;
    background-color: rgba(0, 0, 0, 0.5);
  }
  &:hover {
    &:after {
      opacity: 1;
    }
  }
}
</style>
