<template>
  <DeviceLayout>
    <template v-slot:mobile>
      <MobileHelp></MobileHelp>
    </template>
    <template v-slot:desktop>
      <Help></Help>
    </template>
  </DeviceLayout>
</template>

<script>
import DeviceLayout from '@/components/layout/DeviceLayout'
import Help from '@/views/help/Help'
import MobileHelp from '@/views/help/MobileHelp'

export default {
  name: 'HelpView',
  components: {
    DeviceLayout,
    Help,
    MobileHelp
  }
}
</script>
