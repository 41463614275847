<template>
  <svg viewBox="0 0 1024 1024" fill="currentColor">
    <path
      d="M256.05 127.99v192.006l192.037 192.131L256.05 703.883v191.756h512.099V703.883L576.112 512.127l192.037-192.131V127.99H256.05z m448.087 599.543V831.72H320.062V726.198l192.037-188.823 192.038 190.158z m-192.038-240.55L320.062 295.87V191.784h384.075v104.414L512.099 486.983z"
    />
  </svg>
</template>

<script>
export default {
  name: 'SvgHourglass'
}
</script>

<style scoped>
svg {
  display: block;
}
</style>
