<template>
  <div class="membership-plans">
    <div class="plan yearly-plan" @click.prevent="choosePlan('yearly')">
      <div class="plan-radio">
        <RadioButton
          :checked="plan === 'yearly'"
          @checked="choosePlan('yearly')"
        ></RadioButton>
      </div>
      <div class="plan-title">
        <div class="plan-name line-height">Yearly subscription</div>
        <div class="plan-desc" v-if="!isMobile">(16% off)</div>
        <div class="plan-desc" v-else>$50 / year (16% off)</div>
      </div>
      <div class="plan-price line-height" v-if="!isMobile">$50 / year</div>
    </div>
    <div class="plan-hr"></div>
    <div
      class="plan half-yearly-plan"
      @click.prevent="choosePlan('half-yearly')"
    >
      <div class="plan-radio">
        <RadioButton
          :checked="plan === 'half-yearly'"
          @checked="choosePlan('half-yearly')"
        ></RadioButton>
      </div>
      <div class="plan-title">
        <div class="plan-name line-height">Half-yearly subscription</div>
        <div class="plan-desc" v-if="!isMobile">(13% off)</div>
        <div class="plan-desc" v-else>$26 / 6 months (13% off)</div>
      </div>
      <div class="plan-price line-height" v-if="!isMobile">$26 / 6 months</div>
    </div>
    <div class="plan-hr"></div>
    <div
      class="plan quarterly-plan"
      @click.prevent="choosePlan('quarterly')"
    >
      <div class="plan-radio">
        <RadioButton
          :checked="plan === 'quarterly'"
          @checked="choosePlan('quarterly')"
        ></RadioButton>
      </div>
      <div class="plan-title">
        <div class="plan-name line-height">Quarterly subscription</div>
        <div class="plan-desc" v-if="!isMobile">(6% off)</div>
        <div class="plan-desc" v-else>$14 / 3 months (6% off)</div>
      </div>
      <div class="plan-price line-height" v-if="!isMobile">$14 / 3 months</div>
    </div>
    <div class="plan-hr"></div>
    <div class="plan monthly-plan" @click.prevent="choosePlan('monthly')">
      <div class="plan-radio">
        <RadioButton
          :checked="plan === 'monthly'"
          @checked="choosePlan('monthly')"
        ></RadioButton>
      </div>
      <div class="plan-title">
        <div class="plan-name line-height">Monthly subscription</div>
        <div class="plan-desc" v-if="isMobile">$5 / month</div>
      </div>
      <div class="plan-price line-height" v-if="!isMobile">$5 / month</div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import RadioButton from '@/components/RadioButton'

export default {
  name: 'MembershipPlans',
  props: {
    plan: String
  },
  components: {
    RadioButton
  },
  computed: {
    ...mapState({
      isMobile: (state) => state.isMobile
    })
  },
  methods: {
    choosePlan (plan) {
      this.$emit('chosen', plan)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/styles/variables";
@import "@/styles/breakpoints";

.membership-plans {
  display: flex;
  flex-direction: column;
  .plan {
    display: flex;
    gap: 24px;
    align-items: center;
    cursor: pointer;
    padding: 22px 0;
    .plan-title {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      gap: 8px;
      .plan-name {
        font-family: "Roboto Mono Medium", sans-serif;
        font-size: 16px;
        color: var(--primary-color);
      }
      .plan-desc {
        font-family: "Roboto Mono Medium", sans-serif;
        font-size: 15px;
        color: var(--muted-color);
      }
    }
    .plan-price {
      font-family: "Roboto Mono Medium", sans-serif;
      font-size: 14px;
      background: var(--membership-plans-badge-bg);
      padding: 4px 16px;
      border-radius: 12px;
      color: white;
      text-align: right;
    }
  }
  .plan-hr {
    display: flex;
    justify-content: center;
    &:after {
      content: " ";
      width: 100%;
      height: 1px;
      background-color: var(--membership-plans-hr-color);
    }
  }
}
</style>
