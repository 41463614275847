<template>
  <div class="room-header">
    <RoomHeaderBrand></RoomHeaderBrand>
  </div>
</template>

<script>
import RoomHeaderBrand from '@/components/room/header/RoomHeaderBrand'

export default {
  name: 'RoomHeader',
  components: {
    RoomHeaderBrand
  }
}
</script>

<style lang="scss" scoped>
@import "@/styles/variables";

.room-header {
  width: 100%;
  height: 100%;
  max-height: $room-header-height;
}
</style>
