<template>
  <div class="room-win" ref="win" :class="{ mini: mini }" :style="winPosPx">
    <div class="room-win-content">
      <slot></slot>
      <div class="room-win-content-actions" v-if="mini">
        <router-link class="btn-secondary-icon-sm btn-action"
                     :to="{ name: 'room', params: { roomSid: roomSid } }"
        >
          <SvgMaximize width="20"></SvgMaximize>
        </router-link>
        <button class="btn-secondary-icon-sm btn-action" @click="close">
          <SvgClose width="20"></SvgClose>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import SvgMaximize from '@/components/svg/SvgMaximize'
import SvgClose from '@/components/svg/SvgClose'

export default {
  name: 'RoomWin',
  components: {
    SvgMaximize,
    SvgClose
  },
  computed: {
    ...mapState('room', {
      mini: (state) => state.mini,
      roomSid: (state) => state.roomSid
    }),
    winPosPx () {
      if (this.mini) {
        return {
          bottom: this.winPos.bottom + 'px',
          right: this.winPos.right + 'px'
        }
      }
      return null
    }
  },
  watch: {
    mini (value) {
      if (value) {
        this.bindBoundaryElement()
      } else {
        this.unbindBoundaryElement()
      }
    }
  },
  data () {
    return {
      isDragging: false,
      leashCoords: null,
      snapping: {
        items: [
          {
            bottom: 0,
            right: 24
          }
        ],
        diff: 24
      },
      boundaryElement: null,
      winPos: {
        bottom: 0,
        right: 24
      }
    }
  },
  mounted () {
    if (this.mini) {
      this.bindBoundaryElement()
    }
  },
  beforeUnmount () {
    this.unbindBoundaryElement()
  },
  methods: {
    close () {
      this.$room.close()
    },
    bindBoundaryElement () {
      this.unbindBoundaryElement()
      this.boundaryElement = this.$refs.win.offsetParent
      this.boundaryElement.addEventListener('mousedown', this.handleMouseDown)
      this.boundaryElement.addEventListener('mousemove', this.handleMouseMove)
      this.boundaryElement.addEventListener('mouseup', this.handleMouseUp)
    },
    unbindBoundaryElement () {
      if (this.boundaryElement) {
        this.boundaryElement.removeEventListener(
          'mousedown',
          this.handleMouseDown
        )
        this.boundaryElement.removeEventListener(
          'mousemove',
          this.handleMouseMove
        )
        this.boundaryElement.removeEventListener('mouseup', this.handleMouseUp)
        this.boundaryElement = null
      }
    },
    clamp (num, lower = 0, upper) {
      return num < lower ? lower : num > upper ? upper : num
    },
    clampInsideBoundary (x, y) {
      const boundaryPos = this.boundaryElement.getBoundingClientRect()
      const maxWidth = boundaryPos.width - this.$refs.win.clientWidth
      const maxHeight = boundaryPos.height - this.$refs.win.clientHeight
      const xx = this.clamp(x - boundaryPos.x, 0, maxWidth)
      const yy = this.clamp(y - boundaryPos.y, 0, maxHeight)
      return {
        x: xx,
        y: yy,
        _x: maxWidth - xx,
        _y: maxHeight - yy
      }
    },
    findPositionInsideBound (event) {
      const x = event.clientX - this.leashCoords.left
      const y = event.clientY - this.leashCoords.top
      return this.clampInsideBoundary(x, y)
    },
    handleMouseDown (event) {
      if (event.target === this.$refs.win) {
        const windowPos = this.$refs.win.getBoundingClientRect()
        this.leashCoords = {
          left: event.clientX - windowPos.x,
          top: event.clientY - windowPos.y
        }
        this.isDragging = true
      }
    },
    snap (pos) {
      let { _x, _y } = pos
      for (const snap of this.snapping.items) {
        if (
          _x < snap.right + this.snapping.diff &&
          _x > snap.right - this.snapping.diff
        ) {
          _x = snap.right
        }
        if (
          _y < snap.bottom + this.snapping.diff &&
          _y > snap.bottom - this.snapping.diff
        ) {
          _y = snap.bottom
        }
      }
      return { _x, _y }
    },
    handleMouseMove (event) {
      if (this.isDragging) {
        const { _x: right, _y: bottom } = this.snap(
          this.findPositionInsideBound(event)
        )
        this.winPos = {
          right,
          bottom
        }
      }
    },
    handleMouseUp () {
      this.isDragging = false
    }
  }
}
</script>

<style scoped lang="scss">
@import "@/styles/breakpoints";

.room-win {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  .room-win-content {
    position: relative;
    width: 100%;
    height: 100%;
  }
  &.mini {
    cursor: move;
    top: unset;
    left: unset;
    width: 336px;
    height: 189px;
    @include media-breakpoint-up(xxxl) {
      width: 400px;
      height: 225px;
    }
    .room-win-content {
      user-select: none;
      touch-action: none;
      pointer-events: none;
      .room-win-content-actions {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 1;
        padding: 16px;
        display: flex;
        justify-content: flex-end;
        gap: 12px;
        opacity: 0;
        .btn-action {
          pointer-events: auto;
        }
      }
    }
    &:hover {
      .room-win-content {
        .room-win-content-actions {
          -webkit-transition: 0.3s;
          transition: opacity 0.3s;
          opacity: 1;
        }
      }
    }
  }
}
</style>
