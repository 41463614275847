<template>
  <div class="upgrade-membership-modal">
    <template v-if="step === 'plan'">
      <ModalHeader class="header" :modal="modal">
        <template v-slot:title>Choose your Plan</template>
      </ModalHeader>
      <form class="form">
        <div class="content">
          <MembershipPlans :plan="plan" @chosen="choosePlan"></MembershipPlans>
        </div>
        <ModalFooter class="footer">
          <template v-slot:content>
            <button type="button" class="btn-primary-sm btn-continue" @click="goToPurchase">
              CONTINUE
            </button>
          </template>
        </ModalFooter>
      </form>
    </template>
    <template v-else-if="step === 'purchase'">
      <ModalHeader class="header" :modal="modal">
        <template v-slot:title>Purchase</template>
      </ModalHeader>
      <form class="form">
        <div class="content content-purchase">
          <div class="description line-height">
            Click the PayPal button below to continue checkout.
          </div>
          <PaypalButtonsSub
            @ready="stopLoading"
            @done="goToComplete"
            :plan="plan"
          ></PaypalButtonsSub>
        </div>
      </form>
    </template>
    <template v-else-if="step === 'completed'">
      <ModalHeader class="header" :modal="modal">
        <template v-slot:title>Purchase completed</template>
      </ModalHeader>
      <form class="form">
        <div class="content content-completed">
          <div class="description line-height">
            Thank you for your support! You bearcat membership will be upgraded once payment is processed.
          </div>
        </div>
      </form>
      <ModalFooter class="footer">
        <template v-slot:content>
          <button type="button" class="btn-primary-sm btn-ok" @click="close">
            OK
          </button>
        </template>
      </ModalFooter>
    </template>
    <div class="loading-wrap" v-if="loading">
      <Preloader class="loading" :shown="true"></Preloader>
    </div>
  </div>
</template>

<script>
import ModalHeader from '@/components/modals/ModalHeader'
import ModalFooter from '@/components/modals/ModalFooter'
import MembershipPlans from '@/components/modals/MembershipPlans'
import PaypalButtonsSub from '@/components/paypal/PaypalButtonsSub'
import Preloader from '@/components/Preloader'

export default {
  name: 'UpgradeMembershipModal',
  components: {
    ModalHeader,
    ModalFooter,
    MembershipPlans,
    PaypalButtonsSub,
    Preloader
  },
  props: {
    modal: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      loading: false,
      plan: 'yearly',
      step: 'plan'
    }
  },
  methods: {
    close () {
      this.modal.close()
    },
    choosePlan (plan) {
      this.plan = plan
    },
    goToPurchase () {
      this.startLoading()
      this.step = 'purchase'
    },
    goToComplete () {
      this.stopLoading()
      this.step = 'completed'
    },
    stopLoading () {
      this.loading = false
    },
    startLoading () {
      this.loading = true
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/styles/variables";
@import "@/styles/breakpoints";

.upgrade-membership-modal {
  position: relative;
  width: 100vw;
  display: flex;
  flex-direction: column;
  .header {
    --modal-header-padding: 32px;
  }
  .footer {
    --modal-footer-padding: 32px;
    .btn-continue {
      max-width: 110px;
    }
    .btn-ok {
      max-width: 110px;
    }
  }
  .form {
    display: flex;
    flex-direction: column;
    .content {
      padding: 0 32px;
    }
  }
  @include media-breakpoint-up(sm) {
    max-width: 550px;
  }
  @include media-breakpoint-down(sm) {
    .header {
      --modal-header-padding: 24px;
    }
    .footer {
      --modal-footer-padding: 24px;
    }
    .form {
      .content {
        padding: 0 24px;
      };
    }
  }
}

.content-purchase {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 0 32px 32px !important;
  .description {
    font-family: "Roboto Medium", sans-serif;
  }
  @include media-breakpoint-down(sm) {
    padding: 0 24px 24px !important;
  }
}

.content-completed {
  display: flex;
  flex-direction: column;
  gap: 16px;
  .description {
    font-family: "Roboto Medium", sans-serif;
  }
}

.loading-wrap {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: var(--modal-bg);
  z-index: 1100;
}

</style>
