<template>
  <div class="mobile-subscriptions">
    <div class="title">
      SUBSCRIPTIONS
    </div>
    <div class="subscriptions">
      <ContentLayout
        v-for="subscription in subscriptions"
        :key="subscription.id"
      >
        <template v-slot:content>
          <MobileSubscriptionCard
            :subscription="subscription"
          ></MobileSubscriptionCard>
        </template>
      </ContentLayout>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import ContentLayout from '@/components/layout/ContentLayout'
import MobileSubscriptionCard from '@/views/membership/MobileSubscriptionCard'

export default {
  name: 'MobileMyRooms',
  components: {
    ContentLayout,
    MobileSubscriptionCard
  },
  computed: {
    ...mapState('me', {
      subscriptions: (state) => state.subscriptions
    })
  }
}
</script>

<style scoped lang="scss">
@import "@/styles/variables";
@import "@/styles/breakpoints";

.mobile-subscriptions {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.title {
  font-size: 13px;
  color: var(--muted-color)
}
.subscriptions {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
</style>
