<template>
  <div class="footer-release-control">
    <ProcessButton
      class="btn-secondary-dk-sm btn-release"
      :disabled="processing"
      :processing="processing"
      @submit="releaseControl"
    >
      RELEASE
    </ProcessButton>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import ProcessButton from '@/components/ProcessButton'

export default {
  name: 'FooterReleaseControl',
  components: {
    ProcessButton
  },
  computed: {
    ...mapState('room', {
      roomId: (state) => state.roomId
    })
  },
  data () {
    return {
      processing: false
    }
  },
  methods: {
    async releaseControl () {
      this.processing = true
      try {
        await this.$service.room.releaseControl(this.roomId)
      } finally {
        this.processing = false
      }
      this.$store.commit('room/update', {
        hostId: null,
        host: null
      })
    }
  }
}
</script>

<style scoped lang="scss">
@import "@/styles/variables";

.footer-release-control {
  .btn-release {
    width: 110px;
  }
}
</style>
