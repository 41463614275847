<template>
  <div class="login-form-welcome">
    <div class="welcome-title">
      <div class="title">Log in</div>
      <div class="sub-title">Welcome to Bearcat!</div>
    </div>
    <form class="welcome-form">
      <input
        v-focus
        v-model="email"
        class="input-lt"
        type="email"
        placeholder="Enter your email..."
      />
      <ProcessButton type="submit" class="btn-primary" @submit="sendOtp" :processing="processing">
        LOG IN
      </ProcessButton>
    </form>
    <LoginLegal></LoginLegal>
    <LoginThirdParty></LoginThirdParty>
  </div>
</template>

<script>
import LoginThirdParty from '@/components/login/LoginThirdParty'
import ProcessButton from '@/components/ProcessButton'
import LoginLegal from '@/components/login/LoginLegal'

export default {
  name: 'LoginFormWelcome',
  components: {
    LoginThirdParty,
    ProcessButton,
    LoginLegal
  },
  data () {
    return {
      email: '',
      processing: false
    }
  },
  methods: {
    async sendOtp () {
      this.processing = true
      let data
      try {
        data = await this.$service.iam.sendEmailOtp(this.email)
      } finally {
        this.processing = false
      }
      const { otpId } = data
      this.$emit('done', { otpId, email: this.email })
    }
  }
}
</script>

<style scoped lang="scss">
@import "@/styles/variables";
@import "@/styles/breakpoints";

.login-form-welcome {
  display: flex;
  flex-direction: column;
  gap: 40px;
}
.welcome-title {
  display: flex;
  flex-direction: column;
  gap: 16px;
  .title {
    font-family: "Roboto Medium", sans-serif;
    font-size: 26px;
    @include media-breakpoint-down(sm) {
      font-size: 24px;
    }
  }
  .sub-title {
    color: var(--muted-color);
  }
}
.welcome-form {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
</style>
