<template>
  <div class="login-form">
    <LoginFormWelcome v-if="step === 'welcome'"
                      @done="goToCodeChallenge"
    ></LoginFormWelcome>
    <LoginFormCode v-else-if="step === 'code'"
                   :otp-id="otpId"
                   :email="email"
                   @reset="goToWelcomeChallenge"
    ></LoginFormCode>
  </div>
</template>

<script>
import LoginFormWelcome from '@/views/login/LoginFormWelcome'
import LoginFormCode from '@/views/login/LoginFormCode'

export default {
  name: 'LoginForm',
  components: {
    LoginFormWelcome,
    LoginFormCode
  },
  data () {
    return {
      step: 'welcome',
      otpId: null,
      email: null
    }
  },
  methods: {
    goToCodeChallenge ({ otpId, email }) {
      this.otpId = otpId
      this.email = email
      this.step = 'code'
    },
    goToWelcomeChallenge () {
      this.otpId = null
      this.email = null
      this.step = 'welcome'
    }
  }
}
</script>

<style scoped lang="scss">
@import "@/styles/variables";
</style>
