<template>
  <Fullscreen>
    <div class="online" ref="container">
      <RoomHeader v-if="showRoomHeader"></RoomHeader>
      <div class="content" ref="content">
        <div class="browser" ref="browser">
          <LiveStream></LiveStream>
        </div>
      </div>
      <OnlineFooter v-if="showRoomFooter"></OnlineFooter>
    </div>
  </Fullscreen>
</template>

<script>
import { mapState } from 'vuex'
import LiveStream from '@/components/room/online/LiveStream'
import RoomHeader from '@/components/room/header/RoomHeader'
import OnlineFooter from '@/components/room/online/footer/OnlineFooter'
import Fullscreen from '@/components/room/fullscreen/Fullscreen'

export default {
  name: 'Online',
  components: {
    LiveStream,
    RoomHeader,
    OnlineFooter,
    Fullscreen
  },
  computed: {
    ...mapState('room', {
      mini: (state) => state.mini,
      fullscreen: (state) => state.fullscreen,
      theater: (state) => state.theater
    }),
    showRoomHeader () {
      return !this.mini && !this.fullscreen && !this.theater
    },
    showRoomFooter () {
      return !this.mini && !this.fullscreen
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/styles/variables";

.online {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.content {
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  .browser {
    width: 100%;
    height: 100%;
  }
}
</style>
