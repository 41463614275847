<template>
  <div class="simple-toast line-height">
    {{ toast.data.context.text }}
  </div>
</template>

<script>
export default {
  name: 'SimpleToast',
  props: {
    toast: {
      type: Object,
      required: true
    }
  }
}
</script>

<style scoped lang="scss">
.simple-toast {
  padding: 16px 24px;
}
</style>
