<template>
  <div class="oauth2-view">
    <div class="wrapper">
      <Preprocessor class="spinner"></Preprocessor>
      <div class="text" v-if="$route.query.provider === 'google'">Fetching Google-ID</div>
      <div class="text" v-if="$route.query.provider === 'discord'">Fetching Discord-ID</div>
    </div>
  </div>
</template>

<script>
import Preprocessor from '@/components/Preprocessor'

export default {
  name: 'OAuth2View',
  components: {
    Preprocessor
  },
  async mounted () {
    const { provider, code } = this.$route.query
    if (!['google', 'discord'].includes(provider)) {
      console.error('unknown.provider', provider)
      this.$toast.somethingWentWrong()
      if (this.$service.jwt.has()) {
        await this.$router.push({ name: 'home' })
      } else {
        await this.$router.push({ name: 'login' })
      }
      return
    }
    const linkProvider = this.$service.tmp.getOauth2LinkProvider()
    if (this.$service.jwt.has() && linkProvider === provider) {
      await this.link(provider, code)
    } else {
      await this.login(provider, code)
    }
  },
  methods: {
    async linkRedirect () {
      const next = this.$service.tmp.getOauth2Next()
      if (next) {
        this.$service.tmp.resetOauth2()
        await this.$router.push(next)
      } else {
        this.$service.tmp.resetOauth2()
        await this.$router.push({ name: 'home' })
      }
    },
    async link (provider, code) {
      try {
        if (provider === 'google') {
          await this.$service.iam.linkGoogle(code)
        } else if (provider === 'discord') {
          await this.$service.iam.linkDiscord(code)
        }
      } catch (err) {
        console.error(err)
      } finally {
        await this.linkRedirect()
      }
    },
    async login (provider, code) {
      try {
        if (provider === 'google') {
          await this.$service.iam.loginGoogle(code)
        } else if (provider === 'discord') {
          await this.$service.iam.loginDiscord(code)
        }
      } catch (err) {
        console.error(err)
        this.$service.tmp.resetOauth2()
        await this.$router.push({ name: 'login' })
        return
      }
      const next = this.$service.tmp.getOauth2Next()
      if (next) {
        this.$service.tmp.resetOauth2()
        await this.$router.push(next)
      } else {
        this.$service.tmp.resetOauth2()
        await this.$router.push({ name: 'home' })
      }
    }
  }
}
</script>

<style scoped lang="scss">
@import "@/styles/variables";

.oauth2-view {
  width: 100vw;
  height: 100svh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 32px;
  .spinner {
    --preprocessor-size: 50px;
  }
  .text {
    color: var(--muted-color);
    font-family: "Roboto Mono Medium", sans-serif;
    font-size: 14px;
  }
}
</style>
