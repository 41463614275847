<template>
  <Popup>
    <slot></slot>
    <template v-slot:content="{ hide }">
      <MemberPopupContent @close="hide" :user-id="userId" :name="name" :avatar="avatar"></MemberPopupContent>
    </template>
  </Popup>
</template>

<script>
import Popup from '@/components/Popup'
import MemberPopupContent from '@/components/room/members/MemberPopupContent'

export default {
  name: 'MemberPopup',
  props: {
    userId: {
      type: String,
      default: null
    },
    name: {
      type: String,
      default: null
    },
    avatar: {
      type: String,
      default: null
    }
  },
  components: {
    Popup,
    MemberPopupContent
  }
}
</script>

<style scoped lang="scss">
@import "@/styles/variables";
</style>
