<template>
  <router-link class="room-card"
               :to="{ name: 'room', params: { roomSid: room.sid } }"
  >
    <div class="icon">
      <SvgMonitor width="32"></SvgMonitor>
    </div>
    <div class="info">
      <div class="name clamp-1">{{ room.name }}</div>
      <div class="badges">
        <div class="stats">
          {{ room.memberCount }} watching
          <div
            class="dot dot-online"
            v-if="
                    room.status === 'ONLINE' ||
                    room.status === 'QUEUING' ||
                    room.status === 'PENDING'
                  "
          ></div>
          <div class="dot dot-offline" v-else></div>
        </div>
      </div>
    </div>
    <div class="hop-in">HOP IN</div>
  </router-link>
</template>

<script>
import SvgMonitor from '@/components/svg/SvgMonitor'

export default {
  name: 'RoomCard',
  components: {
    SvgMonitor
  },
  props: {
    room: Object
  }
}
</script>

<style scoped lang="scss">
@import "@/styles/variables";

.room-card {
  color: var(--primary-color);
  text-decoration: none;
  min-width: 430px;
  background: var(--content-layout-bg);
  user-select: none;
  padding: 22px 32px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 24px;
  -webkit-transition: background-color 100ms linear;
  -ms-transition: background-color 100ms linear;
  transition: background-color 100ms linear;

  &:hover {
    background-color: var(--hover-bg);
    .hop-in {
      opacity: 1;
    }
  }
  &:active {
    background-color: var(--click-bg);
  }

  .info {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    gap: 8px;
    .name {
      font-size: 16px;
      font-family: "Roboto Mono Medium", sans-serif;
    }
    .badges {
      display: flex;
      align-items: center;
      .stats {
        font-size: 15px;
        display: flex;
        align-items: center;
        gap: 8px;
        .dot {
          height: 8px;
          width: 8px;
          border-radius: 50%;
          display: inline-block;
        }
        .dot-online {
          background-color: var(--status-green);
        }
        .dot-offline {
          background-color: var(--status-default);
        }
      }
    }
  }
  .hop-in {
    opacity: 0;
    -webkit-transition: 100ms;
    transition: opacity 100ms;
    font-size: 13px;
  }
}
</style>
