import Axios from 'axios'
import store from '@/store'

class MeApi {
  constructor (api) {
    this.api = api
  }

  async getMe () {
    return await this.api.get('/', { useAccessToken: true })
  }
}

export class MeServiceError extends Error {
  constructor (code) {
    super(code)
    this.code = code
  }
}

export class MeService {
  constructor (app, api) {
    this.app = app
    this.api = new MeApi(api)
  }

  async fetchMe () {
    let response
    try {
      response = await this.api.getMe()
    } catch (err) {
      if (Axios.isCancel(err)) {
        throw new MeServiceError('request.canceled')
      }
      throw err
    }
    const data = {
      avatar: response.data.avatar,
      name: response.data.name,
      censoredEmail: response.data.censored_email,
      censoredGoogle: response.data.censored_google,
      censoredDiscord: response.data.censored_discord,
      role: response.data.role,
      userId: response.data.id,
      membership: response.data.membership,
      membershipExpiresAt: response.data.membership_expires_at
    }
    const rooms = []
    for (const room of response.data.rooms) {
      rooms.push({
        id: room.id,
        sid: room.sid,
        memberCount: room.member_count,
        name: room.name,
        status: room.status
      })
    }
    data.rooms = rooms
    const subscriptions = []
    for (const subscription of response.data.subscriptions) {
      subscriptions.push({
        id: subscription.id,
        paymentSystem: subscription.payment_system,
        sourceId: subscription.source_id,
        plan: subscription.plan,
        planId: subscription.plan_id,
        status: subscription.status,
        paymentFailed: subscription.payment_failed
      })
    }
    data.subscriptions = subscriptions
    store.commit('me/update', data)
  }
}
