<template>
  <div class="login-legal line-height">
    By <slot name="action">logging</slot>, you agree to our
    <router-link class="link" :to="{ name: 'terms-of-service' }">Terms of Service</router-link>
    and
    <router-link class="link" :to="{ name: 'privacy-policy' }">Privacy Policy</router-link>
    .
  </div>
</template>

<script>
export default {
  name: 'LoginLegal'
}
</script>

<style scoped lang="scss">
@import "@/styles/variables";

.login-legal {
  color: var(--muted-color);
  font-size: 15px;
}
</style>
