<template>
  <SidebarLayout>
    <template v-slot:content>
      <div class="account">
        <Profile></Profile>
        <LoginOptions></LoginOptions>
        <Logout></Logout>
        <AccountRemoval></AccountRemoval>
      </div>
    </template>
  </SidebarLayout>
</template>

<script>
import SidebarLayout from '@/components/layout/SidebarLayout'
import Profile from '@/views/account/Profile'
import LoginOptions from '@/views/account/LoginOptions'
import AccountRemoval from '@/views/account/AccountRemoval'
import Logout from '@/views/account/Logout'

export default {
  name: 'Account',
  components: {
    SidebarLayout,
    Profile,
    LoginOptions,
    Logout,
    AccountRemoval
  }
}
</script>

<style scoped lang="scss">
@import "@/styles/variables";

.account {
  padding-bottom: $content-padding;
  display: flex;
  flex-direction: column;
  gap: 32px;
}
</style>
