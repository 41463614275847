<template>
  <div
    class="host-wrap"
    ref="container"
  >
    <slot></slot>
    <div v-if="!isMobile"
         class="host-wrap-mock"
         ref="mock"
         tabindex="0"
         @keydown="onKeyDown"
         @keyup="onKeyUp"
         @mousemove="onMouseMove"
         @mousedown="onMouseDown"
         @mouseup="onMouseUp"
         @wheel="onMouseWheel"
         @contextmenu="onRightClick"
    ></div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { isNil } from '@/utils/common'

export default {
  name: 'HostWrap',
  computed: {
    ...mapState({
      isMobile: (state) => state.isMobile
    }),
    ...mapState('me', {
      userId: (state) => state.userId
    }),
    ...mapState('room', {
      lock: (state) => state.lock,
      hostId: (state) => state.hostId,
      browserWidth: (state) => state.browserWidth,
      browserHeight: (state) => state.browserHeight
    }),
    imHost () {
      return (
        !isNil(this.hostId) &&
        !isNil(this.userId) &&
        this.userId === this.hostId
      )
    }
  },
  data () {
    return {
      scrollBuff: 0,
      resizeObserver: null
    }
  },
  mounted () {
    this.initResizeObserver()
  },
  beforeUnmount () {
    this.destroyResizeObserver()
  },
  methods: {
    initResizeObserver () {
      this.destroyResizeObserver()
      const resizeObserver = new ResizeObserver(() => {
        if (this.$refs.container && this.$refs.mock) {
          const containerHeight = this.$refs.container.clientHeight
          const containerWidth = this.$refs.container.clientWidth

          const calcWidth = containerHeight * 16 / 9
          if (calcWidth < containerWidth) {
            this.$refs.mock.style.width = `${calcWidth}px`
            this.$refs.mock.style.height = `${containerHeight}px`
          } else {
            this.$refs.mock.style.width = `${containerWidth}px`
            this.$refs.mock.style.height = `${containerWidth * 9 / 16}px`
          }
        }
      })
      resizeObserver.observe(this.$refs.container)
    },
    destroyResizeObserver () {
      if (this.resizeObserver) {
        this.resizeObserver.disconnect()
        this.resizeObserver = null
      }
    },
    onRightClick (e) {
      e.preventDefault()
    },
    async onKeyDown (e) {
      e.preventDefault()
      const { key, ctrlKey, shiftKey } = e
      // paste text
      if (ctrlKey && key === 'v') {
        if (this.imHost && !this.lock) {
          if (navigator.clipboard && navigator.clipboard.readText) {
            return navigator.clipboard
              .readText()
              .then((text) => this.emitEvent({ text }, 'PASTE_TEXT'))
          } else {
            console.log('no.access.to.clipboard')
            // const { ok, values: { text } } = await this.$confirm({
            //   title: 'Paste Text',
            //   inputs: [
            //     {
            //       label: 'Please enter the text you want to paste here:',
            //       value: '',
            //       type: 'text',
            //       name: 'text',
            //       id: 'text',
            //     }
            //   ],
            // })
            // if (ok) {
            //   this.emitEvent({ text }, 'PASTE_TEXT')
            // }
          }
        }
      }
      this.emitEvent({ key, ctrlKey, shiftKey }, 'KEY_DOWN')
    },
    onKeyUp (e) {
      e.preventDefault()
      const { key, ctrlKey, shiftKey } = e
      if (ctrlKey && key === 'v') {
        return
      }
      this.emitEvent({ key, ctrlKey, shiftKey }, 'KEY_UP')
    },
    onMouseMove (e) {
      const { x, y } = this.calculatePos(e)
      this.emitEvent({ x, y }, 'MOUSE_MOVE')
    },
    onMouseDown (e) {
      const { button } = e
      const { x, y } = this.calculatePos(e)
      this.emitEvent({ x, y, button: button + 1 }, 'MOUSE_DOWN')
    },
    onMouseUp (e) {
      const { button } = e
      const { x, y } = this.calculatePos(e)
      this.emitEvent({ x, y, button: button + 1 }, 'MOUSE_UP')
    },
    onMouseWheel (e) {
      e.preventDefault()
      const { deltaY } = e
      this.scrollBuff += deltaY
      if (this.scrollBuff > 100 || this.scrollBuff < -100) {
        this.emitEvent({ scrollUp: deltaY > 0 }, 'MOUSE_SCROLL')
        this.scrollBuff = 0
      }
    },
    calculatePos (e) {
      const x = this.calculateXPos(e)
      const y = this.calculateYPos(e)
      return { x, y }
    },
    calculateXPos (e) {
      const { clientX, srcElement: elem } = e
      const rect = elem.getBoundingClientRect()
      const xPos = clientX - rect.left
      return Math.round(this.browserWidth * (xPos / elem.clientWidth))
    },
    calculateYPos (e) {
      const { clientY, srcElement: elem } = e
      const rect = elem.getBoundingClientRect()
      const yPos = clientY - rect.top
      return Math.round(this.browserHeight * (yPos / elem.clientHeight))
    },
    emitEvent (a, n) {
      if (this.imHost && !this.lock) {
        this.$service.roomWs.publishCmd(a, n)
      }
    }
  }
}
</script>

<style scoped lang="scss">
.host-wrap {
  width: 100%;
  height: 100%;
  position: relative;
  .host-wrap-mock {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
  }
}
</style>
