<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><polyline points="18 15 12 9 6 15"></polyline></svg>
</template>

<script>
export default {
  name: 'SvgChevronUp'
}
</script>

<style scoped>
svg {
  display: block;
}
</style>
