<template>
  <ContentLayout>
    <template v-slot:content>
      <div class="profile">
        <div class="profile-content">
          <div class="btn-base avatar-wrap">
            <Avatar :class="{
            'avatar-md': isMobile,
            'avatar-lg': !isMobile
          }" :avatar></Avatar>
            <div class="avatar-update-overlay" @click="updateAvatar">
              <SvgEdit width="15"></SvgEdit>
            </div>
          </div>
          <div class="info">
            <div class="name clamp-1">{{ name }}</div>
            <div class="membership">
              <template v-if="role === 'GUEST'">
                Guest
              </template>
              <template v-else-if="membership">
                Premium
              </template>
              <template v-else>
                Basic
              </template>
            </div>
          </div>
          <button type="button" class="btn-primary-sm btn-change" @click="changeName" v-if="!isMobile">
            CHANGE
          </button>
        </div>
        <button type="button" class="btn-primary-sm btn-change" v-if="isMobile" @click="changeName">
          CHANGE
        </button>
      </div>
    </template>
  </ContentLayout>
</template>

<script>
import { mapState } from 'vuex'
import ContentLayout from '@/components/layout/ContentLayout'
import Avatar from '@/components/Avatar'
import SvgEdit from '@/components/svg/SvgEdit'

export default {
  name: 'Profile',
  components: {
    ContentLayout,
    Avatar,
    SvgEdit
  },
  computed: {
    ...mapState({
      isMobile: state => state.isMobile
    }),
    ...mapState('me', {
      avatar: (state) => state.avatar,
      name: (state) => state.name,
      membership: (state) => state.membership,
      role: (state) => state.role
    })
  },
  methods: {
    changeName () {
      this.$modal.open({
        type: 'change-name'
      })
    },
    updateAvatar () {
      console.log('update.avatar')
      this.$modal.open({
        type: 'upload-picture',
        width: 350,
        height: 350,
        upload: async ({ image, width, height, left, top }) => {
          await this.$service.iam.uploadAvatar({ image, width, height, left, top })
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
@import "@/styles/variables";

.profile {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.profile-content {
  display: flex;
  align-items: center;
  gap: 24px;
  .avatar-wrap {
    position: relative;
    border-radius: 8px;
    overflow: hidden;
    .avatar-update-overlay {
      cursor: pointer;
      position: absolute;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: rgba(20, 20, 20, 0.4);
      top: 0;
      left: 0;
      opacity: 0;
      transition: all 0.25s;
    }
    &:hover {
      .avatar-update-overlay{
        opacity: 1;
      }
    }
  }
  .info {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    gap: 6px;
    .name {
      font-family: "Roboto Medium", sans-serif;
      font-size: 18px;
    }
    .membership {
      color: var(--muted-color);
      font-size: 16px;
    }
  }
  .btn-change {
    max-width: 110px;
  }
}
</style>
