<template>
  <div id="paypal-sub-buttons"></div>
</template>

<script>
import { loadScript } from '@paypal/paypal-js'
import {
  paypalClientId,
  paypalMonthlyPlanId,
  paypalYearlyPlanId,
  paypalQuarterlyPlanId,
  paypalHalfYearlyPlanId
} from '@/settings'

export default {
  name: 'PaypalButtonsSub',
  props: {
    plan: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      processing: false
    }
  },
  beforeCreate: function () {
    loadScript({
      'client-id': paypalClientId,
      vault: 'true',
      intent: 'subscription'
    }).then((paypal) => {
      paypal
        .Buttons({
          createSubscription: this.createSubscription,
          onApprove: this.onApprove,
          onInit: this.onInit,
          style: {
            color: 'blue',
            shape: 'pill',
            label: 'pay',
            height: 45
          }
        })
        .render('#paypal-sub-buttons')
    })
  },
  methods: {
    getPaypalPlanId () {
      let paypalPlanId
      if (this.plan === 'monthly') {
        paypalPlanId = paypalMonthlyPlanId
      } else if (this.plan === 'quarterly') {
        paypalPlanId = paypalQuarterlyPlanId
      } else if (this.plan === 'half-yearly') {
        paypalPlanId = paypalHalfYearlyPlanId
      } else if (this.plan === 'yearly') {
        paypalPlanId = paypalYearlyPlanId
      } else {
        throw new Error(`Unknown planId: ${this.plan}`)
      }
      console.log('subscription.plan', this.plan, paypalPlanId)
      return paypalPlanId
    },
    async createSubscription (data, actions) {
      const sourceId = await actions.subscription.create({
        plan_id: this.getPaypalPlanId()
      })
      await this.$service.membership.createPaypalSubscription(sourceId)
      console.log('subscription.created', {
        data,
        sourceId
      })
      return sourceId
    },
    onApprove (data) {
      console.log('subscription.approved', data)
      this.$nextTick(() => {
        this.$emit('done')
      })
    },
    onInit () {
      this.$emit('ready')
    }
  }
}
</script>

<style scoped lang="scss"></style>
