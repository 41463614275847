<template>
  <div class="chat-typing" v-if="typings.length">
    <span class="clamp-1">{{ text }}</span>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'ChatTypingIndicator',
  computed: {
    ...mapState('me', {
      userId: (state) => state.userId
    })
  },
  data () {
    return {
      text: null,
      typings: []
    }
  },
  methods: {
    del ({ userId }) {
      this.typings = this.typings.filter((t) => {
        const b = t.userId !== userId
        if (!b) {
          clearTimeout(t.timer)
        }
        return b
      })
      this.formatText()
    },
    add ({ userId, name }) {
      if (userId === this.userId) {
        return
      }
      this.typings = this.typings.filter((t) => {
        const b = t.userId !== userId
        if (!b) {
          clearTimeout(t.timer)
        }
        return b
      })
      this.typings.push({
        userId,
        name,
        timer: setTimeout(() => {
          this.typings = this.typings.filter((t) => t.userId !== userId)
        }, 4000)
      })
      this.formatText()
    },
    formatText () {
      if (this.typings.length) {
        const names = this.typings.map((t) => t.name || 'Member')
        this.text = names.join(', ')
        this.text += names.length > 1 ? ' are typing...' : ' is typing...'
      } else {
        this.text = null
      }
    }
  }
}
</script>

<style scoped lang="scss">
@import "@/styles/variables";

.chat-typing {
  width: 100%;
  height: 24px;
  font-size: 13px;
  background-color: var(--room-aside-alt-bg);
  color: var(--muted-color);
  font-style: italic;
  padding: 0 8px;
  display: flex;
  align-items: center;
}
</style>
