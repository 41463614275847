import EventEmitter from 'events'

export class WsClient extends EventEmitter {
  constructor (options) {
    super()
    const { autoReconnect, wsUrl, authParams, getToken } = Object.assign(
      this.defaultOptions,
      options || {}
    )
    this.wsUrl = wsUrl
    this.authParams = authParams
    this.getToken = getToken
    this.socket = null
    this.authorized = false
    this.defaultAutoReconnect = autoReconnect
    this.autoReconnectTimer = null
  }

  get defaultOptions () {
    return {
      autoReconnect: true
    }
  }

  get connected () {
    if (!this.socket) {
      return false
    }
    return this.socket.readyState === 1
  }

  disconnect () {
    this.clearAutoReconnectTimer()
    this.autoReconnect = false
    if (this.socket) {
      this.socket.close()
      this.socket = null
    }
    this.authorized = false
  }

  connect (authParams) {
    this.disconnect()
    if (authParams) {
      this.authParams = authParams
    }
    this.autoReconnect = this.defaultAutoReconnect
    this.socket = new WebSocket(this.wsUrl)
    this.socket.onerror = () => {
      console.log('connection.error', this.wsUrl)
    }
    this.socket.onclose = () => {
      console.log('disconnected', this.wsUrl)
      this.emit('disconnected')
      this.authorized = false
      if (this.autoReconnect) {
        this.reconnectAfterPause()
      }
    }
    this.socket.onopen = () => {
      console.log('connected', this.wsUrl)
      this.emit('connected')
      this.authorized = false
      this.send({
        t: 'authorization',
        d: { access_token: this.getToken(), ...(this.authParams || {}) }
      })
    }
    this.socket.onmessage = (payload) => {
      this.handleMessage(JSON.parse(payload.data))
    }
  }

  clearAutoReconnectTimer () {
    if (this.autoReconnectTimer) {
      clearTimeout(this.autoReconnectTimer)
      this.autoReconnectTimer = null
    }
  }

  reconnectAfterPause () {
    this.clearAutoReconnectTimer()
    console.log('reconnect.pending', this.wsUrl)
    this.autoReconnectTimer = setTimeout(() => {
      this.connect()
    }, 1000)
  }

  send (msg) {
    if (!this.connected) {
      return
    }
    try {
      this.socket.send(JSON.stringify(msg))
    } catch (e) {
      console.error(e)
    }
  }

  handleMessage (msg) {
    const { t, d } = msg
    switch (t) {
      case 'authorized':
        console.log('authorized', this.wsUrl)
        this.authorized = true
        this.emit('authorized', d)
        break
      case 'error':
        this.handleError(d)
        break
      default:
        this.handleAuthorizedMessage(msg)
    }
  }

  handleAuthorizedMessage (msg) {
    const { t, d } = msg
    this.emit(t, d)
  }

  handleError (d) {
    const { code } = d
    if (code === 'unauthorized') {
      console.error('unauthorized', this.wsUrl)
      this.authorized = false
      if (this.autoReconnect) {
        this.reconnectAfterPause()
      }
    } else {
      console.error(`${this.wsUrl} error: ${code}`)
    }
  }
}
