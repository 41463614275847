<template>
  <svg viewBox="0 0 24 24" fill="currentColor"><path d="M19 10h-2V8h2v2zm0 3h-2v-2h2v2zm-3-3h-2V8h2v2zm0 3h-2v-2h2v2zm0 4H8v-2h8v2zm-9-7H5V8h2v2zm0 3H5v-2h2v2zm1-2h2v2H8v-2zm0-3h2v2H8V8zm3 3h2v2h-2v-2zm0-3h2v2h-2V8zm9-3H4c-1.11 0-2 .89-2 2v10a2 2 0 002 2h16a2 2 0 002-2V7a2 2 0 00-2-2z"></path></svg>
</template>

<script>
export default {
  name: 'SvgControl'
}
</script>

<style scoped>
svg {
  display: block;
}
</style>
