<template>
  <DeviceLayout>
    <template v-slot:mobile>
      <MobileRoomGuest @logged="join" v-if="screen === 'guest'"></MobileRoomGuest>
    </template>
    <template v-slot:desktop>
      <RoomGuest @logged="join" v-if="screen === 'guest'"></RoomGuest>
    </template>
  </DeviceLayout>
</template>

<script>
import { mapState } from 'vuex'
import DeviceLayout from '@/components/layout/DeviceLayout'
import RoomGuest from '@/views/room/RoomGuest'
import MobileRoomGuest from '@/views/room/MobileRoomGuest'
import { MeServiceError } from '@/service/me.service'
import { RoomServiceError } from '@/service/room.service'

export default {
  name: 'RoomView',
  components: {
    DeviceLayout,
    RoomGuest,
    MobileRoomGuest
  },
  computed: {
    ...mapState('me', {
      userId: (state) => state.userId
    }),
    ...mapState('room', {
      roomSid: (state) => state.roomSid
    }),
    currentRoomSid () {
      return this.$route.params.roomSid
    }
  },
  data () {
    return {
      screen: this.$service.jwt.has() ? null : 'guest'
    }
  },
  async created () {
    if (this.$service.jwt.has()) {
      await this.join()
    }
  },
  methods: {
    async join () {
      this.screen = null
      if (this.roomSid === this.currentRoomSid) {
        this.$store.dispatch('room/updateMode', { mini: false })
        return
      }
      console.log('join', this.currentRoomSid)
      this.$service.centrifugo.connect()
      await this.$store.dispatch('showPreloader')
      const promises = []
      if (this.needToFetchMe) {
        promises.push(this.$service.me.fetchMe())
      }
      promises.push(this.$service.room.join(this.currentRoomSid))
      let result
      try {
        result = await Promise.all(promises)
      } catch (err) {
        if (err instanceof MeServiceError) {
          if (err.code === 'request.canceled') {
            throw err
          }
        } else if (err instanceof RoomServiceError) {
          if (err.code === 'request.canceled') {
            throw err
          } else if (err.code === 'room.not.found') {
            console.log('room.not.found')
            this.$toast.warning({
              type: 'simple',
              context: {
                text: 'This invite may be expired, or you might not have permission to join.'
              }
            })
            await this.$router.push({ name: 'home' })
            return
          }
        }
        this.toast.somethingWentWrong()
        throw err
      } finally {
        await this.$store.dispatch('hidePreloader')
      }
      const joinData = result[1]
      this.$room.open(joinData)
    },
    needToFetchMe () {
      return !this.userId
    }
  }
}
</script>
