<template>
  <div ref="text"></div>
</template>

<script>
import { escapeRegex } from '@/utils/common'
import { getEmojiImage, isEmoji } from '@/utils/emoji'

export default {
  name: 'ChatMessageText',
  props: {
    text: {
      type: String,
      required: true
    }
  },
  mounted () {
    this.renderMessageText()
  },
  methods: {
    renderMessageText () {
      let text = this.text
      if (!text) {
        return
      }
      for (const s of text.match(
        /(?!="):[a-zA-Z0-9-_+]+:(?!")/g
      ) || []) {
        const name = s.slice(1, -1)
        if (isEmoji(name)) {
          text = text.replace(
            new RegExp(`(?!=")${escapeRegex(s)}(?!")`, 'g'),
            this.getEmojiHtml(name)
          )
        }
      }
      this.$refs.text.innerHTML = text
    },
    getEmojiHtml (name) {
      return `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" width="20" height="20" style="margin-bottom:-5px;pointer-events:none;"><image href="${getEmojiImage(
        name
      )}"></image></svg>`
    }
  }
}
</script>

<style scoped lang="scss"></style>
