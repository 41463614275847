<template>
  <div class="footer-volume">
    <button
      class="btn btn-volume"
      v-if="muted"
      @click.prevent="toggleVolume"
    >
      <SvgVolumeMute width="26"></SvgVolumeMute>
    </button>
    <button class="btn btn-volume" v-else @click.prevent="toggleVolume">
      <SvgVolumeUp width="26"></SvgVolumeUp>
    </button>
    <Slider
      class="slider"
      v-model:value="volumeValue"
      @update="updateVolume"
      :rangeMin="0"
      :rangeMax="1"
      :rangeStep="0.001"
    ></Slider>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Slider from '@/components/Slider'
import SvgVolumeUp from '@/components/svg/SvgVolumeUp'
import SvgVolumeMute from '@/components/svg/SvgVolumeMute'

export default {
  name: 'FooterVolume',
  components: {
    Slider,
    SvgVolumeUp,
    SvgVolumeMute
  },
  computed: {
    ...mapState('room', {
      volume: (state) => state.volume,
      muted: (state) => state.muted
    }),
    volumeValue () {
      return this.muted ? 0 : this.volume
    }
  },
  methods: {
    updateVolume (value) {
      this.$store.commit('room/update', { volume: value, muted: false })
    },
    toggleVolume () {
      this.$store.commit('room/update', {
        volume: this.volume,
        muted: !this.muted
      })
    }
  }
}
</script>

<style scoped lang="scss">
@import "@/styles/variables";

.footer-volume {
  display: flex;
  align-items: center;
  max-width: 150px;
  min-width: 150px;
  height: 100%;
}
.btn-volume {
  height: 100%;
  padding: 0 16px;
  color: var(--muted-color);
  &:hover:not([disabled]) {
    color: var(--primary-color);
  }
}
.slider {
  --slider-bg: var(--room-volume-slider-bg);
  --slider-color: var(--room-volume-slider-color);
}
</style>
