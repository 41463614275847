<template>
  <div class="modal-header">
    <div class="title clamp-1">
      <slot name="title"></slot>
    </div>
    <button class="btn btn-close" @click="close">
      <SvgClose width="24"></SvgClose>
    </button>
  </div>
</template>

<script>
import SvgClose from '@/components/svg/SvgClose'

export default {
  name: 'ModalHeader',
  components: {
    SvgClose
  },
  props: {
    modal: {
      type: Object,
      required: true
    }
  },
  methods: {
    close () {
      this.modal.close()
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/styles/variables";

.modal-header {
  --modal-header-padding: 24px;
  padding: 24px calc(var(--modal-header-padding) - 10px) 24px var(--modal-header-padding);
  font-family: "Roboto Medium", sans-serif;
  font-size: 18px;
  display: flex;
  align-items: center;
  .title {
    flex-grow: 1;
  }
  .btn-close {
    padding: 4px;
    color: var(--muted-color);
    &:hover {
      color: var(--primary-color);
    }
    &:active {
      color: white;
    }
  }
}
</style>
