<template>
  <DeviceLayout>
    <template v-slot:mobile>
      <MobileAccount></MobileAccount>
    </template>
    <template v-slot:desktop>
      <Account></Account>
    </template>
  </DeviceLayout>
</template>

<script>
import DeviceLayout from '@/components/layout/DeviceLayout'
import Account from '@/views/account/Account'
import MobileAccount from '@/views/account/MobileAccount'

export default {
  name: 'AccountView',
  components: {
    DeviceLayout,
    Account,
    MobileAccount
  }
}
</script>
