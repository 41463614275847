import EventEmitter from 'events'

export class Modal extends EventEmitter {
  constructor (data) {
    super()
    this.options = Object.assign(
      {
        type: null
      },
      data
    )
    if (!this.options.type) {
      throw new Error('Type must be specified')
    }
  }

  close () {
    this.emit('close')
  }
}
