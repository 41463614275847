<template>
  <div class="mobile-header">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'MobileHeader'
}
</script>

<style scoped lang="scss">
@import "@/styles/variables";

.mobile-header {
  width: 100%;
  max-height: $mobile-header-height;
  min-height: $mobile-header-height;
  height: $mobile-header-height;
  background-color: var(--mobile-header-bg);
  display: flex;
}
</style>
