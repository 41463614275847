<template>
  <div class="mobile-my-rooms">
    <div class="title">
      MY ROOMS
    </div>
    <div class="rooms">
      <MobileRoomCard
        v-for="room in rooms"
        :key="room.id"
        class="room"
        :room="room"
      >
      </MobileRoomCard>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import MobileRoomCard from '@/components/rooms/MobileRoomCard'

export default {
  name: 'MobileMyRooms',
  components: {
    MobileRoomCard
  },
  computed: {
    ...mapState('me', {
      rooms: (state) => state.rooms
    })
  }
}
</script>

<style scoped lang="scss">
@import "@/styles/variables";
@import "@/styles/breakpoints";

.mobile-my-rooms {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.title {
  font-size: 13px;
  color: var(--muted-color)
}
.rooms {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
</style>
