<template>
  <div class="chat-input-box" ref="inputBox" v-click-outside="onClickOutside">
    <div class="input-with-buttons">
      <ChatInput
        ref="input"
        :emoji-list-shown="showEmojiList"
        @open-emoji-list="openEmojiList"
        @close-emoji-list="closeEmojiList"
        @execute-emoji-list="executeEmojiList"
        @submit="submit"
        @typing="$emit('typing')"
      ></ChatInput>
      <div class="input-actions">
        <button class="btn btn-action" @click="togglePaperclip">
          <SvgPaperclip width="18"></SvgPaperclip>
        </button>
        <button class="btn btn-action" @click="toggleEmojiPicker">
          <SvgSmile width="18"></SvgSmile>
        </button>
        <button class="btn btn-action" @click="sendMessage">
          <SvgSend width="18"></SvgSend>
        </button>
      </div>
    </div>
    <div class="attachment" v-if="showAttachment">
      <ChatAttachment
        :attachments="attachments"
        @detach="detach"
      ></ChatAttachment>
    </div>
    <div class="typing">
      <ChatTyping ref="typing"></ChatTyping>
    </div>
    <div class="paperclip" v-show="showPaperclip">
      <ChatPaperclip :shown="showPaperclip" @chosen="attach"></ChatPaperclip>
    </div>
    <div class="emoji-picker" v-show="showEmojiPicker">
      <ChatEmojiPicker
        :shown="showEmojiPicker"
        @chosen="insertEmoji"
      ></ChatEmojiPicker>
    </div>
    <div class="emoji-list" v-if="showEmojiList">
      <ChatEmojiList
        ref="emojiList"
        :query="emojiListQuery"
        @close="closeEmojiList({ focusInput: true })"
        @chosen="autofillEmoji"
      ></ChatEmojiList>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import ChatInput from '@/components/room/chat/ChatInput'
import SvgPaperclip from '@/components/svg/SvgPaperclip'
import SvgSmile from '@/components/svg/SvgSmile'
import SvgSend from '@/components/svg/SvgSend'
import ChatPaperclip from '@/components/room/chat/ChatPaperclip'
import ChatEmojiPicker from '@/components/room/chat/ChatEmojiPicker'
import ChatEmojiList from '@/components/room/chat/ChatEmojiList'
import ChatAttachment from '@/components/room/chat/ChatAttachment'
import ChatTyping from '@/components/room/chat/ChatTyping'

export default {
  name: 'ChatInputBox',
  components: {
    ChatInput,
    SvgPaperclip,
    SvgSmile,
    SvgSend,
    ChatPaperclip,
    ChatEmojiPicker,
    ChatEmojiList,
    ChatAttachment,
    ChatTyping
  },
  props: {
    forceSubmitAttachments: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapState({
      isMobile: (state) => state.isMobile
    }),
    showAttachment () {
      return this.attachments.length > 0 && !this.forceSubmitAttachments
    },
    showEmojiList () {
      return !!this.emojiListQuery
    }
  },
  data () {
    return {
      resizeObserver: null,
      emojiListQuery: null,
      showPaperclip: false,
      showEmojiPicker: false,
      attachments: []
    }
  },
  mounted () {
    this.initResizeObserver()
  },
  beforeUnmount () {
    this.destroyResizeObserver()
  },
  methods: {
    sendMessage () {
      this.$refs.input.submit()
    },
    initResizeObserver () {
      this.destroyResizeObserver()
      const resizeObserver = new ResizeObserver(() => {
        this.$emit('resized')
      })
      resizeObserver.observe(this.$refs.inputBox)
    },
    destroyResizeObserver () {
      if (this.resizeObserver) {
        this.resizeObserver.disconnect()
        this.resizeObserver = null
      }
    },
    onClickOutside () {
      this.closePaperclip()
      this.closeEmojiPicker()
      this.closeEmojiList()
    },
    togglePaperclip () {
      this.closeEmojiPicker()
      this.closeEmojiList()
      this.showPaperclip = !this.showPaperclip
    },
    closePaperclip () {
      this.showPaperclip = false
    },
    openEmojiList (query) {
      this.closePaperclip()
      this.closeEmojiPicker()
      this.emojiListQuery = query
    },
    executeEmojiList (data) {
      this.$refs.emojiList.execute(data)
    },
    closeEmojiList (options) {
      const { focusInput } = Object.assign(
        {
          focusInput: false
        },
        options || {}
      )
      this.emojiListQuery = null
      if (focusInput) {
        if (!this.isMobile) {
          this.$refs.input.focus()
        }
      }
    },
    toggleEmojiPicker () {
      this.closePaperclip()
      this.closeEmojiList()
      this.showEmojiPicker = !this.showEmojiPicker
    },
    closeEmojiPicker () {
      this.showEmojiPicker = false
    },
    autofillEmoji (data) {
      this.$refs.input.autofillEmoji(data)
    },
    insertEmoji (emoji) {
      this.$refs.input.insertEmoji(emoji)
    },
    attach (attachment) {
      this.closePaperclip()
      if (this.forceSubmitAttachments) {
        this.attachments.push(attachment)
        this.submit()
        if (!this.isMobile) {
          this.$nextTick(() => {
            this.$refs.input.focus()
          })
        }
        return
      }
      if (this.attachments.length >= 1) {
        this.attachments.shift()
      }
      this.attachments.push(attachment)
      if (!this.isMobile) {
        this.$nextTick(() => {
          this.$refs.input.focus()
        })
      }
      this.$emit('resized')
    },
    detach (index) {
      this.attachments.splice(index, 1)
      if (!this.isMobile) {
        this.$nextTick(() => {
          this.$refs.input.focus()
        })
      }
      this.$emit('resized')
    },
    submit (text) {
      const attachments = []
      for (const attachment of this.attachments) {
        const { url, height, width } = attachment
        attachments.push({
          url,
          height,
          width
        })
      }
      if (this.attachments.length || text.length) {
        this.$emit('submit', text, attachments)
      }
      this.$nextTick(() => {
        this.attachments = []
        this.closeEmojiPicker()
        this.closePaperclip()
        this.closeEmojiList()
      })
    },
    addTyping (data) {
      this.$refs.typing.add(data)
    },
    delTyping (data) {
      this.$refs.typing.del(data)
    }
  }
}
</script>

<style scoped lang="scss">
@import "@/styles/variables";

.chat-input-box {
  width: 100%;
  min-height: $room-chat-input-height;
  position: relative;
}
.input-with-buttons {
  display: flex;
  align-items: flex-start;
  background-color: var(--room-aside-footer-bg);
}
.input-actions {
  display: flex;
  align-items: center;
  gap: 4px;
  height: $room-chat-input-height;
  padding-right: 12px;
  .btn-action{
    height: $room-chat-input-height;
    padding: 0 4px;
    display: flex;
    align-items: center;
    color: var(--muted-color);
    &:hover:not([disabled]) {
      color: var(--primary-color);
    }
    &:active:not([disabled]) {
      color: var(--bright-color);
    }
  }
}
.typing {
  position: absolute;
  left: 0;
  bottom: 100%;
  z-index: 1;
  width: 100%;
}
.paperclip {
  position: absolute;
  left: 0;
  bottom: 100%;
  z-index: 1;
  width: 100%;
}
.emoji-picker {
  position: absolute;
  left: 0;
  bottom: 100%;
  z-index: 1;
  width: 100%;
}
.emoji-list {
  position: absolute;
  left: 0;
  bottom: 100%;
  z-index: 1;
  width: 100%;
}
</style>
