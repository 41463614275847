<template>
  <SidebarLayout>
    <template v-slot:content>
      <div class="home">
        <VerificationAlert v-if="imGuest"></VerificationAlert>
        <AddRoom></AddRoom>
        <MyRooms></MyRooms>
      </div>
    </template>
  </SidebarLayout>
</template>

<script>
import { mapState } from 'vuex'
import SidebarLayout from '@/components/layout/SidebarLayout'
import AddRoom from '@/views/home/AddRoom'
import MyRooms from '@/views/home/MyRooms'
import VerificationAlert from '@/components/VerificationAlert'

export default {
  name: 'Home',
  components: {
    SidebarLayout,
    AddRoom,
    MyRooms,
    VerificationAlert
  },
  computed: {
    ...mapState('me', {
      role: (state) => state.role
    }),
    imGuest () {
      return this.role === 'GUEST'
    }
  }
}
</script>

<style scoped lang="scss">
@import "@/styles/variables";
@import "@/styles/breakpoints";

.home {
  display: flex;
  flex-direction: column;
  gap: 32px;
  padding-bottom: $content-padding;
}
</style>
