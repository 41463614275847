<template>
  <div class="room-layout" ref="layout" :class="{ dragging: drag }">
    <div class="left" ref="left">
      <div class="room-content">
        <slot></slot>
      </div>
    </div>
    <div class="right" v-show="!mini" ref="right">
      <div class="resize" ref="resize"></div>
      <RoomAside class="room-aside"></RoomAside>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import RoomAside from '@/components/room/aside/RoomAside'

export default {
  name: 'RoomLayout',
  components: {
    RoomAside
  },
  data () {
    return {
      layout: null,
      left: null,
      resize: null,
      right: null,
      drag: false,
      moveX: null,
      snapping: {
        items: [340],
        diff: 25
      }
    }
  },
  computed: {
    ...mapState('room', {
      mini: (state) => state.mini
    })
  },
  mounted () {
    this.layout = this.$refs.layout
    this.left = this.$refs.left
    this.resize = this.$refs.resize
    this.right = this.$refs.right
    this.moveX =
      this.left.getBoundingClientRect().width +
      this.resize.getBoundingClientRect().width / 2

    this.resize.addEventListener('mousedown', this.onResizeMouseDown)
    this.layout.addEventListener('mousemove', this.onLayoutMouseMove)
    this.layout.addEventListener('mouseup', this.onLayoutMouseUp)
  },
  beforeUnmount () {
    if (this.resize) {
      this.resize.removeEventListener('mousedown', this.onResizeMouseDown)
    }
    if (this.layout) {
      this.layout.removeEventListener(
        'mousemove',
        this.onLayoutMouseMove
      )
      this.layout.removeEventListener('mouseup', this.onLayoutMouseUp)
    }
  },
  methods: {
    onResizeMouseDown (event) {
      this.drag = true
      this.moveX = event.x
    },
    onLayoutMouseMove (event) {
      this.moveX = event.x
      if (this.drag) {
        event.preventDefault()
        const width =
          this.layout.getBoundingClientRect().width -
          this.moveX -
          this.resize.getBoundingClientRect().width / 2
        for (const snap of this.snapping.items) {
          if (
            width > snap - this.snapping.diff &&
            width < snap + this.snapping.diff
          ) {
            this.right.style.width = snap + 'px'
            return
          }
        }
        this.right.style.width = width + 'px'
      }
    },
    onLayoutMouseUp () {
      this.drag = false
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/styles/variables";

.room-layout {
  width: 100%;
  height: 100%;
  display: flex;
}

.left {
  flex-grow: 1;
  display: flex;
  position: relative;
  .room-content {
    background-color: var(--room-primary-bg);
    flex-grow: 1;
  }
}

.right {
  position: relative;
  width: $room-aside-width;
  min-width: $room-aside-min-width;
  max-width: $room-aside-max-width;
  height: 100%;
  .resize {
    height: 100%;
    width: 12px;
    cursor: col-resize;
    flex-shrink: 0;
    user-select: none;
    position: absolute;
    left: -6px;
    right: 0;
    z-index: 3;
    &::before {
      content: "";
      position: absolute;
      left: 50%;
      top: 0;
      transform: translate(-50%, 0);
      width: 4px;
      height: 100%;
    }
    &:hover {
      &::before {
        background-color: var(--room-resize-bg);
        -webkit-transition: background-color 250ms linear;
        -ms-transition: background-color 250ms linear;
        transition: background-color 250ms linear;
      }
    }
  }
}

.room-layout {
  &.dragging {
    cursor: col-resize;
    .right {
      .resize {
        &::before {
          background-color: var(--room-resize-bg);
        }
      }
    }
  }
}
</style>
