<template>
  <div class="mobile-queuing">
    <div class="content">
      <div class="status-text">
        <template v-if="queuePos">
          Queuing {{ queuePos.index }} of {{ queuePos.total }}...
        </template>
        <template v-else> Queuing... </template>
      </div>
      <div class="skip-queue">
        <div class="challenge-text">Want to skip the queue?</div>
        <router-link to="/membership" class="btn-primary-sm btn-get-membership">
          GET BEARCAT MEMBERSHIP
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'MobileQueuing',
  computed: {
    ...mapState('room', {
      mini: (state) => state.mini,
      queuePos: (state) => state.queuePos
    })
  }
}
</script>

<style lang="scss" scoped>
@import "@/styles/variables";

.mobile-queuing {
  aspect-ratio: 16 / 9;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: $mobile-content-padding;
}

.content {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
  .status-text {
    text-align: center;
    font-family: "Roboto Mono Medium", sans-serif;
    font-size: 14px;
  }
  .skip-queue {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    .challenge-text {
      color: var(--muted-color);
      font-size: 15px;
    }
    .btn-get-membership {
      max-width: 220px;
    }
  }
}
</style>
