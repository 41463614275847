<template>
  <div class="mobile-pending">
    <div class="content">
      <Preprocessor class="spinner"></Preprocessor>
      <div class="text" v-if="showText">
        We are preparing your browser it may take a few minutes...
      </div>
    </div>
  </div>
</template>

<script>
import Preprocessor from '@/components/Preprocessor'

export default {
  name: 'MobilePending',
  components: {
    Preprocessor
  },
  data () {
    return {
      textDelayTimer: null,
      showText: false
    }
  },
  async beforeMount () {
    this.startTextDelayTimer(3000)
  },
  beforeUnmount () {
    this.stopTextDelayTimer()
  },
  methods: {
    startTextDelayTimer (delay) {
      this.stopTextDelayTimer()
      this.textDelayTimer = setTimeout(async () => {
        this.showText = true
      }, delay)
    },
    stopTextDelayTimer () {
      if (this.textDelayTimer) {
        clearTimeout(this.textDelayTimer)
        this.textDelayTimer = null
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/styles/variables";

.mobile-pending {
  aspect-ratio: 16 / 9;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: $mobile-content-padding;
}

.content {
  width: 100%;
  max-width: 250px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  .spinner {
    --preprocessor-size: 48px;
  }
  .text {
    text-align: center;
    font-family: "Roboto Mono Medium", sans-serif;
    font-size: 13px;
    line-height: 20px;
  }
}
</style>
