<template>
  <div id="fullscreen" class="fullscreen" ref="fullscreen">
    <slot></slot>
    <FullscreenAside
      v-if="fullscreen"
      class="fullscreen-aside"
    ></FullscreenAside>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import FullscreenAside from '@/components/room/fullscreen/FullscreenAside'

export default {
  name: 'Fullscreen',
  components: {
    FullscreenAside
  },
  computed: {
    ...mapState('room', {
      fullscreen: (state) => state.fullscreen
    })
  },
  watch: {
    fullscreen (value) {
      const fullscreenElement = document.fullscreenElement
      const { id: fullscreenElementId } = fullscreenElement || {}
      if (value) {
        this.openFullscreen()
      } else {
        if (fullscreenElementId === 'fullscreen') {
          this.closeFullscreen()
        }
      }
    }
  },
  mounted () {
    document.addEventListener('fullscreenchange', this.onFullscreenChange)
  },
  beforeUnmount () {
    document.removeEventListener('fullscreenchange', this.onFullscreenChange)
  },
  methods: {
    onFullscreenChange () {
      const fullscreenElement = document.fullscreenElement
      const { id: fullscreenElementId } = fullscreenElement || {}
      if (fullscreenElement) {
        if (fullscreenElementId === 'fullscreen') {
          this.$store.dispatch('room/updateMode', { fullscreen: true })
        }
      } else {
        this.$store.dispatch('room/updateMode', { fullscreen: false })
      }
    },
    openFullscreen () {
      const el = this.$refs.fullscreen
      if (el.requestFullscreen) {
        el.requestFullscreen()
      } else if (el.webkitRequestFullscreen) {
        el.webkitRequestFullscreen()
      } else if (el.msRequestFullscreen) {
        el.msRequestFullscreen()
      }
    },
    closeFullscreen () {
      if (document.exitFullscreen) {
        document.exitFullscreen()
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen()
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen()
      }
    }
  }
}
</script>

<style scoped lang="scss">
.fullscreen {
  width: 100%;
  height: 100%;
  position: relative;
  .fullscreen-aside {
    position: absolute;
    right: 0;
    top: 0;
    z-index: 1;
  }
}
</style>
