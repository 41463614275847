<template>
  <ContentLayout>
    <template v-slot:content>
      <div class="help-text">
        <div class="text line-height">
          Welcome to Bearcat! We're here to answer your questions. Feel free to ping us on our <a class="link" :href="discordInviteUrl">Discord</a> server or send our support team a message at <a class="link" href="mailto:support@bear.cat">support@bear.cat</a>.
        </div>
      </div>
    </template>
  </ContentLayout>
</template>

<script>
import ContentLayout from '@/components/layout/ContentLayout'
import { discordInviteUrl } from '@/settings'

export default {
  name: 'HelpText',
  components: {
    ContentLayout
  },
  data () {
    return {
      discordInviteUrl
    }
  }
}
</script>

<style scoped lang="scss">
@import "@/styles/variables";
@import "@/styles/breakpoints";
</style>
