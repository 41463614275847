<template>
  <div class="queuing-mini" v-if="mini">
    <template v-if="queuePos">
      Queuing {{ queuePos.index }} of {{ queuePos.total }}...
    </template>
    <template v-else> Queuing... </template>
  </div>
  <div class="queuing" v-else>
    <RoomHeader></RoomHeader>
    <div class="content-wrap">
      <div class="content">
        <Hourglass></Hourglass>
        <div class="status-text">
          <template v-if="queuePos">
            Queuing {{ queuePos.index }} of {{ queuePos.total }}...
          </template>
          <template v-else> Queuing... </template>
        </div>
        <div class="skip-queue">
          <div class="challenge-text">Want to skip the queue?</div>
          <router-link to="/membership" class="btn-primary-sm">
            GET BEARCAT MEMBERSHIP
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Hourglass from '@/components/room/queuing/Hourglass'
import RoomHeader from '@/components/room/header/RoomHeader'

export default {
  name: 'Queuing',
  components: {
    Hourglass,
    RoomHeader
  },
  computed: {
    ...mapState('room', {
      mini: (state) => state.mini,
      queuePos: (state) => state.queuePos
    })
  }
}
</script>

<style lang="scss" scoped>
@import "@/styles/variables";

.queuing-mini {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Roboto Mono Medium", sans-serif;
  font-size: 14px;
  color: var(--muted-color);
}

.queuing {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.content-wrap {
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.content {
  width: 100%;
  max-width: 220px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;
  .status-text {
    text-align: center;
    font-family: "Roboto Mono Medium", sans-serif;
    font-size: 14px;
  }
  .skip-queue {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;
    .challenge-text {
      color: var(--muted-color);
      font-size: 15px;
    }
  }
}
</style>
