<template>
  <div class="chat-input">
    <div
      class="input scroll-y"
      contenteditable="true"
      spellcheck="false"
      placeholder="Enter message..."
      ref="input"
      v-focus
      @keyup="onKeyup"
      @keydown="onKeydown"
      @click="onClick"
      @input="onInput"
      @focus="onFocus"
      @paste="onPaste"
    ></div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { escapeRegex, isNil } from '@/utils/common'
import { getEmojiImage, isEmoji } from '@/utils/emoji'

export default {
  name: 'ChatInput',
  props: {
    emojiListShown: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapState({
      isMobile: (state) => state.isMobile
    }),
    ...mapState('room', {
      mini: (state) => state.mini
    })
  },
  data () {
    return {
      caret: null,
      typingTimer: null
    }
  },
  watch: {
    mini (value) {
      if (!value) {
        if (!this.isMobile) {
          this.$nextTick(() => {
            this.focus()
          })
        }
      }
    }
  },
  methods: {
    onPaste (e) {
      e.preventDefault()
      const text = e.clipboardData.getData('text/plain')
      this.insertText(text)
    },
    focus () {
      if (!this.caret) {
        const nodes = this.$refs.input.childNodes
        if (nodes.length) {
          if (nodes[nodes.length - 1].nodeType === 3) {
            this.setCaretPosition({
              index: nodes.length - 1,
              offset: nodes[nodes.length - 1].textContent.length
            })
          } else {
            this.setCaretPosition({ index: null, offset: nodes.length })
          }
        } else {
          this.setCaretPosition({ index: null, offset: 0 })
        }
      } else {
        this.setCaretPosition(this.caret)
      }
    },
    getCaretPosition () {
      if (window.getSelection) {
        const selection = window.getSelection()
        if (selection.rangeCount !== 0) {
          const range = selection.getRangeAt(0)
          const selectedObj = window.getSelection()
          if (selectedObj.anchorNode.nodeType === 3) {
            let index = 0
            for (const childNode of this.$refs.input.childNodes) {
              if (childNode === selectedObj.anchorNode) {
                break
              }
              index += 1
            }
            return { index, offset: range.startOffset }
          }
          return { index: null, offset: range.startOffset }
        }
      }
    },
    setCaretPosition ({ index, offset }) {
      if (window.getSelection) {
        if (!this.isMobile) {
          const selection = window.getSelection()
          const range = document.createRange()
          range.setStart(
            isNil(index) ? this.$refs.input : this.$refs.input.childNodes[index],
            offset
          )
          range.collapse(true)
          selection.removeAllRanges()
          selection.addRange(range)
        }
        this.caret = { index, offset }
      }
    },
    saveCaretPosition () {
      const caret = this.getCaretPosition()
      if (caret) {
        this.caret = caret
      }
    },
    onKeydown (e) {
      const { key, shiftKey } = e
      if (key === 'Enter') {
        if (!shiftKey) {
          if (this.emojiListShown) {
            e.preventDefault()
            this.$emit('execute-emoji-list', { key })
          } else {
            e.preventDefault()
            this.submit()
          }
        } else {
          e.preventDefault()
          document.execCommand('insertLineBreak')
        }
      } else if (key === 'Escape') {
        if (this.emojiListShown) {
          e.preventDefault()
          this.$emit('execute-emoji-list', { key })
        }
      } else if (key === 'ArrowUp') {
        if (this.emojiListShown) {
          e.preventDefault()
          this.$emit('execute-emoji-list', { key })
        }
      } else if (key === 'ArrowDown') {
        if (this.emojiListShown) {
          e.preventDefault()
          this.$emit('execute-emoji-list', { key })
        }
      }
    },
    onKeyup (e) {
      const { key } = e
      if (key === 'ArrowLeft') {
        this.saveCaretPosition()
        this.emojiList()
      } else if (key === 'ArrowRight') {
        this.saveCaretPosition()
        this.emojiList()
      } else if (key === 'ArrowUp') {
        this.saveCaretPosition()
      } else if (key === 'ArrowDown') {
        this.saveCaretPosition()
      } else if (key === 'End') {
        this.saveCaretPosition()
        this.emojiList()
      } else if (key === 'PageDown') {
        this.saveCaretPosition()
        this.emojiList()
      } else if (key === 'PageUp') {
        this.saveCaretPosition()
        this.emojiList()
      }
    },
    onClick () {
      this.saveCaretPosition()
    },
    onInput () {
      this.saveCaretPosition()
      this.emojiList()
      this.replaceColons()
      this.handleTyping()
    },
    handleTyping () {
      if (isNil(this.typingTimer)) {
        this.$emit('typing')
        this.typingTimer = setTimeout(() => {
          this.stopTypingTimer()
        }, 2000)
      }
    },
    stopTypingTimer () {
      if (!isNil(this.typingTimer)) {
        clearTimeout(this.typingTimer)
        this.typingTimer = null
      }
    },
    replaceColons () {
      for (const s of this.$refs.input.innerHTML.match(
        /(?!="):[a-zA-Z0-9-_+]+:(?!")/g
      ) || []) {
        const name = s.slice(1, -1)
        if (isEmoji(name)) {
          this.$refs.input.innerHTML = this.$refs.input.innerHTML.replace(
            new RegExp(`(?!=")${escapeRegex(s)}(?!")`, 'g'),
            this.getEmojiHtml(name)
          )
          const img = this.$refs.input.querySelector(
            'img[alt="' + s + '"][is-new="true"][is-emoji="true"]'
          )
          img.removeAttribute('is-new')
          const offset = Array.prototype.indexOf.call(
            this.$refs.input.childNodes,
            img
          )
          this.setCaretPosition({ index: null, offset: offset + 1 })
        }
      }
    },
    emojiList () {
      if (!isNil(this.caret)) {
        const { index, offset } = this.caret
        if (!isNil(index)) {
          const colonIndex =
            this.$refs.input.childNodes[index].textContent.lastIndexOf(':')
          if (colonIndex !== -1) {
            const query = this.$refs.input.childNodes[index].textContent.slice(
              colonIndex + 1,
              offset
            )
            if (query.length > 1 && /^[a-zA-Z0-9-_+]+$/g.test(query)) {
              this.$emit('open-emoji-list', query)
              return
            }
          }
        }
      }
      this.$emit('close-emoji-list')
    },
    onFocus () {
      this.saveCaretPosition()
    },
    autofillEmoji (data) {
      const { query, name } = data
      if (!isNil(this.caret)) {
        const { index } = this.caret
        if (!isNil(index)) {
          const colonIndex =
            this.$refs.input.childNodes[index].textContent.lastIndexOf(':')
          if (colonIndex !== -1) {
            const node = this.$refs.input.childNodes[index]
            node.textContent = node.textContent.replace(
              `:${query}`,
              `:${name}:`
            )
            this.setCaretPosition({
              index,
              offset: colonIndex + name.length + 2
            })
            this.replaceColons()
          }
        }
      }
    },
    insertEmoji (emoji) {
      if (!isNil(this.caret)) {
        const { index, offset } = this.caret
        if (!isNil(index)) {
          const node = this.$refs.input.childNodes[index]
          node.textContent =
            node.textContent.slice(0, offset) +
            `:${emoji}:` +
            node.textContent.slice(offset)
          this.setCaretPosition({ index, offset: offset + emoji.length + 2 })
          this.replaceColons()
        } else {
          const node = this.$refs.input.childNodes[offset]
          if (!isNil(node)) {
            this.$refs.input.insertBefore(
              document.createTextNode(`:${emoji}:`),
              node
            )
            this.setCaretPosition({ index: offset, offset: name.length + 2 })
            this.replaceColons()
          } else {
            this.$refs.input.appendChild(document.createTextNode(`:${emoji}:`))
            this.setCaretPosition({ index: offset, offset: name.length + 2 })
            this.replaceColons()
          }
        }
      } else {
        this.$refs.input.appendChild(document.createTextNode(`:${emoji}:`))
        this.setCaretPosition({ index: 0, offset: name.length + 2 })
        this.replaceColons()
      }
    },
    insertText (text) {
      const _text = text.split(/\r?\n|\r|\n/g)
      for (let i = 0; i < _text.length; i++) {
        const line = _text[i]
        if (!isNil(this.caret)) {
          const { index, offset } = this.caret
          if (!isNil(index)) {
            const node = this.$refs.input.childNodes[index]
            node.textContent = node.textContent + line
            this.setCaretPosition({ index, offset: offset + line.length })
            this.replaceColons()
          } else {
            const node = this.$refs.input.childNodes[offset]
            if (!isNil(node)) {
              this.$refs.input.insertBefore(
                document.createTextNode(line),
                node
              )
              this.setCaretPosition({ index: offset, offset: line.length })
              this.replaceColons()
            } else {
              this.$refs.input.appendChild(document.createTextNode(line))
              this.setCaretPosition({ index: offset, offset: line.length })
              this.replaceColons()
            }
          }
        } else {
          this.$refs.input.appendChild(document.createTextNode(line))
          this.setCaretPosition({ index: 0, offset: line.length })
          this.replaceColons()
        }
        if (i < _text.length - 1) {
          document.execCommand('insertLineBreak')
        }
      }
    },
    getEmojiHtml (name) {
      return `<img src="${getEmojiImage(
        name
      )}" alt=":${name}:" style="margin-bottom:-5px;pointer-events:none;" is-emoji="true" is-new="true">`
    },
    submit () {
      let text = ''
      for (const node of this.$refs.input.childNodes) {
        if (node.nodeType === 3) {
          text += node.textContent
        } else if (node instanceof HTMLImageElement) {
          if (node.getAttribute('is-emoji')) {
            text += node.getAttribute('alt')
          }
        } else if (node instanceof HTMLBRElement) {
          text += '\n'
        }
      }
      this.$refs.input.innerHTML = ''
      this.setCaretPosition({ index: null, offset: 0 })
      this.stopTypingTimer()
      this.$emit('submit', text)
    }
  }
}
</script>

<style scoped lang="scss">
@import "@/styles/variables";

.chat-input {
  display: flex;
  align-items: center;
  width: 100%;
  min-height: $room-chat-input-height;
  background-color: var(--room-aside-footer-bg);
}
.input {
  width: 100%;
  min-height: calc(#{$room-chat-input-height} * 1);
  max-height: calc(#{$room-chat-input-height} * 4);
  padding: 14px 10px 14px 20px;
  font-size: 15px;
  line-height: 22px;
  word-break: break-word;
  cursor: text;
  --scrollbar-color: var(--room-scroll-color);
}
.input:empty:before {
  content: attr(placeholder);
  color: var(--muted-color);
}
</style>
