<template>
  <input
    class="slider"
    type="range"
    :min="rangeMin"
    :max="rangeMax"
    :step="rangeStep"
    v-bind:value="value"
    @input="$emit('update', parseFloat($event.target.value))"
  />
</template>

<script>
export default {
  name: 'Slider',
  components: {},
  props: {
    value: {
      type: Number,
      default: 0
    },
    rangeMin: {
      type: Number,
      default: 0
    },
    rangeMax: {
      type: Number,
      default: 100
    },
    rangeStep: {
      type: Number,
      default: 1
    }
  }
}
</script>

<style scoped lang="scss">
@import "@/styles/variables";

.slider {
  -webkit-appearance: none;
  appearance: none;
  height: 5px;
  width: 100%;
  border-radius: 2px;
  background: var(--slider-bg);
  outline: none;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;
  vertical-align: middle;
  border: none;
  box-shadow: none;
  cursor: pointer;
  &:before,
  &:hover,
  &:focus,
  &:active,
  &:visited,
  &:required,
  &:invalid {
    border: none;
    box-shadow: none;
    outline: none;
  }
  &::-moz-focus-outer {
    border: none;
    box-shadow: none;
    outline: none;
  }
}
.slider::-webkit-slider-runnable-track {
  background-color: transparent;
  border-radius: 2px;
  height: 24px;
}
.slider::-webkit-slider-thumb {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  width: 5px;
  height: 18px;
  margin-top: 3px;
  border-radius: 2px;
  background: var(--slider-color);
  cursor: pointer;
  border: none;
}
.slider::-moz-range-track {
  background-color: transparent;
  border-radius: 2px;
  height: 24px;
}
.slider::-moz-range-thumb {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  width: 5px;
  height: 18px;
  margin-top: 1px;
  border-radius: 2px;
  background: var(--slider-color);
  cursor: pointer;
  border: none;
}
</style>
