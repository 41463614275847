<template>
  <div class="delete-account-modal">
    <ModalHeader :modal="modal">
      <template v-slot:title>Delete account</template>
    </ModalHeader>
    <form class="form">
      <div class="content line-height">
        Are you sure you want to delete your account? This action cannot be undone.
      </div>
      <ModalFooter class="footer">
        <template v-slot:content>
          <ProcessButton
            class="btn-danger-sm btn-confirm"
            :processing="processing"
            @submit="deleteAccount"
          >
            CONFIRM
          </ProcessButton>
        </template>
      </ModalFooter>
    </form>
  </div>
</template>

<script>
import ProcessButton from '@/components/ProcessButton'
import ModalHeader from '@/components/modals/ModalHeader'
import ModalFooter from '@/components/modals/ModalFooter'

export default {
  name: 'DeleteAccountModal',
  components: {
    ProcessButton,
    ModalHeader,
    ModalFooter
  },
  props: {
    modal: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      processing: false
    }
  },
  methods: {
    close () {
      this.modal.close()
    },
    async deleteAccount () {
      this.processing = true
      try {
        await this.$service.iam.deleteAccount()
      } finally {
        this.processing = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/styles/variables";
@import "@/styles/breakpoints";

.delete-account-modal {
  width: 100vw;
  display: flex;
  flex-direction: column;
  .footer {
    .btn-confirm {
      max-width: 110px;
    }
  }
  @include media-breakpoint-up(sm) {
    max-width: 460px;
  }
}

.form {
  display: flex;
  flex-direction: column;
  .content {
    padding: 0 24px;
    font-family: "Roboto Medium", sans-serif;
  }
}
</style>
