<template>
  <StickyHeaderLayout>
    <template v-slot:header>
      <div class="sidebar-layout-header">
        <StickyHeaderAccount class="account"></StickyHeaderAccount>
      </div>
    </template>
    <template v-slot:content>
      <div class="sidebar-layout">
        <div class="sidebar-layout-content">
          <div class="sidebar-wrap">
            <Sidebar></Sidebar>
          </div>
          <div class="content-wrap">
            <slot name="content"></slot>
          </div>
        </div>
      </div>
    </template>
  </StickyHeaderLayout>
</template>

<script>
import StickyHeaderLayout from '@/components/layout/StickyHeaderLayout'
import Sidebar from '@/components/sidebar/Sidebar'
import StickyHeaderAccount from '@/components/sticky-header/StickyHeaderAccount'

export default {
  name: 'SidebarLayout',
  components: {
    Sidebar,
    StickyHeaderLayout,
    StickyHeaderAccount
  }
}
</script>

<style scoped lang="scss">
@import "@/styles/variables";
@import "@/styles/breakpoints";

$sidebar-width: 250px;
$sidebar-content-gap: 64px;
$sidebar-content-sm-gap: 32px;
$sidebar-layout-padding-top: 96px;

.sidebar-layout-header {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  .account {
    margin-left: auto;
  }
}

.sidebar-layout {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
}
.sidebar-layout-content {
  display: flex;
  height: 100%;
  width: 100%;
}
.sidebar-wrap {
  position: fixed;
  width: 100%;
  max-width: $sidebar-width;
  top: calc($sticky-header-height + $sidebar-layout-padding-top - 8px);
  display: flex;
  flex-direction: column;
}
.content-wrap {
  flex-grow: 1;
  width: 100%;
  padding-top: $sidebar-layout-padding-top;
  margin-left: calc($sidebar-width + $sidebar-content-gap);
  @include media-breakpoint-down(xl) {
    margin-left: calc($sidebar-width + $sidebar-content-sm-gap);
  }
}
</style>
