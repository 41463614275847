<template>
  <div class="avatar">
    <template v-if="error || !avatar">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 350 350"
        class="error"
      >
        <rect width="100%" height="100%" />
      </svg>
    </template>
    <template v-else>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 350 350"
        class="image"
      >
        <image width="100%" height="100%" :href="href" @error="onError" />
      </svg>
    </template>
  </div>
</template>

<script>
import { avatarUrl } from '@/settings'

export default {
  name: 'Avatar',
  props: {
    avatar: {
      type: String
    }
  },
  data () {
    return {
      error: false
    }
  },
  computed: {
    href () {
      return this.avatar ? avatarUrl + '/' + this.avatar : null
    }
  },
  methods: {
    onError () {
      this.error = true
    }
  }
}
</script>

<style scoped lang="scss">
@import "@/styles/variables";

.avatar {
  border-radius: 8px;
  overflow: hidden;
}

.image {
  display: block;
  width: 100%;
  height: 100%;
  image {
    display: block;
  }
}

.error {
  display: block;
  width: 100%;
  height: 100%;
  rect {
    fill: var(--avatar-error-bg);
  }
}
</style>
